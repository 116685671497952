import React, { useEffect, useState } from "react";
import UserTable from "../../components/chatFeed/UserTable";
import "../../assets/sass/userTable.scss";

import useGet from "../../hooks/Api/useGet";
import SearchBox from "../../components/admin/SearchBox";
const apiEndpoint = "https://app.steelconnect.co.in:3000/api/admin/user";
let users;
let reversedUsers=[]
const AllUsers = () => {
  const [updateUser, setUpdteUser] = useState(0);
  const [searchfield, setSearchField] = useState("");

  const handleUser = () => {
    setUpdteUser(updateUser + 1);
  };
  // const [users,setUsers]=useState()
  const { makeRequest, data, isLoading, error } = useGet(apiEndpoint);

  useEffect(() => {
    let token = localStorage.getItem("token");
    // console.log(token);
    const getData = async () => {
      await makeRequest(token);
      // users=data?.users
    };
    getData();
    users = data?.users;
  }, [updateUser]);

  useEffect(() => {
    console.log('dataUpadated',data);
    users = data?.users;
    reversedUsers = users?.slice().reverse();

  }, [data]);

  console.log(searchfield);

  const filteredUsers = users?.filter((user) => {
    const name = user?.first_name
      ?.toLowerCase()
      .includes(searchfield?.toLowerCase());
    const mobile = user?.mobile?.includes(searchfield?.toLowerCase());

    return name || mobile;
  });

  // console.log(filteredUsers);

  users = data?.users;
  reversedUsers = users?.slice().reverse();


  // console.log(typeof(users));
  // console.log(users);
  return (
    <div className="row">
      <div className="col-md-12">
        <main>
          <div class="page-heading mt-5">
            <h1>Manage Users</h1>
          </div>
          <div className="col-md-8">
            <div className="search-bar">
              <input
                type="search"
                placeholder="Search"
                onChange={(e) => setSearchField(e.target.value)}
              />
            </div>
          </div>

          {searchfield ? (
            <UserTable
              handleUser={handleUser}
              users={filteredUsers}
              heading={"Users"}
            />
          ) : (
            <UserTable
              handleUser={handleUser}
              users={reversedUsers}
              heading={"Users"}
            />
          )}
        </main>
      </div>
    </div>
  );
};

export default AllUsers;
