import React, { useState, useEffect } from 'react';
import {ExcelRenderer} from 'react-excel-renderer';
import './Excel.css'
const ExcelViewer = ({excelFileUrl}) => {
  console.log(excelFileUrl);
  const [excelData, setExcelData] = useState(null);
//   const excelFileUrl = 'https://app.steelconnect.co.in:3000/public/undefined-1691778131680.xls';

  useEffect(() => {
    loadExcelFile(excelFileUrl);
  }, []);

  const loadExcelFile = (url) => {
    fetch(url)
      .then(response => response.blob())
      .then(blob => {
        const file = new File([blob], 'excel-file.xls', { type: 'application/vnd.ms-excel' });
        ExcelRenderer(file, (err, response) => {
          if (!err) {
            setExcelData(response);
          } else {
            console.error('Error rendering Excel file:', err);
          }
        });
      })
      .catch(error => {
        console.error('Error loading Excel file:', error);
      });
  };

  console.log(excelFileUrl);
  return (
    <div className="excel-viewer">
      {excelData && (
        <table className="excel-table">
          <tbody>
            {excelData.rows.map((row, rowIndex) => (
              <tr key={rowIndex}>
                {row.map((cellData, cellIndex) => (
                  <td key={cellIndex}>{cellData}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default ExcelViewer;
