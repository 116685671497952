import React, { useState } from "react";
import { BiGroup } from "react-icons/bi";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { AiOutlineClose, AiOutlineEdit, AiFillDelete } from "react-icons/ai";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import Groups from "./Groups/Groups";
import { useContext } from "react";
import { AuthContext } from "../../hooks/AuthContext";
import { gql, useMutation } from "@apollo/client";
import { useNavigate, useParams } from "react-router";
import { useEffect } from "react";
import UserData from "./Groups/UserData";
import { MdOutlineExitToApp } from "react-icons/md";
import Media from "./ViewMedia/Media";
import Forward from "./Forward/Forward";
import { useRef } from "react";
import usePost from "../../hooks/Api/usePost";
import { Typography } from "@mui/material";
const apiEndpoint = "https://app.steelconnect.co.in:3000/upload";

const CHAT_INFO_MUTATION = gql`
  mutation ChatInfo($input: RoomChatsRequest) {
    chatInfo(input: $input) {
      success
      message
      data {
        user_id
        display_name
        name
        email
        mobile
        is_default_delete
        delete_settings
        image
        is_group
        is_group_admin
        room_id
      }
    }
  }
`;

const CHAT_PARTICIPANTS_MUTATION = gql`
  mutation ChatParticipants($input: ChatParticipantsRequest) {
    chatParticipants(input: $input) {
      success
      message
      users {
        id
        first_name
        last_name
        email
        mobile
        created_at
        is_group_admin
      }
    }
  }
`;

const SET_NICKNAME_MUTATION = gql`
  mutation SetNickname($input: SetNicknameRequest) {
    setNickname(input: $input) {
      success
      message
    }
  }
`;

const EXIT_GROUP_MUTATION = gql`
  mutation ExitGroup($input: ChatParticipantsRequest) {
    exitGroup(input: $input) {
      success
      message
    }
  }
`;

const UPDATE_GROUP_MUTATION = gql`
  mutation UpdateChatImage($input: UpdateChatImageRequest) {
    updateChatImage(input: $input) {
      success
      message
    }
  }
`;

const UPDATE_TIME_MUTAION = gql`
  mutation UpdateChatAutoDelete($input: AutoDeleteRequest) {
    updateChatAutoDelete(input: $input) {
      success
      success
      message
    }
  }
`;

const CHAT_DELETE_MUTATION = gql`
  mutation DeleteChat($input: DeleteChatRequest) {
    deleteChat(input: $input) {
      success
      message
    }
  }
`;

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: "20px",
  boxShadow: 24,
  p: 4,
};


const ChatDetails = ({ setShowDetials }) => {
  const { roomId } = useParams();
  const { userData } = useContext(AuthContext);
  const { changeCount } = useContext(AuthContext);
  const navigate = useNavigate();
  const { makeRequest, data, isLoading, error } = usePost(apiEndpoint);
  const [setGroupImage] = useMutation(UPDATE_GROUP_MUTATION);
  const [chatDelete] = useMutation(CHAT_DELETE_MUTATION);
  const [imageError, setimageError] = useState(false);

  //   const { handleChatData} =useContext(AuthContext)
  const [isForward, setIsForward] = useState(false);
  const [totalChatParticipant] = useMutation(CHAT_PARTICIPANTS_MUTATION);
  const [exitGroup] = useMutation(EXIT_GROUP_MUTATION);
  const [timeUpdate] = useMutation(UPDATE_TIME_MUTAION);

  const [chatInfo] = useMutation(CHAT_INFO_MUTATION);
  const [Nickname] = useMutation(SET_NICKNAME_MUTATION);

  const [selectedColor, setSelectedColor] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [text, setText] = useState("Monu");
  const [isAddParticipants, setIsAddParticipants] = useState();
  const [chatData, setChatData] = useState();
  const [totalParticipant, setTotalParticipnat] = useState();

  const [isViewMoreMedia, setIsViewMoreMedia] = useState();
  const [count, setCount] = useState(1);
  const [value, setValue] = React.useState("off");
  const [viewMedia, setViewMedia] = useState();
  const [selectedFile, setSelectedFile] = useState(null);
  const [isOpen, setIsOpen] = React.useState(false);
  const handleOpen = () => setIsOpen(true);
  const handleModalClose = () => {
    setIsOpen(false);
  };
  const [url, setUrl] = useState(null);

  const handleImageUpload = async () => {
    if (!selectedFile) return;
    // console.log(selectedFile);
    try {
      let formData = {
        file: selectedFile,
      };
      // console.log(formData);
      let token = localStorage.getItem("token");

      await makeRequest(formData, token);
      // console.log(data?.url);
      setUrl(data?.url);
      if (url !== null) {
        // handleProfilePic()
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleProfilePic = async () => {
    console.log("url", url);
    if (url === null) return;
    try {
      const { data } = await setGroupImage({
        variables: {
          input: {
            link: url,
            code: roomId,
          },
        },
      });
      console.log(data);
      if (data?.updateChatImage?.success === true) {
        // console.log(data);
        console.log('data: ', data);
        getChatInfo();
        getChatInfo();

        setSelectedFile(null);
        setUrl(null);
      }
      setSelectedFile(null);
      setUrl(null);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    data && setUrl(data?.url);
  }, [data]);

  useEffect(() => {
    // console.log(url);
    // console.log(data.url);

    if (url !== null) {
      handleProfilePic();
    }
  }, [url]);

  useEffect(() => {
    selectedFile && handleImageUpload();
  }, [selectedFile]);

  const handleFileInputChange = (e) => {
    setSelectedFile(e.target.files[0]);
    // console.log(selectedFile);
  };

  const handleChangeValue = (event) => {
    setValue(event.target.value);
  };

  const handleCount = () => {
    setCount(count + 1);
  };

  const autoTimeUpdate = async (time) => {
    if(time==='off'){
      return;
    }
    const Time = parseInt(time);
    console.log('Time: ', Time);

    const { data } = await timeUpdate({
      variables: {
        input: {
          room_id: chatData?.room_id,
          time: Time,
        },
      },
    });
    console.log(data);
    // setChatData(data?.chatInfo?.data);
    if (data?.success === true) {
    }
  };
  const getChatInfo = async () => {
    const { data } = await chatInfo({
      variables: {
        input: {
          code: roomId,
        },
      },
    });
    // console.log(data?.chatInfo?.data);
    setChatData(data?.chatInfo?.data);
    if (data?.success === true) {
      setChatData(data?.chatInfo?.data);
      // console.log("chatData", chatData);
      // handleChatData(data?.chatInfo?.data)
    }
  };
  console.log(chatData);

  useEffect(() => {
    console.log(value);

    value && autoTimeUpdate(value)
  }, [value]);

  useEffect(() => {
    roomId && getChatInfo();
  }, [roomId]);

  const handleEditIconClick = () => {
    setIsEditing(true);
  };

  const hnaldeExitgroup = async () => {
    const { data } = await exitGroup({
      variables: {
        input: {
          code: roomId,
        },
      },
    });
    if (data?.exitGroup?.success === true) {
      navigate("/chats");
    }
    changeCount();
  };

  useEffect(() => {
    const getChatParticipants = async () => {
      const { data } = await totalChatParticipant({
        variables: {
          input: {
            code: roomId,
          },
        },
      });
      console.log(data?.chatParticipants?.users);
      setTotalParticipnat(data?.chatParticipants?.users);
    };
    getChatParticipants();
    console.log(totalParticipant);
  }, [count]);


  const handleDeleteMessage = async () => {
   
    // const chat_Id = chatId.toString();
    const { data } = await chatDelete({
      variables: {
        input: {
          code: roomId,
          // chat_id: chat_Id,
        },
      },
    });
    // console.log(data);
    handleModalClose()
  };


  const handleSave = () => {
    const handleNickNmae = async () => {
      const { data } = await Nickname({
        variables: {
          input: {
            name: text,
            user_id: chatData?.user_id,
          },
        },
      });
      console.log(data);
    };

    handleNickNmae();
    setIsEditing(false);
    changeCount();

    // Perform save or update logic here if needed
  };

  const handleCancel = () => {
    setIsEditing(false);
    // Reset the text to its original value or perform any other necessary actions
  };

  useEffect(() => {
    chatData &&setText(chatData?.display_name)
    chatData &&setValue(chatData?.delete_settings)
  
  }, [chatData]);

  const handleChange = (e) => {
    setText(e.target.value);
  };
  const handleColorClick = (e) => {
    const theme = e.target.getAttribute("data-color");
    document.body.setAttribute("data-theme", theme);
    setSelectedColor(theme);
  };

  const getFileExtension = (filename) => {
    return filename.slice(filename.lastIndexOf(".") + 1).toLowerCase();
  };

  const detectDocumentType = (link) => {
    const extension = getFileExtension(link);

    if (
      extension === "jpg" ||
      extension === "jpeg" ||
      extension === "png" ||
      extension === "gif"
    ) {
      return "photo";
    } else if (
      extension === "mp4" ||
      extension === "avi" ||
      extension === "mov" ||
      extension === "mkv"
    ) {
      return "video";
    } else if (extension === "pdf") {
      return "pdf";
    } else {
      return "unknown";
    }
  };

  const handleImageError = () => {
    setimageError(true);
    setimageError(true);
    
      };


  return (
    <div className="detail-area">
        <Modal
        open={isOpen}
        onClose={handleModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2"
            style={{ marginLeft: "50px" }}
          >
            Do You want Delete Chat
          </Typography>

          <Box style={{ marginLeft: "90px", marginTop: "20px" }}>
            <Button
              variant="contained"
              color="success"
              onClick={handleModalClose}
              style={{ marginRight: "50px" }}
            >
            Cancel
            </Button>
            <Button
              variant="contained"
              color="success"
              onClick={handleDeleteMessage}
            >
              Ok
            </Button>
          </Box>
        </Box>
      </Modal>
      <Media
        setViewMedia={setViewMedia}
        close={setIsViewMoreMedia}
        open={isViewMoreMedia}
      />
      <Groups
        totalMembers={totalParticipant}
        open={isAddParticipants}
        handleCount={handleCount}
        close={setIsAddParticipants}
      />
      <div className="detail-area-header">
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginLeft: "-204px",
            position: "relative",
            top: 40,
          }}
        >
          <AiOutlineClose
            onClick={() => setShowDetials(false)}
            style={{
              cursor: "pointer",
            }}
            size={24}
          />
        </div>

        {chatData?.is_group === 1 ? (
          <>
            <div className="user-profile-section">
              <div className="user-image">
                <img
                  className="user"
                  // src={
                  //   chatData?.image === null
                  //     ? "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQhLja_b1nZD7bzgvwtJ0JlhlQaKR3ModNySg&usqp=CAU"
                  //     : chatData?.image
                  // }

                  src={imageError===true?"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQhLja_b1nZD7bzgvwtJ0JlhlQaKR3ModNySg&usqp=CAU":chatData?.image}
        
        onError={handleImageError}

                  alt="user"
                  onClick={() =>
                    document.getElementById("groupFileInput").click()
                  }
                  style={{ cursor: "pointer" }}
                />
                <input
                  type="file"
                  id="groupFileInput"
                  accept="image/*"
                  onChange={handleFileInputChange}
                  style={{ display: "none" }}
                />
              </div>
            </div>
            <div style={{ marginLeft: "-25px" }}>
              <div className="content-1">
                <span className="ab-1">User name</span>
                <span className="ab"> {chatData?.name}</span>
              </div>
            </div>

            <div
              className="add-members"
              onClick={() => setIsAddParticipants(true)}
            >
              Add Participtans
            </div>

            <ul
              style={{
                width: "300px",
              }}
            >
              <span>Total Users {totalParticipant?.length}</span>

              {totalParticipant?.map((contact) => (
                <>
                  <UserData
                    chatData={chatData}
                    handleCount={handleCount}
                    data={contact}
                    name={contact.first_name}
                  />
                </>
              ))}
            </ul>

            <div
              onClick={hnaldeExitgroup}
              aria-disabled="false"
              role="button"
              tabindex="0"
              class="exitbutton"
              data-ignore-capture="any"
              data-testid="li-delete-group"
              title="Exit group"
            >
              <div class="exit-button">
                <span data-testid="exit" data-icon="exit" class="">
                  <MdOutlineExitToApp />
                </span>
              </div>

              <div class="Exit-text">
                <span class="Exit-text-value">Exit group</span>
              </div>
            </div>
          </>
        ) : (
          <div>
            <div className="user-profile-section">
              <div className="user-image">
                <img
                  className="user"
                  src={
                    chatData?.image === null
                      ? "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQhLja_b1nZD7bzgvwtJ0JlhlQaKR3ModNySg&usqp=CAU"
                      : chatData?.image
                  }
                  alt="user"
                />
              </div>
            </div>
            <div style={{ marginLeft: "-25px" }}>
              <div className="content-1">
                <span className="ab-1">User name</span>
                <span className="ab">{chatData?.name}</span>
              </div>
              <div className="content">
                <span className="ab-1">User Mobile Number</span>
                <span className="ab">{chatData?.mobile}</span>
              </div>
              <div className="content">
                <span className="ab-1">User Nick Name</span>

                <div className="edit-nick-name">
                  {isEditing ? (
                    <div>
                      <input
                        style={{
                          background: "none",
                        }}
                        type="text"
                        value={text}
                        onChange={handleChange}
                      />
                      <button onClick={handleSave}>Save</button>
                    </div>
                  ) : (
                    <>
                      <span className="ab">{text}</span>

                      <AiOutlineEdit
                        style={{
                          cursor: "pointer",
                          color: "black",
                          fontSize: "22",
                        }}
                        onClick={handleEditIconClick}
                      />
                    </>
                  )}
                </div>
              </div>
            </div>
            <div 
            onClick={handleOpen}
              aria-disabled="false"
              role="button"
              tabindex="0"
              class="exitbutton"
              data-ignore-capture="any"
              data-testid="li-delete-group"
              title="Delete Chat"

              style={{marginRight:"20px"}}
            >
              <div class="exit-button">
                <span data-testid="exit" data-icon="exit" class="">
                  <AiFillDelete />
                </span>
              </div>

              <div class="Exit-text">
                <span class="Exit-text-value">Delete Chat</span>
              </div>
            </div>
          </div>
        )}

        <div className="detail-buttons">
               
<FormControl>
      <FormLabel id="demo-controlled-radio-buttons-group">
        Auto Delete
      </FormLabel>
      <RadioGroup
        aria-labelledby="demo-controlled-radio-buttons-group"
        name="controlled-radio-buttons-group"
        value={value}
        onChange={handleChangeValue}
      >
        <FormControlLabel value="3" control={<Radio />} label="3 Days" />
        <FormControlLabel value="7" control={<Radio />} label="7 Days" />
        <FormControlLabel value="15" control={<Radio />} label="15 Days" />
        <FormControlLabel value="30" control={<Radio />} label="30 Days" />
        <FormControlLabel value="off" control={<Radio />} label="off" />
      </RadioGroup>
    </FormControl>
        </div>
      </div>
      <div className="detail-changes">
        {/* <input type="text" placeholder="Search in Conversation" /> */}
        {/* <div className="detail-change">
          Change Color
          <div className="colors">
            <div
              className="color blue selected"
              data-color="blue"
              onClick={handleColorClick}
            ></div>
            <div
              className="color purple"
              data-color="purple"
              onClick={handleColorClick}
            ></div>
            <div
              className="color green"
              data-color="green"
              onClick={handleColorClick}
            ></div>
          </div>
        </div> */}
      </div>
      <div className="detail-photos">
        <div className="detail-photo-title">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewbox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            className="feather feather-image"
          >
            <rect x="3" y="3" width="18" height="18" rx="2" ry="2" />
            <circle cx="8.5" cy="8.5" r="1.5" />
            <path d="M21 15l-5-5L5 21" />
          </svg>
          Shared photos
        </div>
        <div className="detail-photo-grid">
          {viewMedia?.slice(0, 4).map((chats) => {
            let link =
              chats?.chat?.type === "attachment" ? chats?.chat?.attachment : "";
            const documentType = detectDocumentType(link);
            return (
              <>
                {documentType === "photo" ? (
                  <img src={chats?.chat?.attachment} />
                ) : (
                  ""
                )}
              </>
            );
          })}

          {/* <img src="https://images.unsplash.com/photo-1523049673857-eb18f1d7b578?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2168&q=80" /> */}
        </div>
        <div onClick={() => setIsViewMoreMedia(true)} className="view-more">
          View More
        </div>
      </div>
    </div>
  );
};

export default ChatDetails;
