import React, { useEffect, useState } from "react";
import chat from "../../assets/images/chat.jpg";
import ChatAreaFooter from "./ChatAreaFooter";
import { SlOptionsVertical } from "react-icons/sl";
import audio from "../../assets/vivo.mp3";
import { useNavigate, useParams } from "react-router";
import { gql, useMutation } from "@apollo/client";
import CircularProgress from "@mui/material/CircularProgress";
import Popover from "@mui/material/Popover";
import empty from "../../assets/images/empty-chat.jpg";
import { useContext } from "react";
import { AuthContext, AuthProvider } from "../../hooks/AuthContext";
import { AiOutlineDown, AiOutlineClose } from "react-icons/ai";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import { MdDone, MdDoneAll } from "react-icons/md";

import { useRef } from "react";
import { useMemo } from "react";
import Reply from "./Reply";
import UserReply from "./UserReply";
import ExcelViewer from "./ExcelViewer";
import WordView from "./WordView";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import ChatInfoMessage from "./ChatInfoMessage";

const CHAT_DELETE_MUTATION = gql`
  mutation DeleteChat($input: DeleteChatRequest) {
    deleteChat(input: $input) {
      success
      message
    }
  }
`;

const CHATLIST_MUTATION = gql`
  mutation RoomChats($input: RoomChatsRequest) {
    roomChats(input: $input) {
      success
      message
      chats {
        user {
          id
          first_name
          last_name
        }
        chat {
          id
          message
          created_at
          attachment
          type
          reply_to
          is_forwarded
          reply_message
          reply_attachment
          reply_type
          is_read
          reply_to
          is_forwarded
        }
        from_me
      }
    }
  }
`;

const CHAT_INFO_MUTATION = gql`
  mutation ChatInfo($input: RoomChatsRequest) {
    chatInfo(input: $input) {
      success
      message
      data {
        user_id
        display_name
        name
        email
        mobile
        is_default_delete
        delete_settings
        image
        is_group
      }
    }
  }
`;

const CHAT_ID_MUTATION = gql`
  mutation ChatDetail($input: DeleteChatRequest) {
    chatDetail(input: $input) {
      success
      message
      chat {
        user_id
        room_code
        message
        attachment
        type
        reply_to
        is_forwarded
        is_forwarded
        reply_message
      }
      from_me
    }
  }
`;

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: "20px",
  boxShadow: 24,
  p: 4,
};
const style2 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  borderRadius: "20px",
  transform: "translate(-50%, -50%)",
  width: 969,
  bgcolor: "background.paper",
  height: 764,
  boxShadow: 24,
  p: 4,
  overflow:"scroll"
};

let chatId = "";
let chatMessage='';
let idList = [];
let chatInfoData = [];
let fileType = "";
const ChatMain = ({ setShowDetials }) => {
  const { count, hanldeReply } = useContext(AuthContext);

  const { roomId } = useParams();
  const { userData, changeCount } = useContext(AuthContext);
  const [showCheckboxes, setShowCheckboxes] = useState(false);
  const [selectedMessages, setSelectedMessages] = useState([]);
  const [load, setLoad] = useState();
  const [imageError, setimageError] = useState(false);
const [chatInofMessage, setchatInofMessage] = useState(false);
  const [chatData, setChatData] = useState();
  const [isUser, setIsUser] = useState();
  const [ChatList] = useMutation(CHATLIST_MUTATION);
  const [chatInfo] = useMutation(CHAT_INFO_MUTATION);
  const [chatDelete] = useMutation(CHAT_DELETE_MUTATION);
  const [chatIdData, { data }] = useMutation(CHAT_ID_MUTATION);
  // const [chatInfoData, setChatInfoData] = useState();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorE2, setAnchorE2] = React.useState(null);
  const [checked, setChecked] = React.useState(true);
  const [isForward, setIsForward] = useState(false);
  const [callApi, setCallApi] = useState(true);
  const chatContainerRef = useRef(null);
  const stringRef = useRef("");
  const imageRef = useRef("");
  const [openImage, setOpenImage] = React.useState(false);
  const handleOpenImage = () => setOpenImage(true);
  const handleCloseImage = () => setOpenImage(false);
  const [imageLink, setImageLink] = useState();

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const [isOpen, setIsOpen] = React.useState(false);
  const handleOpen = () => setIsOpen(true);
  const handleModalClose = () => {
    setIsOpen(false);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClickMessagePop = (event) => {
    setAnchorE2(event.currentTarget);
    setCallApi(false);
    // console.log(callApi);
  };
  // console.log(callApi);

  const handleCloseMessagePop = () => {
    setAnchorE2(null);
    setCallApi(true);
  };

  const popoverOpeon = Boolean(anchorEl);
  // console.log(popoverOpeon);
  const id = popoverOpeon ? "simple-popover" : undefined;

  const popoverMessage = Boolean(anchorE2);
  const idMessage = popoverMessage ? "simple-popover" : undefined;


  useEffect(()=>{
    setIsForward(false)
    // setIsForward(false)
    setShowCheckboxes(false)
    setSelectedMessages([]);

  },[roomId])

  const toggleCheckboxes = () => {
    setShowCheckboxes(true);
    setIsForward(true);
    setSelectedMessages([]);
  };
  // console.log(selectedMessages);

  // const handleCheckboxChange = (messageId) => {
  //   setSelectedMessages((prevSelectedMessages) => {
  //     if (prevSelectedMessages.includes(messageId)) {
  //       return prevSelectedMessages.filter((id) => id.id !== messageId.id);
  //     } else {
  //       return [...prevSelectedMessages, messageId];
  //     }
  //   });
  // };
  console.log(selectedMessages);
  const handleCheckboxChange = useMemo(
    () => (messageId) => {
      setSelectedMessages((prevSelectedMessages) => {
        if (prevSelectedMessages.find((msg) => msg.id === messageId.id)) {
          return prevSelectedMessages.filter((msg) => msg.id !== messageId.id);
        } else {
          return [...prevSelectedMessages, messageId];
        }
      });
    },
    []
  );

  const getChatInfo = async () => {
    const { data } = await chatInfo({
      variables: {
        input: {
          code: roomId,
        },
      },
    });
    // console.log(data?.chatInfo?.data);

    // setChatInfoData(data?.chatInfo?.data);
    chatInfoData = data?.chatInfo?.data;
    if (data?.success === true) {
      // setChatInfoData(data?.chatInfo?.data);
      chatInfoData = data?.chatInfo?.data;

      // console.log("chatData", chatInfoData);
    }
  };

  const getChatList = async () => {
    try {
      const { data } = await ChatList({
        variables: { input: { code: roomId } },
      });
      setChatData(data.roomChats.chats);
      setLoad(false);
      // console.log("data==>", data.roomChats);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    chatData &&
      chatContainerRef.current.scrollIntoView({
        behavior: "smooth",
        block: "end",
      });
  }, [chatData]);

  useEffect(() => {
    if (roomId) {
      getChatInfo();
      getChatList();
    }
  }, [count]);

  useEffect(() => {
    if (roomId) {
      getChatList();
      getChatInfo();
      setSelectedMessages([]);
    }
    const intervalId = setInterval(() => {
      // Call your function here
      roomId && getChatList();
    }, 3000);

    // Clean up the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, [roomId]);

  useEffect(() => {
    setLoad(true);
    setShowDetials(false);
  }, [roomId]);
  // console.log("chat", chatData);
  //   console.log(roomId);
  const [isOptionsOpen, setIsOptionsOpen] = useState(false);

  const handleNavigate = () => {
    handleClose();
    navigate("/chats");
  };

  // console.log(chatInfoData?.image);

  const getFileExtension = (filename) => {
    return filename.slice(filename.lastIndexOf(".") + 1).toLowerCase();
  };

  const detectDocumentType = (link) => {
    const extension = getFileExtension(link);

    if (
      extension === "jpg" ||
      extension === "jpeg" ||
      extension === "png" ||
      extension === "gif"
    ) {
      return "photo";
    } else if (
      extension === "mp4" ||
      extension === "avi" ||
      extension === "mov" ||
      extension === "mkv"
    ) {
      return "video";
    } else if (extension === "pdf") {
      return "pdf";
    } else if (extension === "doc" || extension === "docx") {
      return "word";
    } else if (extension === "xls" || extension === "xlsx") {
      return "excel";
    } else {
      return "unknown";
    }
  };

  const getChatIdInfo = async (id) => {
    // console.log(idList);
    if (id === "0") return;

    if (idList.includes(id)) return;
    idList.push(id);

    const response = await chatIdData({
      variables: {
        input: {
          chat_id: id,
          code: roomId,
        },
      },
    });
  };

  const replyData = (data) => {
    // console.log("data reply", data);
    let chatReplyData = {
      reply: true,
      id: data?.chat?.id,
      message: data?.chat?.message,
      type: data?.chat?.type,
      attachment: data?.chat?.attachment,
      user: {
        first_name: data?.user?.first_name,
        last_name: data?.user?.last_name,
      },
    };
    hanldeReply(chatReplyData);
  };
  // console.log(chatId);

  const handleDeleteMessage = async () => {
    console.log(stringRef.current.value);
    console.log(chatId);
    handleModalClose();

    if (chatId === "") return;
    const chat_Id = chatId.toString();
    const { data } = await chatDelete({
      variables: {
        input: {
          code: roomId,
          chat_id: chat_Id,
        },
      },
    });
    // console.log(data);
    handleClose();
    handleCloseMessagePop();
  };
  console.log(imageLink);


  console.log(fileType);

  const handleImageError = () => {
    setimageError(true);
    setimageError(true);
    
      };


      
  return (
    <>

<ChatInfoMessage 
message={chatMessage}
chatId={chatId}
 open={chatInofMessage} close={()=>setchatInofMessage(false)} />

      <Modal
        open={openImage}
        onClose={handleCloseImage}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style2}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItem: "center",
              justifyContent: "center",
            }}
          >
            <span
              style={{
                cursor: "pointer",
                position: "relative",
                left: "869px",
              }}
              onClick={handleCloseImage}
            >
              <AiOutlineClose fontSize={22} />
            </span>
            {fileType === "pdf" ? (
              <iframe
                style={{
                  height: 700,
                  overflow: "scroll",
                  objectFit: "contain",
                }}
                src={imageLink}
              />
            ):
            fileType === "excel"? (
              <ExcelViewer
              excelFileUrl={imageLink}
            />
            ):
            fileType === "word"? (
              <WordView
              documentUrl={imageLink}
            />
            ):
              (
              <img
                style={{ height: "668px", width: "77%", objectFit: "contain" }}
                src={imageLink}
                alt="image"
              />
            )
            }
          </div>
        </Box>
      </Modal>
      <Modal
        open={isOpen}
        onClose={handleModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Delete Messaage
          </Typography>

          <Box style={{ marginLeft: "90px", marginTop: "20px" }}>
            <Button
              variant="contained"
              color="success"
              onClick={handleDeleteMessage}
            >
              Ok
            </Button>
          </Box>
        </Box>
      </Modal>
      <Popover
        id={idMessage}
        open={popoverMessage}
        anchorEl={anchorE2}
        onClose={handleCloseMessagePop}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Typography sx={{ p: 1 }}>
          <li
            style={{
              cursor: "pointer",
              marginBottom: "10px",
            }}
onClick={()=>{setchatInofMessage(true)
  handleCloseMessagePop()}
}
            // onClick={handleOpen}
          >
            Chat Info Message
          </li>
          <li
            style={{
              cursor: "pointer",
              marginBottom: "10px",
            }}
            onClick={handleOpen}
          >
            Delete Message
          </li>

          <li
            style={{
              cursor: "pointer",
            }}
            onClick={toggleCheckboxes}
          >
            Forward Message
          </li>
          <li
            style={{
              cursor: "pointer",
            }}
            // onClick={toggleCheckboxes}
          >
                   <CopyToClipboard text={chatMessage}
          >
          <div>

          Copy to clipboard with button
          </div>

        </CopyToClipboard>

          </li>
        </Typography>
      </Popover>
      {roomId ? (
        <div className="chat-area">
          <div
            style={{
              cursor: "pointer",
              marginBottom: "20px",
            }}
            className="chat-area-header"
          >
            <div
              className="chat-area-title "
              onClick={() => setShowDetials(true)}
            >
              {" "}
              <img
                className="chat-area-profile"
                // src={
                //   chatInfoData?.image === null
                //     ? "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQhLja_b1nZD7bzgvwtJ0JlhlQaKR3ModNySg&usqp=CAU"
                //     : chatInfoData?.image
                // }
                src={imageError===true?"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQhLja_b1nZD7bzgvwtJ0JlhlQaKR3ModNySg&usqp=CAU":chatInfoData?.image}
        
        onError={handleImageError}



                alt=""
              />
              {chatInfoData?.display_name}
            </div>
            <div className="chat-area-group">
              <div className="more-options" style={{ marginLeft: "10px" }}>
                <div className="options-icon" onClick={handleClick}>
                  <SlOptionsVertical />
                </div>
                <Popover
                  id={id}
                  open={popoverOpeon}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                >
                  <div
                    style={{
                      width: "200px",
                    }}
                    className="options-list"
                  >
                    <ul className="menu-options">
                      {/* <li>
                      <span>Group Info</span>
                    </li> */}
                      <li onClick={toggleCheckboxes}>
                        <span>Select Messages</span>
                      </li>

                      <li onClick={handleNavigate}>
                        <span>Close Chat</span>
                      </li>
                    </ul>
                  </div>
                </Popover>
              </div>
            </div>
          </div>
          {load ? (
            <div
              style={{
                height: 835,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <>
              <div className="chat-area-main">
                {chatData?.map((chats) => {
                  const timestamp = parseInt(chats.chat.created_at);
                  const dateObject = new Date(timestamp);
                  const timeString = dateObject.toLocaleTimeString([], {
                    timeStyle: "short",
                  });
                  let link =
                    chats?.chat?.type === "attachment"
                      ? chats?.chat?.attachment
                      : "";
                  const documentType = detectDocumentType(link);
                  // console.log(documentType);
                  // let chatId = chats?.chat?.reply_to.toString();
                  // console.log(typeof(chatId));
                  // console.log("reply Id", chatId);
                  // let replyData;
                  let response;
                  let replyMessage;

                  // console.log(replyMessage);

                  if (chatId !== 0) {
                    // getChatIdInfo(chatId);
                  }
                  // console.log(data);

                  // chatId!=="0"?replyData=getChatIdInfo(chatId):""

                  // console.log(replyData);
                  return (
                    <div>
                      {/* ds */}
                      {showCheckboxes && (
                        <div style={{ position: "relative", top: "63px" }}>
                          <Checkbox
                            checked={selectedMessages.includes(chats?.chat)}
                            onChange={() => handleCheckboxChange(chats?.chat)}
                            inputProps={{ "aria-label": "controlled" }}
                          />
                        </div>
                      )}
                      {chats.from_me ? (
                        <div>
                          <div className="chat-msg owner">
                            <div className="chat-msg-profile">
                              <img
                                className="chat-msg-img"
                                src={
                                  userData?.image === null
                                    ? "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQhLja_b1nZD7bzgvwtJ0JlhlQaKR3ModNySg&usqp=CAU"
                                    : userData?.image
                                }
                                alt=""
                              />
                            </div>
                            <div>
                              <div className="chat-msg-content-1">
                                {chats?.chat?.type === "text" ? (
                                  <div onClick={() => replyData(chats)}>
                                    {chats?.chat?.reply_to === 0 ? (
                                      <div className="chat-msg-text">
                                        {chats.chat.message}
                                        <div className="chat-msg-date">
                                          {timeString}



{
  chats?.chat.is_read===0?  <MdDone  size={18} />:
  <MdDoneAll size={18} />

}

                                        </div>
                                        <div
                                          onClick={(e) => {
                                            handleClickMessagePop(e);
                                            stringRef.current = chats?.chat?.id;
                                            chatMessage=chats?.chat?.message
                                            chatId = chats?.chat?.id;
                                            console.log("chatId", chatId);
                                          }}
                                          className="chat-menu-options"
                                        >
                                          <AiOutlineDown />
                                        </div>
                                      </div>
                                    ) : (
                                      <Reply chats={chats} />
                                    )}
                                  </div>
                                ) : (
                                  <div>
                                    {documentType === "photo" ? (
                                      <div
                                        className="chat-msg-text-3"
                                        onClick={() => {
                                          handleOpenImage();
                                          setImageLink(chats?.chat?.attachment);
                                          fileType = "photo";
                                        }}
                                      >
                                        <img
                                          src={chats?.chat?.attachment}
                                          alt=""
                                        />

                                        <div className="chat-msg-date">
                                          {timeString}
                                          {
  chats?.chat.is_read===0?  <MdDone  size={18} />:
  <MdDoneAll size={18} />

}
                                        </div>
                                        <div
                                          onClick={(e) => {
                                            handleClickMessagePop(e);
                                            chatMessage=chats?.chat?.message

                                            chatId = chats?.chat?.id;
                                          }}
                                          className="chat-menu-options"
                                        >
                                          <AiOutlineDown />
                                        </div>
                                      </div>
                                    ) : documentType === "video" ? (
                                      <div className="chat-msg-text-4">
                                        <video
                                          src={chats?.chat?.attachment}
                                          controls
                                          alt=""
                                          style={{
                                            width: "227px",
                                            height: "169px",
                                          }}
                                        />

                                        <div className="chat-msg-date">
                                          {timeString}
                                          {
  chats?.chat.is_read===0?  <MdDone  size={18} />:
  <MdDoneAll size={18} />

}
                                        </div>
                                        <div
                                          onClick={(e) => {
                                            handleClickMessagePop(e);
                                            chatMessage=chats?.chat?.message

                                            chatId = chats?.chat?.id;
                                          }}
                                          className="chat-menu-options"
                                        >
                                          <AiOutlineDown />
                                        </div>
                                      </div>
                                    ) : chats?.chat?.message === "voice" ? (
                                      <div className="chat-msg-text">
                                        <audio
                                          src={chats?.chat?.attachment}
                                          controls
                                        />

                                        <div className="chat-msg-date">
                                          {timeString}
                                          {
  chats?.chat.is_read===0?  <MdDone  size={18} />:
  <MdDoneAll size={18} />

}
                                        </div>
                                        <div
                                          onClick={(e) => {
                                            handleClickMessagePop(e);
                                            chatMessage=chats?.chat?.message

                                            chatId = chats?.chat?.id;
                                          }}
                                          className="chat-menu-options"
                                        >
                                          <AiOutlineDown />
                                        </div>
                                      </div>
                                    ) : documentType === "pdf" ? (
                                      <div
                                        className="chat-msg-text-3"
                                        onClick={() => {
                                          handleOpenImage();
                                          setImageLink(chats?.chat?.attachment);
                                          fileType = "pdf";
                                        }}
                                      >
                                        <iframe
                                          src={chats?.chat?.attachment}
                                          title="PDF Viewer"
                                          width="100%"
                                          // height="500px"
                                          height="fitContent"
                                        />

                                        <div className="chat-msg-date">
                                          {timeString}
                                          {
  chats?.chat.is_read===0?  <MdDone  size={18} />:
  <MdDoneAll size={18} />

}
                                        </div>
                                        <div
                                          onClick={(e) => {
                                            handleClickMessagePop(e);
                                            chatMessage=chats?.chat?.message

                                            chatId = chats?.chat?.id;
                                          }}
                                          className="chat-menu-options"
                                        >
                                          <AiOutlineDown />
                                        </div>
                                      </div>
                                    ) 
                                    :
                                     documentType === "excel" ? (
                                      <div className="chat-msg-content-1">       
                                      <div style={{color: "green", backgroundColor: "#c9c8c8"}}
                                        className="chat-msg-text"
                                        onClick={() => {
                                          handleOpenImage();
                                          setImageLink(chats?.chat?.attachment);
                                          fileType = "excel";
                                        }}
                                      >

Excel File


                                     

                                        <div className="chat-msg-date">
                                          {timeString}
                                          {
  chats?.chat.is_read===0?  <MdDone  size={18} />:
  <MdDoneAll size={18} />

}
                                        </div>
                                        <div
                                          onClick={(e) => {
                                            handleClickMessagePop(e);
                                            chatMessage=chats?.chat?.message

                                            chatId = chats?.chat?.id;
                                          }}
                                          className="chat-menu-options"
                                        >
                                          <AiOutlineDown />
                                        </div>
                                      </div>
                                      </div>
                                    ) 
                                    
                                    
                                    :
                                     documentType === "word" ? (
                                      <div className="chat-msg-content-1">       
                                      <div style={{color: "green", backgroundColor: "#c9c8c8"}}
                                        className="chat-msg-text"
                                        onClick={() => {
                                          handleOpenImage();
                                          setImageLink(chats?.chat?.attachment);
                                          fileType = "word";
                                        }}
                                      >

Word File


                                     

                                        <div className="chat-msg-date">
                                          {timeString}
                                          {
  chats?.chat.is_read===0?  <MdDone  size={18} />:
  <MdDoneAll size={18} />

}
                                        </div>
                                        <div
                                          onClick={(e) => {
                                            handleClickMessagePop(e)
                                            chatMessage=chats?.chat?.message
;
                                            chatId = chats?.chat?.id;
                                          }}
                                          className="chat-menu-options"
                                        >
                                          <AiOutlineDown />
                                        </div>
                                      </div>
                                      </div>
                                    ) 
                                    
                                    :
                                     (
                                      ""
                                    )}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="chat-msg">
                          <div className="chat-msg-profile">
                            <img
                              src={
                                chatInfoData?.image === null
                                  ? "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQhLja_b1nZD7bzgvwtJ0JlhlQaKR3ModNySg&usqp=CAU"
                                  : chatInfoData?.image
                              }
                              alt=""
                              className="chat-msg-img account-profile"
                            />
                            {/* <div className="chat-msg-date">{timeString}</div> */}
                          </div>
                          <div onClick={() => replyData(chats)}>
                            <div className="chat-msg-content">
                              {chats?.chat?.type === "text" ? (
                                <div>
                                  {chats?.chat?.reply_to === 0 ? (
                                    <div className="chat-msg-text">
                                      {chats.chat.message}
                                      <div className="chat-msg-date-1">
                                        {timeString}
                                      </div>{" "}
                                      <div
                                        onClick={(e) => {
                                          handleClickMessagePop(e);
                                          chatMessage=chats?.chat?.message

                                          chatId = chats?.chat?.id;
                                        }}
                                        className="chat-menu-options"
                                      >
                                        <AiOutlineDown />
                                      </div>
                                    </div>
                                  ) : (
                                    <UserReply chats={chats} />
                                  )}
                                </div>
                              ) : (
                                <div>
                                  {documentType === "photo" ? (
                                    <div
                                      onClick={() => {
                                        handleOpenImage();
                                        setImageLink(chats?.chat?.attachment);
                                        fileType = "photo";
                                      }}
                                      className="chat-msg-text-5"
                                    >
                                      <img
                                        src={chats?.chat?.attachment}
                                        alt=""
                                      />
                                      <div className="chat-msg-date-1">
                                        {timeString}
                                      </div>{" "}
                                      <div
                                        onClick={(e) => {
                                          handleClickMessagePop(e);
                                          chatMessage=chats?.chat?.message

                                          chatId = chats?.chat?.id;
                                        }}
                                        className="chat-menu-options"
                                      >
                                        <AiOutlineDown />
                                      </div>
                                    </div>
                                  ) : documentType === "video" ? (
                                    <div className="chat-msg-text-4">
                                      <video
                                        src={chats?.chat?.attachment}
                                        controls
                                        alt=""
                                        style={{
                                          width: "227px",
                                          height: "169px",
                                        }}
                                      />
                                      <div className="chat-msg-date-1">
                                        {timeString}
                                      </div>{" "}
                                      <div
                                        onClick={(e) => {
                                          handleClickMessagePop(e);
                                          chatMessage=chats?.chat?.message

                                          chatId = chats?.chat?.id;
                                        }}
                                        className="chat-menu-options"
                                      >
                                        <AiOutlineDown />
                                      </div>
                                    </div>
                                  ) : documentType === "pdf" ? (
                                    <div
                                      className="chat-msg-text-5"
                                      onClick={() => {
                                        handleOpenImage();
                                        setImageLink(chats?.chat?.attachment);
                                        fileType = "pdf";
                                      }}
                                    >
                                      <iframe
                                        src={chats?.chat?.attachment}
                                        title="PDF Viewer"
                                        width="100%"
                                        // height="500px"
                                        height="fitContent"
                                      />
                                      <div className="chat-msg-date-1">
                                        {timeString}
                                      </div>{" "}
                                      <div
                                        onClick={(e) => {
                                          handleClickMessagePop(e);
                                          chatMessage=chats?.chat?.message

                                          chatId = chats?.chat?.id;
                                        }}
                                        className="chat-menu-options"
                                      >
                                        <AiOutlineDown />
                                      </div>
                                    </div>
                                  ) : chats?.chat?.message === "voice" ? (
                                    <div className="chat-msg-text">
                                      <audio
                                        src={chats?.chat?.attachment}
                                        controls
                                      />

                                      <div className="chat-msg-date">
                                        {timeString}
                                      </div>
                                      <div
                                        onClick={(e) => {
                                          handleClickMessagePop(e);
                                          chatMessage=chats?.chat?.message

                                          chatId = chats?.chat?.id;
                                        }}
                                        className="chat-menu-options"
                                      >
                                        <AiOutlineDown />
                                      </div>
                                    </div>
                                  )
                                  
                                  :
                                  documentType === "excel" ? (
                                    <div className="chat-msg-content-1">       
                                    <div style={{color: "green", backgroundColor: "#c9c8c8"}}
                                      className="chat-msg-text"
                                      onClick={() => {
                                        handleOpenImage();
                                        setImageLink(chats?.chat?.attachment);
                                        fileType = "excel";
                                      }}
                                    >

Excel File


                                   

                                      <div className="chat-msg-date">
                                        {timeString}
                                      </div>
                                      <div
                                        onClick={(e) => {
                                          handleClickMessagePop(e);
                                          chatMessage=chats?.chat?.message

                                          chatId = chats?.chat?.id;
                                        }}
                                        className="chat-menu-options"
                                      >
                                        <AiOutlineDown />
                                      </div>
                                    </div>
                                    </div>
                                  ) 
                                  
                                  
                                  :
                                   documentType === "word" ? (
                                    <div className="chat-msg-content-1">       
                                    <div style={{color: "green", backgroundColor: "#c9c8c8"}}
                                      className="chat-msg-text"
                                      onClick={() => {
                                        handleOpenImage();
                                        setImageLink(chats?.chat?.attachment);
                                        fileType = "word";
                                      }}
                                    >

Word File


                                   

                                      <div className="chat-msg-date">
                                        {timeString}
                                      </div>
                                      <div
                                        onClick={(e) => {
                                          handleClickMessagePop(e);
                                          chatMessage=chats?.chat?.message

                                          chatId = chats?.chat?.id;
                                        }}
                                        className="chat-menu-options"
                                      >
                                        <AiOutlineDown />
                                      </div>
                                    </div>
                                    </div>
                                  ) 
                                  
                                  
                                  
                                  : (
                                    ""
                                  )}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  );
                })}
                <div ref={chatContainerRef} />
              </div>

              <ChatAreaFooter
                forward={isForward}
                setIsForward={setIsForward}
                setShowCheckboxes={setShowCheckboxes}
                selectedMessages={selectedMessages}
              />
            </>
          )}
        </div>
      ) : (
        <div>
          <img src={empty} style={{ margin: "auto", width: "65%" }} alt="" />
        </div>
      )}
    </>
  );
};

export default ChatMain;
