import React, { useContext, useState } from "react";
import { IoMdClose } from "react-icons/io";
import "./Modal.css";
import "../../../assets/sass/profile.scss";
import { useNavigate } from "react-router";
import { gql, useMutation, useQuery } from "@apollo/client";
import { useRef } from "react";
import usePost from "../../../hooks/Api/usePost";
import { useEffect } from "react";
import { AuthContext } from "../../../hooks/AuthContext";
const apiEndpoint = "https://app.steelconnect.co.in:3000/upload";

const PROFILE_QUERY = gql`
  query Profile {
    profile {
      success
      message
      user {
        first_name
        last_name
        image
        email
        mobile
        id
      }
    }
  }
`;

const SET_PROFILE_MUTATION = gql`
  mutation SetProfilePicture($input: SetProfilePicture) {
    setProfilePicture(input: $input) {
      message
      success
    }
  }
`;

// let url=""
const Profile = ({ open, close }) => {
  const { handleUserData } = useContext(AuthContext);
  const navigate = useNavigate();
  const [isOtpActive, setIsOtpActive] = useState(false);
  const [imageError, setimageError] = useState(false);

  const { data: userData, refetch } = useQuery(PROFILE_QUERY);
  if (userData) {
    handleUserData(userData?.profile?.user);
  }
  const [setProfileImage] = useMutation(SET_PROFILE_MUTATION);

  const { makeRequest, data, isLoading, error } = usePost(apiEndpoint);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadedImageUrl, setUploadedImageUrl] = useState("");
  const [uploadError, setUploadError] = useState("");
  const [url, setUrl] = useState(null);
  const fileInputRef = useRef(null);

  const handleClick = () => {
    fileInputRef.current.click();
  };

  const handleImageUpload = async () => {
    if (!selectedFile) return;
    // console.log(selectedFile);
    try {
      let formData = {
        file: selectedFile,
      };
      // console.log(formData);
      let token = localStorage.getItem("token");

      await makeRequest(formData, token);
      // console.log(data?.url);
      setUrl(data?.url);
      if (url !== null) {
        // handleProfilePic()
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleProfilePic = async () => {
    if(url===null) return;
    try {
      const { data } = await setProfileImage({
        variables: {
          input: {
            link: url,
          },
        },
      });
      // console.log(data);
      if (data?.setProfilePicture?.success === true) {
        refetch();
        refetch();
        setSelectedFile(null);
        setUrl(null);
      }
      setUrl(null);

    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
   data && setUrl(data?.url);
  }, [data]);

  useEffect(() => {
    // console.log(url);
    // console.log(data.url);

    if (url !== null) {
      handleProfilePic();
    }
  }, [url]);

  useEffect(() => {
    selectedFile&&  handleImageUpload();
  }, [selectedFile]);

  const handleFileInputChange = (e) => {
    setSelectedFile(e.target.files[0]);
    // console.log(selectedFile);
  };

  let user = userData?.profile?.user;

  // console.log(firstName);

  // console.log(user[0]?.first_name);


  const handleImageError = () => {
    setimageError(true);
    setimageError(true);
    
      };

  return (
    <>
      <div className={open ? "profile-modal open" : "profile-modal"}>
        <div className="modal-header">
          <h2>My Profile</h2>
          <div className="modal-close" onClick={() => close(false)}>
            <IoMdClose />
          </div>
        </div>

        <div>
          <div className="user-profile-section">
            <div className="user-image">
              <img


                className="user"
                // src={user?.image}
                       
        src={imageError===true?"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQhLja_b1nZD7bzgvwtJ0JlhlQaKR3ModNySg&usqp=CAU":user?.image}
        
        onError={handleImageError}
                onClick={() => document.getElementById("fileInput").click()}
                style={{ cursor: "pointer" }}
              />
              <input
                type="file"
                id="fileInput"
                accept="image/*"
                onChange={handleFileInputChange}
                style={{ display: "none" }}
              />
            </div>
          </div>

          <div className="content-1">
            <span className="ab-1">My Name</span>
            <span className="ab">
              {user?.first_name} &nbsp;
              {user?.last_name}
            </span>
          </div>
          <div className="content">
            <span className="ab-1">My Mobile Number</span>
            <span className="ab">{user?.mobile}</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default Profile;
