import React, { useEffect, useState } from 'react'
import { useContext } from 'react';
import { getAllGroupsData } from '../../Api';
import GroupTable from '../../components/admin/GroupTable'
const apiEndpoint='https://app.steelconnect.co.in:3000/api/admin/messages/groups?page=1'
import useGet from '../../hooks/Api/useGet'
import { AuthContext } from '../../hooks/AuthContext';

let groups;
let reverseGroup=[]
const AllGroups = () => {
  const {handleGroupData} =useContext(AuthContext)

  const [updateUser,setUpdteUser]=useState(0);
  const [searchfield, setSearchField] = useState("");

  
  const handleUser=()=>{

    setUpdteUser(updateUser+1);
  }
  // const [groups,setUsers]=useState()
  const { makeRequest, data, isLoading, error } = useGet(apiEndpoint);
  

  useEffect(()=>{
    let token=localStorage.getItem('token');
    console.log(token);
    const getData=async()=>{
      await makeRequest(token)
      // groups=data?.groups
    }
getData()
groups=data?.data

},[updateUser])



useEffect(()=>{
console.log('dataUpadated',data?.data);
groups=data?.data
// handleGroupData(data?.data)
reverseGroup=groups?.slice().reverse()

},[data])

const filteredGropus = groups?.filter((user) => {

  const name =user?.name?.toLowerCase().includes(searchfield?.toLowerCase())
 
  return name 
});




  groups=data?.data
reverseGroup=groups?.slice().reverse()

  console.log(data);
  console.log(groups);

  return (
    <div className='row'>
      <div className='col-md-12'>
      <main>
        <div class="page-heading mt-5">
            <h1>Manage Groups</h1>
        </div>
        <div className="col-md-8">
         
         <div className="search-bar">
<input type="search" placeholder="Search" 
onChange={(e)=>setSearchField(e.target.value)}
/>
</div>
     </div>

      {
        searchfield?(
          <GroupTable handleUser={handleUser} groups={filteredGropus} heading={'Groups'}/>
        ):  <GroupTable handleUser={handleUser} groups={reverseGroup} heading={'Groups'}/>
      }
        </main>
      </div>
    </div>
  )
}

export default AllGroups
