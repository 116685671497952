import React from 'react'

const DocsFile = ({chatMedia}) => {
  const getFileExtension = (filename) => {
    return filename.slice(filename.lastIndexOf(".") + 1).toLowerCase();
  };

  const detectDocumentType = (link) => {
    const extension = getFileExtension(link);

    if (
      extension === "jpg" ||
      extension === "jpeg" ||
      extension === "png" ||
      extension === "gif"
    ) {
      return "photo";
    } else if (
      extension === "mp4" ||
      extension === "avi" ||
      extension === "mov" ||
      extension === "mkv"
    ) {
      return "video";
    } else if (extension === "pdf") {
      return "pdf";
    } else {
      return "unknown";
    }
  };
  return (
    
    <div>

{chatMedia&&chatMedia?.map((chats)=>{
 let link =
 chats?.chat?.type === "attachment"
   ? chats?.chat?.attachment
   : "";
const documentType = detectDocumentType(link);
  return (
    <>
    {
documentType==="pdf"?
    
<div className='mediagrp'>
     <iframe className="Mediaimg"                              src={chats?.chat?.attachment}
     
     />

    </div>
    :""

    }
    </>
  )
})}
    </div>
  )
}

export default DocsFile
