import React from 'react'

const LinksFile = () => {
  return (
    <div>
      Links File
    </div>
  )
}

export default LinksFile
