import React, { useEffect, useState } from "react";
import MessageTable from "../../components/admin/MessageTable";
import { Button, Col, Row } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import FormRow from "../../components/admin/FormRow";
import FormRowSelect from "../../components/admin/FormRowSelect";

const apiEndpoint =
  "https://app.steelconnect.co.in:3000/api/admin/messages/p2p?page=1";
import useGet from "../../hooks/Api/useGet";
import { AuthContext } from "../../hooks/AuthContext";
import { useContext } from "react";

// const groupendpoint='https://app.steelconnect.co.in:3000/api/admin/messages/groups?page=1'
// import useGet from '../../hooks/Api/useGet'
let users;
const Messages = () => {
  const {groupData}=useContext(AuthContext)
  // console.log(groupData);
  const userOptions = ["Select Filter", "Chat Type", ];
  const chatOptions = ["Select Type", "Normal Chat", "Group Chat"];

  const [filterOption, setFilterOption] = useState("");
  const [chatFilterOption, setChatFilterOption] = useState("");

  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");

  const [isEditing, setIsEditing] = useState(false);

  const [updateUser, setUpdteUser] = useState(0);
  const [searchfield, setSearchField] = useState("");

  const handleUser = () => {
    setUpdteUser(updateUser + 1);
  };
  // const [users,setUsers]=useState()
  const { makeRequest, data, isLoading, error } = useGet(apiEndpoint);

  useEffect(() => {
    let token = localStorage.getItem("token");
    // console.log(token);
    const getData = async () => {
      await makeRequest(token);
      // users=data?.users
    };
    getData();
    users = data?.data;
  }, [updateUser]);

  useEffect(() => {
    // console.log("dataUpadated", data);
    users = data?.data;
  }, [data]);

  users = data?.data;

  const filteredUsers = users?.filter((user) => {

    const name =user?.name?.toLowerCase().includes(searchfield?.toLowerCase())
    const mobile =user?.mobile?.includes(searchfield?.toLowerCase())
    
    return name || mobile;
  });
  
  console.log(users);

  return (
    <div className="row">
      <div className="col-md-12">
        <main>
         
        <div className="col-md-8">
         
                    <div className="search-bar">
        <input type="search" placeholder="Search" 
        onChange={(e)=>setSearchField(e.target.value)}
        />
    </div>
                </div>

                {
      searchfield?(
        <MessageTable handleUser={handleUser} users={filteredUsers} heading={'Users'}/>
      ):
      <MessageTable handleUser={handleUser} users={users} heading={'Users'}/>
    }
{/* <MessageTable handleUser={handleUser} users={users} /> */}


        </main>
      </div>
    </div>
  );
};

export default Messages;
