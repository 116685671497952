import React, { useState } from "react";
import { IoMdClose } from "react-icons/io";
import "./Modal.css"
import '../../../assets/sass/setting.scss'
import { useNavigate } from "react-router";

const Security = ({ open, close }) => {
  const [isOtpActive, setIsOtpActive] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);
  return (
    <>
      <div className={open ? "profile-modal open" : "profile-modal"}>
        <div className="modal-header">
          <h2>Security</h2>
          <div className="modal-close" onClick={() => close(false)}>
            <IoMdClose />
          </div>
        </div>

        <div>
          <span
            data-testid="security-drawer-lock"
            data-icon="security-drawer-lock"
            className="Security-logo"
          >
            <svg
              viewBox="0 0 84 84"
              width="84"
              preserveAspectRatio="xMidYMid meet"
              class=""
              fill="none"
            >
              <circle cx="42" cy="42" r="42" fill="#07CD9E"></circle>
              <path
                d="M53.2411 33.432C53.2411 31.3535 52.7335 29.4441 51.7185 27.7039C50.7193 25.9476 49.3553 24.5619 47.6265 23.5468C45.9136 22.5156 44.042 22 42.0119 22C39.9659 22 38.0785 22.5156 36.3497 23.5468C34.6368 24.5619 33.2728 25.9476 32.2577 27.7039C31.2585 29.4441 30.7589 31.3535 30.7589 33.432V37.8066C30.6638 37.8389 30.4734 37.9275 30.1879 38.0725C29.157 38.6365 28.3719 39.434 27.8327 40.4653C27.5313 41.0292 27.3172 41.6495 27.1903 42.3263C27.0634 43.003 27 44.0101 27 45.3474V53.8792C27 55.2165 27.0634 56.2236 27.1903 56.9003C27.3172 57.577 27.5313 58.1974 27.8327 58.7613C28.3719 59.7925 29.157 60.5901 30.1879 61.1541C30.7272 61.4602 31.3299 61.6777 31.996 61.8066C32.678 61.9356 33.6693 62 34.9699 62H49.0301C50.3307 62 51.314 61.9356 51.9802 61.8066C52.6622 61.6777 53.2728 61.4602 53.8121 61.1541C54.8271 60.6062 55.6122 59.8087 56.1673 58.7613C56.4687 58.1974 56.6828 57.577 56.8097 56.9003C56.9366 56.2236 57 55.2165 57 53.8792V45.3474C57 44.0101 56.9366 43.003 56.8097 42.3263C56.6828 41.6495 56.4687 41.0292 56.1673 40.4653C55.6122 39.4179 54.8271 38.6203 53.8121 38.0725C53.5266 37.9275 53.3362 37.8389 53.2411 37.8066V33.432ZM35.2554 33.432C35.2554 32.1913 35.5567 31.0473 36.1594 30C36.7621 28.9366 37.5789 28.0987 38.6098 27.4864C39.6566 26.8741 40.7906 26.568 42.0119 26.568C43.2332 26.568 44.3592 26.8741 45.3902 27.4864C46.4211 28.0987 47.2379 28.9366 47.8406 30C48.4433 31.0473 48.7446 32.1913 48.7446 33.432V37.2266H35.2554V33.432Z"
                fill="white"
              ></path>
            </svg>
          </span>
        </div>

        <div className="security-content">Your chats  are private</div>
        <div class="security-text">
          End-to-end encryption keeps your personal messages between you and the
          people you choose. Not even SteelConnect can read or listen to them. This
          includes your:
        </div>
        <ul>
          <li class="Security-types">
            <span class="Security-span">
              <span
                data-testid="e2e-message"
                data-icon="e2e-message"
                class="Security-icon"
              >
                <svg
                  viewBox="0 0 24 24"
                  height="25"
                  width="25"
                  preserveAspectRatio="xMidYMid meet"
                  class=""
                  fill="none"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M13.1468 3H5.19245C3.43784 3 2 4.43784 2 6.19245V10.2361C2 11.9907 3.43784 13.4286 5.19245 13.4286H5.91071V16.0357L9.38734 13.4286H13.1468C14.9027 13.4286 16.3393 11.9907 16.3393 10.2361V6.19245C16.3393 4.43784 14.9027 3 13.1468 3Z"
                    stroke="#8696A0"
                    stroke-width="1.2"
                    stroke-linejoin="round"
                  ></path>
                  <path
                    d="M7.21436 15.0581V15.4505C7.21436 17.2051 8.65219 18.6429 10.4068 18.6429H14.1663L17.6429 21.2501V18.6429H18.3612C20.1158 18.6429 21.5536 17.2051 21.5536 15.4505V11.4068C21.5536 9.65219 20.1158 8.21436 18.3612 8.21436H16.3394"
                    stroke="#8696A0"
                    stroke-width="1.2"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </span>
              Text and voice messages
            </span>
          </li>
          
          <li class="Security-types">
            <span class="Security-span">
              <span
                data-testid="e2e-attachment"
                data-icon="e2e-attachment"
                class="Security-icon"
              >
                <svg
                  viewBox="0 0 24 24"
                  height="25"
                  width="25"
                  preserveAspectRatio="xMidYMid meet"
                  class=""
                  fill="none"
                >
                  <path
                    d="M10.0636 6.34105L17.5257 12.6005C18.9998 13.8371 19.4065 16.0166 18.3169 17.602C17.0651 19.4237 14.5629 19.728 12.9125 18.3435L4.195 11.0309C3.27106 10.2559 2.98788 8.88887 3.66532 7.89506C4.44697 6.74836 6.01764 6.55189 7.05443 7.42159L14.4601 13.6338C14.848 13.9592 14.8992 14.5428 14.5738 14.9307C14.2484 15.3186 13.6648 15.3698 13.2768 15.0444L7.10544 9.86756C6.81627 9.62499 6.37531 9.66364 6.13274 9.95281C5.89017 10.242 5.92882 10.6829 6.21799 10.9255L12.2906 16.0195C13.2146 16.7945 14.61 16.8356 15.4709 15.9956C16.4641 15.0264 16.3844 13.4455 15.3476 12.5758L8.06178 6.46422C6.5877 5.2277 4.37064 5.20638 2.99898 6.55508C1.42867 8.09762 1.5584 10.6216 3.20881 12.0061L11.8629 19.2654C13.8871 20.9634 16.9421 21.0028 18.8247 19.1453C20.9827 17.0024 20.8041 13.5482 18.5331 11.6432L10.9511 5.28309C10.6619 5.04052 10.2209 5.07917 9.97836 5.36834C9.73579 5.65752 9.77444 6.09848 10.0636 6.34105Z"
                    fill="#8696A0"
                  ></path>
                </svg>
              </span>
              Photos, videos and documents
            </span>
          </li>
        </ul>
      </div>
    </>
  );
};

export default Security;
