import React, { useEffect, useState } from "react";
import SingleChat from "./SingleChat";
import chats from "./chats";
import { BiGroup } from "react-icons/bi";
import ChatNavbar from "./ChatNavbar";
import { gql, useQuery, useMutation } from "@apollo/client";
import { useContext } from "react";
import { AuthContext } from "../../hooks/AuthContext";
import { type } from "@testing-library/user-event/dist/type";

const MYCHAT_LIST = gql`
  query MyChatList {
    myChatList {
      success
      message
      result {
        id
        code
        is_group
        name
        message
        attachment
        message_date
        type
        is_read
        image
      }
    }
  }
`;

const SEARCH_MUTATION = gql`
  query FindUser($input: FindUserRequest) {
    findUser(input: $input) {
      success
      message
      users {
        id
        first_name
        last_name
        mobile
        email
        image
      }
    }
  }
`;
let auth = localStorage.getItem("token");

let reversedChatList = [];
const AllChats = () => {
  const { count } = useContext(AuthContext);

  // console.log("count==>",count);

  const { userListloading, error, data, refetch } = useQuery(MYCHAT_LIST);
  // console.log("data",data);

  const [chatList, setChatList] = useState([]);
  const [searchfield, setSearchField] = useState("");
  const [isSearch, setIsSearch] = useState(false);
  const [open, setOpen] = useState(false);

  // const [message, setMessage] = useState("");

  const handleCallback = (data) => {
    setSearchField(data);

    if (searchfield?.length > 1) {
      setIsSearch(true);
    } else {
      console.log(searchfield?.length);
      setIsSearch(false);
    }

    // console.log(searchfield);
  };

  useEffect(() => {
    // let  originalArray.reverse();
    let chatArray = [];
    chatArray = data?.myChatList?.result;
  
    let dataArray=chatArray?.slice().reverse()
    setChatList(dataArray);
  }, [data]);

  useEffect(() => {
    // setChatList([...chatList].reverse());

    reversedChatList = chatList?.slice().reverse();

    console.log(reversedChatList);
  }, [chatList]);
  // console.log((reversedChatList));
  //
  const filteredContacts = chatList?.filter((contact) => {
    return contact?.name?.toLowerCase().includes(searchfield?.toLowerCase());
  });

  useEffect(() => {
    refetch();
    refetch();
  }, [auth]);

  useEffect(() => {
    refetch();
  }, [count]);



  // console.log("filteredContacts==>", filteredContacts);
  console.log(chatList);
  console.log(isSearch);
  console.log(reversedChatList);
  // const

  return (
    <div className="conversation-area">
      <ChatNavbar open={open} falseOpen={setOpen} onSearch={handleCallback} />
      

      {isSearch !== false ?
       (
        <div>
          {filteredContacts?.map((chat) => {
            return <SingleChat key={chat.id} {...chat} data={chat} />;
          })}
        </div>
      ) : (
        <div>
        {chatList?.map((chat) => {
          return <SingleChat key={chat.id} {...chat} data={chat} />;
        })}
      </div>
      )}

      <button onClick={() => setOpen(true)} className="add"></button>
      <div className="overlay"></div>
    </div>
  );
};

export default AllChats;
