import React, { useEffect, useState } from 'react'
import '../../assets/sass/admin.scss'
import { Link } from 'react-router-dom'
import useSWR from 'swr'
import { useNavigate } from 'react-router'

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

const apiEndpoint = 'https://app.steelconnect.co.in:3000/api/admin/auth/login';
import usePost from '../../hooks/Api/usePost';


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  // border: '2px solid #000',
  borderRadius:"20px",
  boxShadow: 24,
  p: 4,
};

const login = () => {
  const [isOpen, setIsOpen] = React.useState(false);
  const handleOpen = () => setIsOpen(true);
  const handleClose = () => setIsOpen(false);
  const [errrorMessage,setErrorMessage]=useState(false)

  const navigate = useNavigate(); 

  const [email, setEmail] = useState('');

  const [password, setPassword] = useState('')
  const { makeRequest, data, isLoading, error } = usePost(apiEndpoint);

  


 
  // const { data, error } = useSWR(apiEndpoint, fetcher);
  useEffect(()=>{
console.log("data==>",data);
if(data?.success===true){
// console.log('tokne');
setErrorMessage(false)
localStorage.clear()
  localStorage.setItem('token', data.token);

  navigate('/admin/dashboard')
}

if(errrorMessage===true){
  handleOpen()
}

  },[data])


  const HandleLogin=async (e)=>{
    e.preventDefault();
    const formData={
      username:email,
      password:password
    }
    
    console.log(formData);
    await makeRequest(formData);

    console.log(data);
    if(data?.success!==true){
      // const textMessage = JSON.stringify(data.login.message);
      // console.log(textMessage);
    
      console.log("datalogin");
      setErrorMessage(true)
      
    }
   
  
    console.log('sdf');

  
  }

  return (
    <div className="card admin-login">
            <div className="card-body">
                <Modal
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
          Please enter valid username or password     </Typography>
      
       <Box style={{marginLeft:"90px",marginTop:"20px"}}>
       <Button variant="contained" color="success"  onClick={handleClose}>
        Ok

        </Button>
       </Box>

        </Box>
      </Modal>
              <div className="app-brand justify-content-center">
                <Link to="/admin/dashboard" className="app-brand-link gap-2">
                  <span className="app-brand-text demo text-body fw-bolder">Admin Panel</span>
                </Link>
              </div>
              
              <p className="mb-4">Please sign-in to your account</p>

              <form id="formAuthentication" className="mb-3">
                <div className="mb-3">
                  <label for="email" className="form-label">Email or Username</label>
                  <input type="text" className="form-control" id="email" name="email-username" placeholder="Enter your email or username" autofocus=""
                              onChange={(e) => setEmail(e.target.value)}
value={email}        
                  />
                </div>
                <div className="mb-3 form-password-toggle">
                  <div className="d-flex justify-content-between">
                    <label className="form-label" for="password">Password</label>
                  </div>
                  <div className="input-group input-group-merge">
                    <input type="password" id="password" className="form-control" name="password" placeholder="············" aria-describedby="password"
                    onChange={(e) => setPassword(e.target.value)}
                    value={password}
                    />
                  </div>
                </div>
                <div className="mb-3">
                  <div className="form-check">
                    {/* <input className="form-check-input" type="checkbox" id="remember-me" /> */}
                    {/* <label className="form-check-label" for="remember-me"> Remember Me </label> */}
                  </div>
                </div>
                <div className="mb-3">
                  <button className="btn btn-primary d-grid w-100" type="submit" 
                  onClick={HandleLogin}
                  >Sign in</button>
                </div>
              </form>
            </div>
          </div>
  )
}

export default login
