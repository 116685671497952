import ChatFeed from "./pages/ChatFeed";
import Landing from "./pages/Landing";
import {
  BrowserRouter,
  Route,
  Routes,
  useNavigate,
  Redirect,
  Navigate,
} from "react-router-dom";
import SharedLayout from "./layout/SharedLayout";
import AllUsers from "./pages/admin/AllUsers";
import AddUser from "./pages/admin/CreateUser";
import StaffMembers from "./pages/admin/StaffMembers";
import Messages from "./pages/admin/Messages";
import Dashboard from "./pages/admin/Dashboard";
import AllGroups from "./pages/admin/AllGroups";
import CreateGroup from "./pages/admin/CreateGroup";
import CreateStaff from "./pages/admin/CreateStaff";
import CreateRole from "./pages/admin/CreateRole";
import AllRoles from "./pages/admin/AllRoles";
import Login from "./pages/admin/Login";
import Settings from "./pages/admin/Settings";
// import { AuthContext, AuthProvider } from ".";
import { useContext } from "react";
import PrivateRoutes, { AdminPrivateRoutes } from "./utils/PrivateRoutes";
import EditUser from "./pages/admin/EditUser";
import EditAdmin from "./pages/admin/EditAdmin";
import ChatBox from "./components/chatFeed/ChatBox";
import GroupMessage from "./components/admin/GroupMessage";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  gql,
} from "@apollo/client";
import { useEffect } from "react";
import ErrorPage from "./pages/ErrorPage";
import { useState } from "react";
import { AuthProvider } from "./hooks/AuthContext";

function App() {
  // const {auth} =useContext(AuthContext)
  // const {count,auth}=useContext(AuthContext)
// const navigate=useNavigate()
  // console.log(auth);

  const [user, setUser] = useState(0);
  let token = localStorage.getItem("token");

  console.log(user);

  let client = new ApolloClient({
    uri: "https://app.steelconnect.co.in:3000/graphql",
    headers: {
      Authorization: `Bearer ${token}`,
      // Replace YOUR_TOKEN_HERE with your actual token
    },
    cache: new InMemoryCache(),
  });

  const [isConnected, setIsConnected] = useState(true);

  useEffect(() => {
    // Check for internet connection
    const checkInternetConnection = () => {
      const isOnline = navigator.onLine;
      console.log(isOnline);
      setIsConnected(isOnline);
    };

    checkInternetConnection();

    // Event listener to detect changes in internet connection
    window.addEventListener("online", checkInternetConnection);
    window.addEventListener("offline", checkInternetConnection);

    // Clean up the event listeners
    return () => {
      window.removeEventListener("online", checkInternetConnection);
      window.removeEventListener("offline", checkInternetConnection);
    };
  }, []);


  useEffect(() => {
    if(isConnected===false){
      localStorage.clear() 
     
    }
  }, [isConnected]);


  useEffect(() => {
    token = localStorage.getItem("token");
    token = localStorage.getItem("token");

    console.log("new token", token);
    client = new ApolloClient({
      uri: "https://app.steelconnect.co.in:3000/graphql",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      cache: new InMemoryCache(),
    });
  }, [user]);

  return (
    <ApolloProvider client={client}>
      <AuthProvider>
        <BrowserRouter>
          <Routes>
            <Route exact path="/404" element={<ErrorPage />} />

            {isConnected ? (
              <>
                <Route
                  path="/"
                  index
                  element={<Landing user={user} setUser={setUser} />}
                />

                <Route element={<PrivateRoutes isConnected={isConnected} />}>
                  <Route path="/chats" element={<ChatFeed />} />
                  <Route path="/chats/rooms/:roomId" element={<ChatFeed />} />
                </Route>

                <Route element={<AdminPrivateRoutes />}>
                  <Route path="/admin" element={<SharedLayout />}>
                    <Route index path="dashboard" element={<Dashboard />} />
                    <Route path="all-users" element={<AllUsers />} />
                    <Route path="add-user" element={<AddUser />} />
                    <Route path="edit-user/:id" element={<EditUser />} />
                    <Route path="edit-admin/:id" element={<EditAdmin />} />
                    <Route path="staff-members" element={<StaffMembers />} />
                    <Route path="create-staff" element={<CreateStaff />} />
                    <Route path="messages" element={<Messages />} />
                    <Route path="groups" element={<GroupMessage />} />

                    <Route path="all-groups" element={<AllGroups />} />
                    <Route path="create-group" element={<CreateGroup />} />
                    <Route path="all-roles" element={<AllRoles />} />
                    <Route path="create-role" element={<CreateRole />} />
                    <Route path="settings" element={<Settings />} />
                  </Route>
                </Route>

                <Route path="/superadmin" element={<Login />} />
              </>
            ) : (
              // Redirect to the 404 page if not connected
              <Route path="*" element={<Navigate to="/404" />} />
            )}
          </Routes>
        </BrowserRouter>
      </AuthProvider>
    </ApolloProvider>
  );
}

export default App;
