import React from 'react';
import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.scss';
// import { ApolloClient, InMemoryCache, ApolloProvider, gql } from '@apollo/client';
import App from './App';

import TimeAgo from 'javascript-time-ago'

import en from 'javascript-time-ago/locale/en.json'
import ru from 'javascript-time-ago/locale/ru.json'
import { AuthProvider } from './hooks/AuthContext';

TimeAgo.addDefaultLocale(en)
TimeAgo.addLocale(ru)


let token=localStorage.getItem('token');

// const client = new ApolloClient({
//   uri: 'https://app.steelconnect.co.in:3000/graphql',
//   headers: {
//     Authorization: `Bearer ${token}` // Replace YOUR_TOKEN_HERE with your actual token
//   },
//   cache: new InMemoryCache(),
// });




const root =ReactDOM.createRoot(document.getElementById('root'));
root.render(

  // <AuthProvider>
    <App />
  // {/* </AuthProvider> */}
  

,
);

