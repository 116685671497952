import React, { useEffect, useState } from 'react'
import { IoMdClose } from 'react-icons/io';
import "../../assets/sass/chatInfoMessage.scss";
import { gql, useMutation } from "@apollo/client";
import UserData from './UserData';


const CHAT_INFO_MESSAGE = gql`
mutation RoomChatReceiptInfo($input: RoomChatReceiptRequest) {
    roomChatReceiptInfo(input: $input) {
      data {
        read {
          user {
            id
            first_name
            last_name
            username
            email
            password
            mobile
            email_verified
            mobile_verified
            is_mobile_app_code_set
            image
            created_at
            created_by
            updated_at
            updated_by
            status
          }
          receipt {
            id
            user_id
            chat_id
            created_at
            status
          }
        }
        unread {
          user {
            id
            first_name
            last_name
            username
            email
            password
            mobile
            email_verified
            mobile_verified
            is_mobile_app_code_set
            image
            created_at
            created_by
            updated_at
            updated_by
            status
          }
          receipt {
            id
            user_id
            chat_id
            created_at
            status
          }
        }
      }
    }
  }`


const ChatInfoMessage = ({ open, close, message, chatId }) => {
    // console.log('message: ', message);
    const [chatInfo, { loading, error }] = useMutation(CHAT_INFO_MESSAGE);
    const [isOpen, setIsOpen] = React.useState(false);

    const handleOpen = () => setIsOpen(true);
    const handleModalClose = () => {
        setIsOpen(false);
    };

    const [chatInfoData, setChatInfoData] = useState()


    const handleChatInfo = async () => {
        if (chatId) {
            try {
                const { data } = await chatInfo({
                    variables: { input: { chat_id: chatId } },
                });
                console.log(data?.roomChatReceiptInfo);
                setChatInfoData(data?.roomChatReceiptInfo)
                if (data?.login?.success !== true) {


                }


            } catch (error) {
                console.error(error);
            }
        }
    }


    useEffect(() => {

        console.log('chatId: ', chatId);
        handleChatInfo();
    }, [chatId])

    console.log(chatInfoData);




    return (
        <div className={open ? "info-modal open" : "info-modal"}>

            <div className="modal-header">
                <h2>Chat Info Message</h2>
                <div className="info-close"
                    onClick={close}
                >
                    <IoMdClose />
                </div>

            </div>
            <div className="chat-message">
                <span>

                    {message}
                </span>


                <div className="messageInfo">
                    <div className="read_by">
                    <ul> Read By:   </ul>
                        {
                            chatInfoData?.data?.read.map((user, index) => {
                                return (
                                    <li
                                        key={index}
                                    >
                                        <UserData imgsrc={user?.user.image}
                                            sname={user?.user?.first_name}
                                            aname={user?.user?.last_name}

                                        />
                                    </li>
                                )
                            })
                        }

                    </div>
                    <div className="unread_by">
                        <ul>Delivered to:   </ul>
                        {
                            chatInfoData?.data?.unread.map((user, index) => {
                                return (
                                    <li
                                        key={index}
                                    >
                                        <UserData imgsrc={user?.user.image}
                                            sname={user?.user?.first_name}
                                            aname={user?.user?.last_name}

                                        />
                                    </li>
                                )
                            })
                        }


                    </div>
                </div>

            </div>
        </div>
    )
}

export default ChatInfoMessage
