import { useState, useCallback } from 'react';
import axios from 'axios';

export default function usePost(url) {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);

  const makeRequest = useCallback(async (requestData,token=undefined) => {
    // console.log('tokenns',token);
    setIsLoading(true);
    setError(null);
    const config = {
        headers: {
          "Content-Type": "multipart/form-data",
   
          'Authorization': `Bearer ${token}`,
          "type": "formData"

        }
      };
      
    try {
      const response = await axios.post(url, requestData,config);
      console.log('response: ', response.data);
      setData(response?.data);
    } catch (err) {
      setError(err);
    }
    setIsLoading(false);
    // console.log(data);
  }, [url]);

  return { makeRequest, data, isLoading, error };
}