import React, { useState } from "react";
import classNames from "classnames";
import FormRow from "./FormRow";
import { useEffect } from "react";
import { useContext } from "react";
import { AuthContext, AuthProvider } from "../../hooks/AuthContext";

const getOptionClass = (isSelected) => {
  return isSelected
    ? "checkbox-option checkbox-option--selected"
    : "checkbox-option";
};
const CheckboxOptions = () => {

  const {handlePermission}=useContext(AuthContext)

  const options = [
    { id: 1, label: "DashBoard" },
    { id: 2, label: "Manage User" },
    { id: 3, label: "Manage Messages" },
    { id: 4, label: "Manage Group" },
    { id: 5, label: "Messages Staff" },
    { id: 6, label: "Manage Delete Setting" },
  ];

  const [isCheckboxOpen, setIsCheckboxOpen] = useState(false);

  const [selectedOptions, setSelectedOptions] = useState([]);

  const handleOptionChange = (event, optionId) => {
    const isChecked = event.target.checked;

    if (isChecked) {
      setSelectedOptions([...selectedOptions, optionId]);
    } else {
      setSelectedOptions(selectedOptions.filter((item) => item !== optionId));
    }
  };


  useEffect(()=>{
    handlePermission(selectedOptions)
  },[selectedOptions])

  // console.log(selectedOptions);

  const handleChange = (e) => {
    e.preventDefault();
  };


  

 

  return (
    <div
      style={{ position: "relative" }}
      onClick={() => setIsCheckboxOpen(!isCheckboxOpen)}
    >
      <FormRow
        type="text"
        name="options"
        value={selectedOptions
          .map((optionId) => {
            const option = options.find((item) => item.id === optionId);
            return option ? option.label : "";
          })
          .join(", ")}
        handleChange={handleChange}
        labelText="Permissions"
      />
      <div
        className={
          isCheckboxOpen
            ? "checkbox-options display-block"
            : "checkbox-options display-none"
        }
      >
        {options.map((option) => (
          <label
            key={option.id}
            className={classNames("checkbox-option", {
              "checkbox-option--selected": selectedOptions.includes(option.id),
            })}
          >
            <input
              type="checkbox"
              checked={selectedOptions.includes(option.id)}
              onChange={(event) => handleOptionChange(event, option.id)}
            />
            <span className="checkmark"></span>
            {option.label}
          </label>
        ))}
        <div></div>
      </div>
    </div>
  );
};

export default CheckboxOptions;
