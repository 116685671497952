import axios from 'axios'
import React, { useState } from 'react'
import {AiFillEdit,AiFillDelete} from 'react-icons/ai'
import { useNavigate } from 'react-router'
import useDelete from '../../hooks/Api/useDelete'
import Switch from '@mui/material/Switch';

import useGet from '../../hooks/Api/useGet'
const UserTable = ({heading,users,handleUser}) => {


  console.log("users",users);
  const navigate = useNavigate();
  const [isActive,setIsActive] = useState(false);
  const [isVerified,setIsVerified] = useState(false);


  const deleteUser=async(id)=>{
const     apiEndpoint=`https://app.steelconnect.co.in:3000/api/admin/user/delete/${id}`
   
    let token=localStorage.getItem('token');
    
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    };
 try{   const response=await axios.get(apiEndpoint,config);
    // console.log(response);
    if(response.data.success){
      handleUser()
    }
  }
  catch (err) {
    console.log(err);
  }
  }

  const verifyUser=async(id)=>{
    // console.log(id);
    const     apiEndpoint=`https://app.steelconnect.co.in:3000/api/admin/user/verify/${id}`
   
    let token=localStorage.getItem('token');
    
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    };
 try{   const response=await axios.get(apiEndpoint,config);
    // console.log(response);
    if(response.data.message==="User Verified Successfully"){
      handleUser()
    }
  }
  catch (err) {
    console.log(err);
  }
  }


  const changeStatus=async(id)=>{
    console.log(id);
    const     apiEndpoint=`https://app.steelconnect.co.in:3000/api/admin/user/change-status/${id}`
   
    let token=localStorage.getItem('token');
    
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    };
 try{   const response=await axios.get(apiEndpoint,config);
    // console.log(response);
    if(response.data.message==="User Status Changed Successfully"){
      handleUser()
    }
  }
  catch (err) {
    console.log(err);
  }
  }

  const editUser=(id)=>{
    navigate(`/admin/edit-user/${id}`)
  }


  return (
<div className='all-users' 
style={{
  marginBottom:"80px"
}}
>
    <div className='user-table'>
      <div className="container-fluid">
  <div className="row row--top-40">
    <div className="col-md-12" style={{display:'flex',justifyContent:'space-between'}}>
      <h2 className="row__title">
        <span className='p-1'>
        {users?.length}
        </span>
        {heading} 
      
       </h2>
      <button className="register-btn btn btn-primary" onClick={()=>navigate('../add-user')}>Add User</button>
    </div>
  </div>
  <div 
  style={{
    height: "500px",
    overflow: "scroll",
    
    
  }}
  className="row row--top-20">
    <div className="col-md-12">
      <div className="table-container">
        <table className="table">
          <thead className="table__thead">
            <tr>
              <th className="table__th"><input id="selectAll" type="checkbox" className="table__select-row" /></th>
              <th className="table__th">Name</th>
              <th className="table__th">Mobile Number</th>
              {/* <th className="table__th">Status</th> */}
              <th className="table__th">Verified/Not Verified</th>
              {/* <th className="table__th">User Type</th> */}
              {/* <th className="table__th">Role</th> */}
              <th className="table__th">Action</th>
              <th className="table__th">Mobile App Code</th>
            </tr>
          </thead>
          <tbody className="table__tbody">
           {users?.map((user,id)=>{

            // console.log("status",user.status);
let status=user.status;
            let mobile_verify=user.mobile_verified;
            let email_verify=user.email_verified;

            let verify=mobile_verify&&email_verify;

            // console.log("verifyuser==>",verify);

            return(
              <tr className="table-row table-row--chris">
              <td className="table-row__td">
                <input id="" type="checkbox" className="table__select-row" />
              </td>
              <td className="table-row__td">
                <div className="table-row__img"></div>
                <div className="table-row__info">
                  <p className="table-row__name"> 
                  {user?.first_name} {user?.last_name}
                   </p>
                </div>
              </td>
              <td data-column="Destination" className="table-row__td">
                {user?.mobile}
              </td>
              {/* <td  data-column="Status" className="table-row__td">
             
             
             <Switch 
    color="success"
      checked={status}
      
      onClick={()=>changeStatus(user.id) }
      inputProps={{ 'aria-label': 'controlled' }}
    />
              </td> */}


              <td  data-column="Status" className="table-row__td">

                {/* <p className={`table-row__status status ${verify ? 'status--green' : 'status--red'}`} onClick={()=>verifyUser(user.id)}>{verify?'Verified':'Not Verified'}</p> */}

                <Switch 
    color="success"
      checked={verify}
      onClick={()=>verifyUser(user.id) }
      inputProps={{ 'aria-label': 'controlled' }}
    />   


              </td>
              {/* <td data-column="Progress" className="table-row__td">
                <p className="table-row__progress">User</p>
              </td>
              <td data-column="Progress" className="table-row__td">
                <p className="table-row__progress">User</p>
              </td> */}
              <td className="table-row__td">
                <div className="table-row__edit" 
                
                onClick={()=> editUser(user.id)}
                ><AiFillEdit /></div>
                <div className="table-row__bin"
                onClick={()=> deleteUser(user.id)}
                ><AiFillDelete /></div>                
              </td>
                <td data-column="Destination" className="table-row__td">
                {user?.mobile_app_code}
              </td>
            </tr>
            )
           })}

          </tbody>
        </table>
      </div>
    </div>
  </div>


</div>

    </div>
    </div>
  )
}

export default UserTable
