import React, { useEffect, useState } from "react";
import { BiVideo, BiLike } from "react-icons/bi";
import { AiOutlineSend } from "react-icons/ai";
import { HiOutlinePhotograph } from "react-icons/hi";
import { IoAddCircleOutline } from "react-icons/io5";
import { RiAttachment2 } from "react-icons/ri";
import { VscSmiley } from "react-icons/vsc";
import { BsFillMicFill } from "react-icons/bs";
import { gql, useMutation } from "@apollo/client";
import { useParams } from "react-router";
import usePost from "../../hooks/Api/usePost";
import { useContext } from "react";
import { AuthContext } from "../../hooks/AuthContext";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import VoiceSend from "./VoiceSend";
import Forward from "./Forward/Forward";
import InputEmoji from 'react-input-emoji'

const apiEndpoint = "https://app.steelconnect.co.in:3000/upload";

const SEND_MESSAGE_MUTATION = gql`
  mutation SendChat($input: SendChatRequest) {
    sendChat(input: $input) {
      success
      message
      chat {
        id
        room_id
        room_code
        user_id
      }
      chat_room {
        id
        name
        code
      }
      from_me
    }
  }
`;



const CHAT_DELETE_MUTATION = gql`
  mutation DeleteChat($input: DeleteChatRequest) {
    deleteChat(input: $input) {
      success
      message
    }
  }
`;






const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: "20px",
  boxShadow: 24,
  p: 4,
};

let Ids=[]
let deleteIds 

const ChatAreaFooter = ({
  forward,
  setIsForward,
  setShowCheckboxes,
  selectedMessages,
}) => {
  
  const { roomId } = useParams();
  const { reply,changeCount } = useContext(AuthContext);
  const [chatDelete] = useMutation(CHAT_DELETE_MUTATION);
  // console.log(deleteIds);
  const [isMessageForward,setMessageIsForward]=useState(false)

  const [SendMessage] = useMutation(SEND_MESSAGE_MUTATION);
  const [url, setUrl] = useState(null);
  const [isReply, setIsReply] = useState(false);
  const [emoij, setEmoij] = useState(false);

  const { makeRequest, data, isLoading, error } = usePost(apiEndpoint);
  const [message, setMessage] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);
  const [isOpen, setIsOpen] = React.useState(false);
  const [voiceData,setVoiceData]=useState(null)
  const [isVoice,setIsVoice]=useState(false)
  const handleOpen = () => setIsOpen(true);
  const handleModalClose = () => {
    setIsOpen(false);
    deleteIds=""
    Ids=[]
  };

  // console.log(selectedMessages);
  const [chosenEmoji, setChosenEmoji] = useState(null);

  const onEmojiClick = (event, emojiObject) => {

    setChosenEmoji(emojiObject);
  };

  const hanldeIds=()=>{


      selectedMessages && selectedMessages.map((chat)=>{
        Ids.push(chat?.id)
        return ("")
      })
      // console.log(Ids);
      deleteIds = Ids.join(",");
      // console.log(deleteIds);

    }

 

  const handleDeleteMessage = async () => {
    const { data } = await chatDelete({
      variables: {
        input: {
          code: roomId,
          chat_id:   deleteIds
          ,
        },
      },
    });
    // console.log(data);
    closeForward();
    deleteIds=""
    Ids=[]
    

  };

  useEffect(() => {
    setIsReply(reply?.reply);
  }, [reply]);

  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    setSelectedImage(file);
  };

  const handleClick = () => {
    document.getElementById("imageInput").click();
  };

  const handleImageUpload = async () => {
    if (!selectedImage) return;
    // console.log(selectedFile);
    try {
      let formData = {
        file: selectedImage,
      };
      // console.log(formData);
      let token = localStorage.getItem("token");

      await makeRequest(formData, token);
      // console.log(data?.url);
      setUrl(data?.url);
      if (url !== null) {
        // handleProfilePic()
      }
    } catch (e) {
      console.log(e);
    }
  };

  const sendMedia = async () => {
    if (!url) return;

    try {
      const { data } = await SendMessage({
        variables: {
          input: {
            room_code: roomId,
            message: "a",
            type: "attachment",
            attachment: url,
            is_forwarded: null,
            reply_to: isReply === true ? reply?.id : null,
          },
        },
      });
      // console.log(data.sendChat.success);
      if (data.sendChat.success === true) {
        setMessage("");
        changeCount()

        setUrl(null);
      }
    } catch (error) {
      console.error(error);
    }
    setUrl(null);
    setSelectedImage(null);
  };



  useEffect(() => {
    setUrl(data?.url);
  }, [data]);

  useEffect(() => {
    handleImageUpload();
  }, [selectedImage]);

  useEffect(() => {
    if (url !== null) {
      sendMedia();
    }
  }, [url]);

  useEffect(()=>{
    // console.log(isVoice);
if(voiceData!==null && isVoice===true){
  // console.log(voiceData);
}
  },[isVoice])

  const handleMessage = async () => {
    try {
      const { data } = await SendMessage({
        variables: {
          input: {
            room_code: roomId,
            message: message,
            type: "text",
            attachment: "a",
            is_forwarded: null,
            reply_to: isReply === true ? reply?.id : null,
          },
        },
      });
      // console.log(data.sendChat.success);
      if (data.sendChat.success === true) {
        setMessage("");
        changeCount()

      }
    } catch (error) {
      console.error(error);
    }
    setIsReply()
    changeCount()
  };

  useEffect(()=>{

    // setMessage((prevText) => prevText + chosenEmoji?.emoji);


  },[chosenEmoji])

  console.log(message);

  const handleKeyPress = async (event) => {
    console.log("enter");
    if (event.key === "Enter") {
      handleMessage();
    }
  };

  const closeForward=()=>{
    setIsForward(false);
    setShowCheckboxes(false);
    handleModalClose(false)
    Ids=[]

  }

  return (
    <div className="chat-area-input">
      <Forward listMessages={selectedMessages} isForward={isMessageForward} closeForward={closeForward}
    close={setMessageIsForward}


    />
       <Modal
        open={isOpen}
        onClose={handleModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>

          <Typography id="modal-moda l-title" variant="h6" component="h2">
            Delete Message
          </Typography>

          <Box style={{ marginLeft: "90px", marginTop: "20px" }}>
            <Button
              variant="contained"
              color="success"
              onClick={handleModalClose}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="success"
              onClick={handleDeleteMessage}
            >
              Ok
            </Button>
          </Box>
        </Box>
      </Modal>
      {forward === true ? (
        <div className="close-button" style={{ transform: "translateY(0%)" }}>
          <button
            className="cross-button"
            tabindex="0"
            data-tab="10"
            title="Cancel forward"
            type="button"
            onClick={closeForward}
          >
            <span data-testid="x" data-icon="x" class="">
              <svg
                viewBox="0 0 24 24"
                height="24"
                width="24"
                preserveAspectRatio="xMidYMid meet"
                class=""
                fill="currentColor"
                enable-background="new 0 0 24 24"
              >
                <path d="M19.6004 17.2L14.3004 11.9L19.6004 6.60005L17.8004 4.80005L12.5004 10.2L7.20039 4.90005L5.40039 6.60005L10.7004 11.9L5.40039 17.2L7.20039 19L12.5004 13.7L17.8004 19L19.6004 17.2Z"></path>
              </svg>
            </span>
          </button>
          <span class="selected-content">
            {selectedMessages?.length}
            selected
          </span>

          <button
            class="cross-button"
            tabindex="0"
            data-tab="10"
            title="Delete"
            type="button"
            onClick={()=>{
              handleOpen()
              hanldeIds()
            }}
          >
            <span data-testid="delete" data-icon="delete" class="">
              <svg
                viewBox="0 0 24 24"
                height="24"
                width="24"
                preserveAspectRatio="xMidYMid meet"
                class=""
                version="1.1"
                x="0px"
                y="0px"
                enable-background="new 0 0 24 24"
              >
                <path
                  fill="currentColor"
                  d="M6,18c0,1.1,0.9,2,2,2h8c1.1,0,2-0.9,2-2V6H6V18z M19,3h-3.5l-1-1h-5l-1,1H5v2h14V3z"
                ></path>
              </svg>
            </span>
          </button>
          <button
          onClick={()=>setMessageIsForward(true)}
            class="cross-button"
            tabindex="0"
            data-tab="10"
            title="Forward"
            type="button"
          >
            <span data-testid="forward" data-icon="forward" class="">
              <svg
                viewBox="0 0 24 24"
                height="24"
                width="24"
                preserveAspectRatio="xMidYMid meet"
                class=""
                version="1.1"
                x="0px"
                y="0px"
                enable-background="new 0 0 24 24"
              >
                <path
                  fill="currentColor"
                  d="M14.278,4.813c0-0.723,0.873-1.085,1.383-0.574l6.045,6.051 c0.317,0.317,0.317,0.829,0,1.146l-6.045,6.051c-0.51,0.51-1.383,0.149-1.383-0.574v-2.732c-5.096,0-8.829,1.455-11.604,4.611 c-0.246,0.279-0.702,0.042-0.602-0.316C3.502,13.303,6.997,8.472,14.278,7.431V4.813z"
                ></path>
              </svg>
            </span>
          </button>
        </div>
      ) : (
        <div>
          {isReply === true ? (
            <div className="chatfooter">
              <span className="chatname">
                {reply?.user?.first_name} &nbsp;
                {reply?.user?.last_name}
              </span>
              <br />

{reply?.type==="text"?
reply?.message:
<img src={reply.attachment} alt="" className="reply_image" />
}
              


              <div class="modalclose" onClick={() => setIsReply(false)}>
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  stroke-width="0"
                  viewBox="0 0 512 512"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M405 136.798L375.202 107 256 226.202 136.798 107 107 136.798 226.202 256 107 375.202 136.798 405 256 285.798 375.202 405 405 375.202 285.798 256z"></path>
                </svg>
              </div>
            </div>
          ) : (
            ""
          )}

          <div className="chat-area-footer">
            {/* <div className="chat-footer-icon">
              <BiVideo onClick={handleClick} style={{ cursor: "pointer" }} />
            </div> */}
            <div className="chat-footer-icon">
              <HiOutlinePhotograph
                onClick={handleClick}
                style={{ cursor: "pointer" }}
              />
              <input
                id="imageInput"
                type="file"
                // accept="image/*"
                onChange={handleFileSelect}
                style={{ display: "none" }}
              />
            </div>
            {/* <div className='chat-footer-icon'>
            <IoAddCircleOutline />
        </div>             */}
            <div className="chat-footer-icon">
              <RiAttachment2
                onClick={handleClick}
                style={{ cursor: "pointer" }}
              />
            </div>
            {/* <input
              type="text"
              placeholder="Type something here..."
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              onKeyPress={handleKeyPress}
            />
         
            */}
            <InputEmoji
           value={message}
           onChange={setMessage}
           onKeyDown={handleKeyPress}
          placeholder="Type a message"
        />
   <div onClick={handleMessage} className="chat-footer-icon">
              <AiOutlineSend />
            </div> 
            <div className="chat-footer-icon">
              {/* <BsFillMicFill /> */}
              <VoiceSend 
              setIsVoice={setIsVoice}
              setVoiceData={setVoiceData}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ChatAreaFooter;
