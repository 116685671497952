import React from 'react'
import {FiSearch} from 'react-icons/fi'

const SearchBox = ({onSearch}) => {

  const handleInput=(e)=>{
    console.log(e.target.value);
    onSearch(e.target.value)
  }

  return (
    <div className="search-bar">
        <input type="search" placeholder="Search" 
        onChange={(e)=>handleInput(e)}
        />
    </div>
  )
}

export default SearchBox
