import React from 'react'
import { AuthContext } from "../../hooks/AuthContext";
import { useContext } from "react";
import MessageTable from "../../components/admin/MessageTable";
import { useState } from 'react';

const GroupMessage = () => {
    const {groupData}=useContext(AuthContext)
    console.log(groupData);
    const [updateUser, setUpdteUser] = useState(0);

  const handleUser = () => {
    setUpdteUser(updateUser + 1);
  };

  return (
    <div>
          <MessageTable group={true} handleUser={handleUser} users={groupData} />

    </div>
  )
}

export default GroupMessage