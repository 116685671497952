import React, { useEffect, useState } from 'react'
import StaffTable from '../../components/admin/StaffTable'
import '../../assets/sass/userTable.scss';
const apiEndpoint='https://app.steelconnect.co.in:3000/api/admin/admin-user'
import useGet from '../../hooks/Api/useGet'
let staff
const CreateStaff = () => {
  const [updateStaff,setUpdteStaff]=useState(0);
  const [searchfield, setSearchField] = useState("");

  const handleStaff=()=>{
    setUpdteStaff(updateStaff+1);
  }
  // const [users,setUsers]=useState()
  const { makeRequest, data, isLoading, error } = useGet(apiEndpoint);

  useEffect(()=>{
    let token=localStorage.getItem('token');
    // console.log(token);
    const getData=async()=>{
      await makeRequest(token)
      // users=data?.users
    }
getData()
staff=data?.users

},[updateStaff])

useEffect(()=>{
console.log('dataUpadated',data);
staff=data?.users

},[data])

const filteredStaff = staff?.filter((user) => {

  const name =user?.first_name?.toLowerCase().includes(searchfield?.toLowerCase())
  const mobile =user?.phone?.includes(searchfield?.toLowerCase())
  
  return name || mobile;
});



staff=data?.users


  console.log(data);
  console.log(staff);
  return (
<div className='row'>
      <div className='col-md-12'>
      <main>
        <div class="page-heading mt-5">
            <h1>Manage Staff</h1>
        </div>
        <div className="col-md-8">
         
                    <div className="search-bar">
        <input type="search" placeholder="Search" 
        onChange={(e)=>setSearchField(e.target.value)}
        />
    </div>
                </div>
       
        {
      searchfield?(
        <StaffTable handleStaff={handleStaff} staff={filteredStaff} heading={'Staff Members'}/>
      ):
      <StaffTable handleStaff={handleStaff} staff={staff} heading={'Staff Members'}/>
    }
        </main>
      </div>
    </div>
  )
}

export default CreateStaff
