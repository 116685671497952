import React from 'react';
// import './WordDocumentViewer.css'; // Import your CSS file
import './Excel.css'
const WordView = ({documentUrl}) => {
//   const documentUrl = "https://app.steelconnect.co.in:3000/public/undefined-1691829794176.doc";
  
console.log(documentUrl);

  return (
    <div className="document-viewer">
      <iframe
        src={`https://docs.google.com/gview?url=${encodeURIComponent(documentUrl)}&embedded=true`}
        title="Word Document Viewer"
        width="100%"
        height="500px"
      />
    </div>
  );
};

export default WordView;
