import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Stack from '@mui/material/Stack';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { useNavigate } from 'react-router';
import Form from '../landing/Form';
import { AuthContext } from '../../hooks/AuthContext';
import { useContext } from 'react';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
//   border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  borderRadius:"50px",
};


const NEW_CHAT_MUTATION = gql`
mutation NewChat($input: NewChatRequest) {
    newChat(input: $input) {
      success
      message
      chat_room {
        id
        code
      }
    }
  }
`


const SerachChat = ({last_name,first_name,user,handleCloseSearch}) => {
     
  const {changeCount} =useContext(AuthContext)

    const [sendMessage,{loading,data}]=useMutation(NEW_CHAT_MUTATION)
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setErrorMessage(false)
        setOpen(false)
       
    };
    const [message, setMessage] = useState("");
    const [errorMessage,setErrorMessage]=useState(false)
const navigate=useNavigate()
const [validate, setValidate] = useState({});
const validateRegister = () => {
  let isValid = true;

  let validator = Form.validator({
    message: {
      value: message,
      isRequired: true,
    },
  });

  if (validator !== null) {
    setValidate({
      validate: validator.errors,
    });

    isValid = false;
  }
  return isValid;
};
    const handleChange = (e) => {
      setMessage(e.target.value);
    };



    const handleFistMessage=()=>{
        
        console.log(user.id);
        
        const validate = validateRegister();
        // console.log(validate);
        if(validate)
{
        sendMessage({variables:{input:{
            user_id: user?.id,
            message: message
        }}})
        console.log(data?.newChat?.success);
       
        setErrorMessage(false)
    }
    setErrorMessage(false)




      }

      

      useEffect(() => {
        if(data?.newChat?.success===true){
          changeCount()
            navigate(`/chats`)
            handleClose()
            handleCloseSearch()
            
        }
        if(data?.newChat?.success===false) {
// setErrorMessage(data?.newChat?.message)
setErrorMessage(true)


        }
      }, [data]);
    
  return (
    <div>
         <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>

{errorMessage===true?
<div>
<Typography id="modal-modal-title" variant="h6" component="h2">
   Your have already with  chat that Person
           </Typography>
      
       <Box style={{marginLeft:"90px",marginTop:"20px"}}>
       <Button variant="contained" color="success"  onClick={handleClose}>
        Ok

        </Button>
       </Box>

</div>:
         <>
         <Typography id="modal-modal-title" variant="h6" component="h2">


          <input
        type="text"
        id="message"
        placeholder="Type your first message here"
        value={message}
        name="message"
        onChange={handleChange}
        style={{
          width: 312,
      height: 30,
      border: "1px solid #ccc",
      padding: 18,
      marginBottom: 10
        }}

        className={`form-control ${
            validate.validate && validate.validate.message
              ? "is-invalid "
              : ""
          }`}
        />
        <div
          className={`invalid-feedback text-start ${
            validate.validate && validate.validate.message
              ? "d-block"
              : "d-none"
          }`}
        >
          {validate.validate && validate.validate.message
            ? validate.validate.message[0]
            : ""}
        </div>
          </Typography>
         <Typography   onClick={handleFistMessage}
          style={{ marginTop: 30, marginLeft: 113 }}
         >
         <Button variant="contained" color="success">
        Send
      </Button>
         </Typography>
         </>}

         
        </Box>
      </Modal>


         <div  className="msg online"  onClick={handleOpen}>
        <img className="msg-profile" src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQhLja_b1nZD7bzgvwtJ0JlhlQaKR3ModNySg&usqp=CAU" alt="name "/>
        <div className="msg-detail">
            <div className="msg-username">{first_name} {last_name} </div>
            <div className="msg-content">
                {/* <span className="msg-message">{message}</span> */}
                <span className="msg-date">
                {/* <TimeAgo date={parseInt(message_date)} 
      locale="en-us"
      /> */}
                </span>
            </div>
        </div>
    </div>
    </div>
  )
}

export default SerachChat