import React, { useState, useEffect } from "react";
import { Button, Col, Row } from "react-bootstrap";
// import Form from "react-bootstrap/Form";
import FormRow from "../../components/admin/FormRow";
import FormRowSelect from "../../components/admin/FormRowSelect";
import MultipleSelect from "../../components/admin/MultipleSelect";
import { useNavigate } from "react-router";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Box from "@mui/material/Box";
// import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";

import usePost from "../../hooks/Api/usePost";
import { useContext } from "react";
import { AuthContext, AuthProvider } from "../../hooks/AuthContext";
import axios from "axios";
const apiEndpoint =
  "https://app.steelconnect.co.in:3000/api/admin/admin-user/create";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  // border: '2px solid #000',
  borderRadius: "20px",
  boxShadow: 24,
  p: 4,
};
let message = "Invalid Email or Moblie";

const CreateStaff = () => {
  const { permision } = useContext(AuthContext);

  console.log(permision);

  let permisionData 
  
 if(permision?.length>0){
  permisionData= permision.join();
  console.log(permisionData);
 }

  const navigate = useNavigate();

  const { makeRequest, data, isLoading, error } = usePost(apiEndpoint);
  // const { sendPermsionRequest:makeRequest,  } = usePost(apiEndpoint);

  const userOptions = [
    "Manage Role",
    "Manage User",
    "Manage Staff",
    "Manage Group",
    "Messages Count",
    "Manage Delete Setting",
  ];

  const [role, setRole] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [validate, setValidate] = useState({});

  const [isOpen, setIsOpen] = React.useState(false);
  const handleOpen = () => setIsOpen(true);
  const handleClose = () => setIsOpen(false);
  const [errrorMessage, setErrorMessage] = useState("");

  const schema = yup.object().shape({
    firstName: yup.string().required("The firstName is required!"),
    lastName: yup.string().required("The lastName is required!"),
    email: yup.string().email().required("The Email is required!"),
    mobileNumber: yup
      .number()
      .positive()
      .min(10)
      .required("Mobile Number must have at least 10 number"),
    password: yup.string().min(6).max(25).required("The Password is required!"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      // firstName: 'John',
    },
  });
  const [transfer, setTransfer] = useState(false);

  const [sendPermsion, setPermsion] = useState(false);

  const handlePermission = async (id) => {
    const apiEndpoint = `https://app.steelconnect.co.in:3000/apiapi/admin/admin-user/change-permissions/4/${id}`;

    let token = localStorage.getItem("token");

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      const response = await axios.post(apiEndpoint, config, permisionData);
      console.log(response);
      if (response.data.success) {
      }
    } catch (err) {
      console.log(err);
    }
  };

  const onSubmit = async (data) => {
    // console.log("data",data);
    const fetch = data;

    // console.log(fetch)

    // const arrayToString = array.join();

    const formData = {
      first_name: data.firstName,
      last_name: data.lastName,
      username: data.email,
      email: data.email,
      mobile: data.mobileNumber,
      password: data.password,
      permission:permisionData
    };

    let token = localStorage.getItem("token");

    await makeRequest(formData, token);
  };

  useEffect(() => {
    if (data?.success === true) {
    }
  }, [data]);

  const validation = (props) => {
    if (props.fetch) {
      setTransfer(transfer === true);
    } else {
      setTransfer(transfer === false);
    }
  };

  useEffect(() => {
    // console.log("datat===>", data);
    if (data?.success === false) {
      setErrorMessage(true);
      message = data?.message;
      // console.log(message);
      setPermsion(false);
      handleOpen();
    }

    if (data?.success === true) {
      navigate("/admin/staff-members");
    }
  }, [data]);

  return (
    <div className="row">
      <div className="col-md-12">
        <main>
          <div class="page-heading mt-5">
            <h1>Create Staff</h1>
          </div>
          <div className="add-job-section">
            <Modal
              open={isOpen}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  {message}
                </Typography>

                <Box style={{ marginLeft: "90px", marginTop: "20px" }}>
                  <Button
                    variant="contained"
                    color="success"
                    onClick={handleClose}
                  >
                    Ok
                  </Button>
                </Box>
              </Box>
            </Modal>
            <form
              className="create-user-form"
              onSubmit={handleSubmit(onSubmit)}
            >
              <span className="d-block">
                <label className="create-user-form-labelBox">First Name</label>
                <input
                  className="create-user-form-inputBox"
                  style={{ borderColor: transfer ? "red" : "lightblue" }}
                  type="text"
                  {...register("firstName", { defaultValue: "John" })} // Add defaultValue prop
                />
                <p className="errorMsg">{errors.firstName?.message}</p>
              </span>

              <span className="d-block">
                <label className="create-user-form-labelBox">Last Name</label>
                <input
                  className="create-user-form-inputBox"
                  style={{ borderColor: transfer ? "red" : "lightblue" }}
                  type="text"
                  {...register("lastName")}
                />
                <p className="errorMsg">{errors.lastName?.message}</p>
              </span>

              <div className="d-block">
                <label className="create-user-form-labelBox">Email</label>
                <input
                  className="create-user-form-inputBox"
                  style={{ borderColor: transfer ? "red" : "lightblue" }}
                  type="text"
                  {...register("email")}
                />
                <p className="errorMsg">{errors.email?.message}</p>
              </div>

              <span className="d-block">
                <label className="create-user-form-labelBox">
                  Mobile Number
                </label>
                <input
                  className="create-user-form-inputBox"
                  style={{ borderColor: transfer ? "red" : "lightblue" }}
                  // value={mobile}
                  // handleChange={handleMobile}
                  type="number"
                  {...register("mobileNumber", { minLength: 10 })}
                />
                <p className="errorMsg">{errors.mobileNumber?.message}</p>
              </span>

              <span className="d-block">
                <label className="create-user-form-labelBox">Password</label>
                <input
                  className="create-user-form-inputBox"
                  style={{ borderColor: transfer ? "red" : "lightblue" }}
                  type="password"
                  {...register("password")}
                />
                <p className="errorMsg">{errors.password?.message}</p>
              </span>

              <span className="d-block">
                <MultipleSelect send={sendPermsion} />
              </span>

              <button className="btnForm" onClick={validation}>
                Submit
              </button>
            </form>
          </div>
        </main>
      </div>
    </div>
  );
};

export default CreateStaff;
