import React, { useState } from 'react'
import {IoMdClose} from 'react-icons/io'
import "./Modal.css"
import '../../../assets/sass/setting.scss'
import { useNavigate } from 'react-router'
import Profile from './Profile'
import Security from './Security'
import { gql, useMutation, useQuery } from "@apollo/client";

const PROFILE_QUERY = gql`
  query Profile {
    profile {
      success
      message
      user {
        first_name
        last_name
        image
        email
        mobile
        id
      }
    }
  }
`;

const Setting = ({open,close}) => {

    const { data: userData, refetch } = useQuery(PROFILE_QUERY);


// console.log(firstName);

    const [isProfile,setIsProfile] = useState(false);

    
        const [isSecurity,setIsSecurity] = useState(false);

    const navigate = useNavigate();
    const [isOtpActive,setIsOtpActive] = useState(false);
   
        const [isModalOpen,setIsModalOpen] = useState(false);
        let user = userData?.profile?.user;
console.log(user);
        const logout = () => {
            // localStorage.removeItem('token');
            localStorage.clear();
        
            navigate("/");
          };

  return (<>
  <Profile  open={isProfile} close={setIsProfile} />
  <Security  open={isSecurity} close={setIsSecurity} />

    <div className={open ? 'setting-modal open' : 'setting-modal'}>
    <div className='setting-header'>

        <h2 >Settings</h2>
        <div className='setting-close' onClick={()=>close(false)}>
            <IoMdClose />
        </div>
    </div>


        <div>
            <div 
            onClick={()=>setIsProfile(true)}
            className="user-profile-section">
                <div className='user-image'>
             <img className="user-dp" src={user?.image} />
             </div>
             <div className="user-data">
             <span className="user-name" >
                {user?.first_name}
             </span>
             <span className="about-user">
                {user?.last_name}
             </span>
             </div>
            </div>


            <div className="security" onClick={()=>setIsSecurity(true)}>
            
                <div className="security-icon">
                <span data-testid="settings-security" data-icon="settings-security" class="">
                    <svg viewBox="0 0 24 24" height="24" width="24" preserveAspectRatio="xMidYMid meet" class="" version="1.1">
                        <path d="M12.027027,2 L4,5.56756757 L4,10.9189189 C4,15.8689189 7.42486486,20.4978378 12.027027,21.6216216 C16.6291892,20.4978378 20.0540541,15.8689189 20.0540541,10.9189189 L20.0540541,5.56756757 L12.027027,2 Z M12.027027,11.8018919 L18.2702703,11.8018919 C17.7975676,15.4764865 15.3448649,18.7497297 12.027027,19.7754054 L12.027027,11.8108108 L5.78378378,11.8108108 L5.78378378,6.72702703 L12.027027,3.95324324 L12.027027,11.8018919 Z" fill="currentColor" fill-rule="nonzero">
                            </path>
                            </svg>
                            </span>
                            </div>
                            <div className="border-top">
                                <div className="security-txt">
                                    <span className="sec-text">Security</span>
                                    </div>
                                    </div>
                                        </div>



            <div className="security">
                <div className="security-icon">
                <span data-testid="settings-security" data-icon="settings-security" class="">
                   <img className="logout-img" src="https://seekicon.com/free-icon-download/logout_4.svg"></img>
                            </span>
                            </div>
                            <div className="border-top">
                                <div className="security-txt">
                                    <span 
                                    onClick={logout}
                                    className="sec-text">Logout</span>
                                    </div>
                                    </div>
                                        </div>
        </div>
   
    </div>
    </>
  )
}

export default Setting
