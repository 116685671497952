import { gql, useMutation } from '@apollo/client';
import { useEffect } from 'react';
import { useState } from 'react';
import { useContext } from 'react';
import { Outlet, Navigate, useNavigate } from 'react-router-dom'
import { AuthContext } from '../hooks/AuthContext';

const VERIFY_MUTATION = gql`
mutation($input: AuthTokenRequest) {
    verifyToken(input: $input) {
      success
      message
    }
  }
`;

let tokenData
// let isVerfiyToken=false;
let verifyTokenAUth=false

const PrivateRoutes = ({isConnected}) => {
    let token=localStorage.getItem('token');
    const navigate=useNavigate()

    const { auth } = useContext(AuthContext);

    const [isVerfiyToken,setIsVerfiyToken]=useState(false)

    const [verify]=useMutation(VERIFY_MUTATION)
const [tokenData,setTokenData]=useState(null)

useEffect(() => {
  if(isConnected===false){
    // console.log('logout');
    // localStorage.clear() 
    // navigate('/')
  }
}, [isConnected]);

//     useEffect(()=>{
//         const verifyToken=async()=>{
//             const { data } = await verify({
//                 variables: { input: { token:auth } },
//               });
//               console.log(data);
//               setTokenData(data)
//               console.log(tokenData);
//         }

//         auth &&verifyToken()
      
  
//     },[auth])
  

//     useEffect(()=>{
//         if(tokenData!==null)
// console.log(tokenData);
//     },[tokenData])

// let auth=false
// let token=false
console.log(auth);
  
    return(

        <div>

      { auth ||token ? <Outlet/> : <Navigate to="/"/>
      
      }
        </div>
    )
}


const  AdminPrivateRoutes = () => {
    // const { auth } = useContext(AuthContext);
let token=localStorage.getItem('token');
const { auth } = useContext(AuthContext);


    // console.log(auth);

    // let auth = {'token':false}
    return(
       auth ||token ? <Outlet/> : <Navigate to="/superadmin"/>
    )
}

export {AdminPrivateRoutes}


export default PrivateRoutes