import React, { useState } from 'react';
import MediaFile from "./MediaFile"

import DocsFile from './DocsFile';

import './Media.css'; // Create a CSS file for styling
import LinksFile from './LinksFile';

const Tabs = ({chatMedia}) => {
  const [activeTab, setActiveTab] = useState(1);

  const handleTabClick = (tabNumber) => {
    setActiveTab(tabNumber);
  };

  return (
    <div className="tabs-container">
      <div className="tabs">
        <div
          className={`tab ${activeTab === 1 ? 'active' : ''}`}
          onClick={() => handleTabClick(1)}
        >
          Media
        </div>
        <div
          className={`tab ${activeTab === 2 ? 'active' : ''}`}
          onClick={() => handleTabClick(2)}
        >
          Docs
        </div>
        {/* <div
          className={`tab ${activeTab === 3 ? 'active' : ''}`}
          onClick={() => handleTabClick(3)}
        >
          Links
        </div> */}
      </div>
      <div className="tab-content">
        {activeTab === 1 && <p> <MediaFile
        chatMedia={chatMedia}
        /> </p>}
        {activeTab === 2 && <p><DocsFile 
                chatMedia={chatMedia}

        /></p>}
        {activeTab === 3 && <p><LinksFile/></p>}
      </div>
    </div>
  );
};

export default Tabs;
