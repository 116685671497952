import React, { useEffect, useState } from "react";
import { GrFormClose } from "react-icons/gr";
import {
  AiFillDashboard,
  AiOutlineUser,
  AiOutlineMail,
  AiOutlineLogout,
} from "react-icons/ai";
import { MdOutlineAnalytics, MdReportGmailerrorred } from "react-icons/md";
import { IoMdSettings } from "react-icons/io";
import { NavLink, useNavigate } from "react-router-dom";
import useGet from "../../hooks/Api/useGet";
const apiEndpoint = "https://app.steelconnect.co.in:3000/api/admin/menus";

const Sidebar = () => {
  const navigate = useNavigate();

  const { makeRequest, data, isLoading, error } = useGet(apiEndpoint);
  const [menus, setMenus] = useState();

  useEffect(() => {
    console.log("sidebar");
    let token = localStorage.getItem("token");
    // console.log(token);
    const getData = async () => {
      await makeRequest(token);

      //   setMenus(data?.menus)
    };

    getData();
  }, []);

  useEffect(() => {
    setMenus(data?.menus);
    console.log(menus);
  }, [data]);

  const logout = () => {
    // localStorage.removeItem('token');
    localStorage.clear();

    navigate("/superadmin");
  };

  return (
    <aside>
      <div className="top">
        <div className="close" id="close-btn">
          <span className="material-symbols-outlined">
            <GrFormClose />
          </span>
        </div>
      </div>
      <div className="sidebar">
       {menus && menus[0]? <NavLink className="side-nav" to="/admin/dashboard">
          <span className="material-symbols-outlined">
            <AiFillDashboard />
          </span>
          <h3>{menus && menus[0]?.name}</h3>
        </NavLink>
        :''}

      {menus && menus[1]?  <NavLink className="side-nav" to="/admin/all-users">
          <span className="material-symbols-outlined">
            <AiOutlineUser />
          </span>
          <h3>{menus && menus[1]?.name}</h3>
        </NavLink>
        :""}

      { menus && menus[4]? <NavLink className="side-nav" to="/admin/staff-members">
          <span className="material-symbols-outlined">
            <AiOutlineMail />
          </span>
          <h3>{menus && menus[4]?.name}</h3>
        </NavLink>
        :""}
       {menus && menus[3]? <NavLink className="side-nav" to="/admin/all-groups">
          <span className="material-symbols-outlined">
            <MdOutlineAnalytics />
          </span>
          <h3>{menus && menus[3]?.name}</h3>
        </NavLink>
        :""}

      {  menus && menus[2]?<NavLink className="side-nav" to="/admin/messages">
          <span className="material-symbols-outlined">
            <AiOutlineMail />
          </span>
          <h3>{menus && menus[2]?.name}</h3>
        </NavLink>
        :""}

       {menus && menus[5]? <NavLink className="side-nav" to="/admin/settings">
          <span className="material-symbols-outlined">
            <IoMdSettings />
          </span>
          <h3>{menus && menus[5]?.name}</h3>
        </NavLink>
        :""}
        <span onClick={logout} className="side-nav">
          <span className="material-symbols-outlined">
            <AiOutlineLogout />
          </span>
          <h3>Logout</h3>
        </span>
      </div>
    </aside>
  );
};

export default Sidebar;
