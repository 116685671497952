import { useState, useCallback } from 'react';
import axios from 'axios';

export default function useGet(url) {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);

  
  const makeRequest = useCallback(async (token) => {
    const config = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      };
      
    setIsLoading(true);
    setError(null);
    try {
      const response = await axios.get(url,config );
      // console.log(response);
      setData(response.data);
    } catch (err) {
      setError(err);
    }
    setIsLoading(false);
  }, [url]);

  return { makeRequest, data, isLoading, error };
}