import React, { useEffect, useRef, useState } from 'react'
import { useContext } from 'react';
import { MdDone, MdDoneAll } from 'react-icons/md';
import { useNavigate } from 'react-router';
import TimeAgo from 'react-time-ago';
import { AuthContext, AuthProvider } from '../../hooks/AuthContext';

const SingleChat = ({id,dp,name,display_name,message_date,message,code,data , image,is_read}) => {
  // console.log(data);
// console.log(data);
  const chatContainerRef = useRef(null);
const [imageError, setimageError] = useState(false);
  const {handleChatData}=useContext(AuthContext)
  const navigate= useNavigate()
  const date = new Date(message_date);
  const formattedDate = date.toLocaleString('en-US', {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
  });
  
  // const formattedTime = message_date.toLocaleString('en-US', {
  //   hour: 'numeric',
  //   minute: 'numeric',
  //   hour12: true,
  // });
  
  // useEffect(() => {
  //   chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
  // }, [message]);
  
  const navigateChats=()=>{
    // console.log(code);
    console.log(data);
    handleChatData(data)
    navigate(`/chats/rooms/${code}`)

  }

  const handleImageError = () => {
setimageError(true);
setimageError(true);

  };


  return (
    <div onClick={navigateChats} className="msg online">
        <img className="msg-profile" 
        
        src={imageError===true?"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQhLja_b1nZD7bzgvwtJ0JlhlQaKR3ModNySg&usqp=CAU":image}
        
        onError={handleImageError}

        
        alt={name} />
        <div className="msg-detail">
            <div className="msg-username">{name}</div>
            <div className="msg-content">
                  {data?.type==="text"?
                <span className="msg-message">
                  {  is_read===0?  <MdDone  size={18} />:
  <MdDoneAll size={18} />}
                  {message}
                  


                </span>
                  :
                  "Attachment"
                  }
                 
                
                <span className="msg-date">
              {message_date&&  <TimeAgo date={parseInt(message_date)} 
      locale="en-us"
      />}

      {/* {formattedTime} */}
                </span>
            </div>
        </div>
    </div>
  )
}

export default SingleChat
