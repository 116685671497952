import React, { useState } from "react";
import { IoMdClose } from "react-icons/io";
import { AiOutlineArrowRight } from "react-icons/ai";
import "./Groups.css";
import UserData from "./UserData";
import "../../../assets/sass/Groups.scss";
// import Sdata from "./Sdata";
import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { useNavigate, useParams } from "react-router";
import { useEffect } from "react";
import userEvent from "@testing-library/user-event";



const MYCHAT_LIST = gql`
  query MyChatList {
    myChatList {
      success
      message
      result {
        id
        code
        name
        message
        attachment
        message_date
        type
        is_group
        user_id
      }
    }
  }
`;

const ADD_PARTICIPANTS_MUTATION = gql`
  mutation AddChatParticipant($input: AddChatParticipantRequest) {
    addChatParticipant(input: $input) {
      success
      message
    }
  }
`;

const SEARCH_MUTATION = gql`
  query FindUser($input: FindUserRequest) {
    findUser(input: $input) {
      success
      message
      users {
        id
        first_name
        last_name
        mobile
        email
      }
    }
  }
`;


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  // border: '2px solid #000',
  borderRadius:"20px",
  boxShadow: 24,
  p: 4,
};




let selectedContactIds = []; // New global variable to store selected contact IDs
let filteredUsers=[]

const Groups = ({ totalMembers,open, close,handleCount}) => {
  const { roomId } = useParams();
  const [isOpen, setIsOpen] = React.useState(false);
  const handleOpen = () => setIsOpen(true);
  const handleClose = () =>{setIsOpen(false) };

  const [selectedContacts, setSelectedContacts] = useState([]);
  const { userListloading, error, data, refetch } = useQuery(MYCHAT_LIST);

  const [addChatParticipant] = useMutation(ADD_PARTICIPANTS_MUTATION);

  const [getData, { data: searchData }] = useLazyQuery(SEARCH_MUTATION);
  const [totalParticipant, setTotalParticipnat] = useState();
  const [userList, setUserList] = useState();
  const [search, setSearch] = useState();
  const [searchList, setSearchList] = useState();
  const [userId, setUsers] = useState();
  const [errrorMessage,setErrorMessage]=useState('')

  const handleSearch = (e) => {
    let text = e.target.value;
    setSearch(text);
    console.log(text);
    getData({ variables: { input: { query: text } } });
    console.log("data==>", searchData?.findUser?.users);
  };

  useEffect(() => {
    // console.log("data==>", data?.findUser?.users);
    if (searchData?.findUser?.success === true) {
      setSearchList(searchData?.findUser?.users);
      // console.log(searchList);
    }
  }, [searchData]);

  // console.log(user_id);

  useEffect(() => {
    setUserList(data?.myChatList?.result);
  }, [data]);

  // console.log(userList);

  const handleContactClick = (contact) => {
    const isSelected = selectedContacts.includes(contact);
    if (isSelected) {
      setSelectedContacts(selectedContacts.filter((c) => c !== contact));
      selectedContactIds = selectedContactIds.filter((id) => id !== contact.id); // Remove the ID from the new global variable
    } else {
      setSelectedContacts([...selectedContacts, contact]);
      selectedContactIds.push(contact.user_id); // Add the ID to the new global variable
    }
  };
  console.log(selectedContactIds);
  const navigate = useNavigate();
  const [isOtpActive, setIsOtpActive] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleGroupClose = () => {
    close(false);
    setSearchList([]);
    setSearch("");
    setSelectedContacts([]);
    selectedContactIds = [];
  };

  const handleParticipants = async () => {
    const user_id = selectedContactIds.join();
    // console.log(user_id);

    const { data } = await addChatParticipant({
      variables: {
        input: {
          user_id,
          code: roomId,
        },
      },
    
    });
    if(data?.addChatParticipant?.success===true){
      handleGroupClose()
    };
    if(data.addChatParticipant.success!==true){
      // const textMessage = JSON.stringify(data.login.message);
      // console.log(textMessage);
    
      console.log("datalogin");
      setErrorMessage(data?.addChatParticipant?.message)
      handleOpen()
    }
    handleGroupClose()
    handleCount()

    
  }
    const filterMembers = () => {
      let emails = [];
      console.log('totalMembers: ', totalMembers);
      totalMembers?.map((user) => {
        emails.push(user.id);
        return "";
      });
      // console.log(emails);
      console.log('emails: ', emails);
      filteredUsers = userList?.filter((user) => !emails.includes(user.user_id));
filteredUsers=filteredUsers?.filter((user)=>user.is_group!==1)
      console.log(filteredUsers);

    };



    // console.log(data.login);
  
  useEffect(()=>{
    filterMembers()
    filterMembers()
  // console.log(filteredUsers);

  },[userList])
  console.log(totalMembers);
  console.log(userList);
  // console.log(filteredUsers);
  console.log('filteredUsers: ', filteredUsers);


  return (
    <>

<Modal
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
       {errrorMessage}      </Typography>
      
       <Box style={{marginLeft:"90px",marginTop:"20px"}}>
       <Button variant="contained" color="success"  onClick={handleClose}>
        Ok

        </Button>
       </Box>

        </Box>
      </Modal>
      <div className={open ? "group-modal open" : "group-modal"}>
        <div className="modal-header">
          <h2>Add Group Participants</h2>
          <div className="modal-close" onClick={handleGroupClose}>
            <IoMdClose />
          </div>
        </div>




        <div onClick={handleParticipants} className="selectbutton">
          <AiOutlineArrowRight />
        </div>
        <div>
          <h3>Selected Contacts:</h3>
          {
            <div>
              {selectedContacts?.length > 0 ? (
                <ul  
                >
                  {selectedContacts.map((contact) => (
                    <>
                      <div key={contact.id} className="user-profile-section2">
                        <div className="user-image2">
                          <img
                            className="user-dp-2"
                            src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
                          />
                        </div>
                        <div className="user-data2">
                          <span className="user-name2">
                            {contact.name} 
                            {/* {contact?.first_name} */}
                          </span>
                        </div>
                        <div
                          className="crossicon"
                          onClick={() => handleContactClick(contact)}
                        >
                          <IoMdClose />
                        </div>
                      </div>
                    </>
                  ))}
                </ul>
              ) : (
                <p>No contacts selected.</p>
              )}
            </div>
          }

          <div className="inputfeild">
            <input
              placeholder="Type contact name"
              value={search}
              onChange={handleSearch}
            />
          </div>

          {searchList?.length > 0 ? (
            searchList?.map((contact) => (
              <li
                key={contact.id}
                onClick={() => handleContactClick(contact)}
                style={{
                  display: selectedContacts.includes(contact) ? "none" : "",
                }}
              >
                <UserData name={contact.first_name} />
              </li>
            ))
          ) : (
            <ul  style={{
                  
              overflowY: "scroll",
              height: "500px",
          
                          }}>
              {filteredUsers?.map((contact) => (
                <li
                  key={contact.id}
                  onClick={() => handleContactClick(contact)}
                  style={{
                    display: selectedContacts.includes(contact) ? "none" : "",
                  }}
                >
                  <UserData name={contact.name} />
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    </>
  );
};
export default Groups;
