import React from 'react'

function ForwardData({sname,image,aname}) {
    // console.log(sname,image);
  return (
    

  
    <div>
       <div className="border1">
  <div className="user-profile-section-forward">
                <div className='user-image'>
             <img className="user-dp-1" 
         src={image===null?"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQhLja_b1nZD7bzgvwtJ0JlhlQaKR3ModNySg&usqp=CAU":image}
             
             />
             </div>
             <div className="user-data">
             <span className="user-name-1" >{sname}</span>
             <span className="about-user-1">{aname}</span>
             </div>
            </div>
            </div>
    </div>
  )
}

export default ForwardData;