import React, { useState, useEffect } from "react";
import { Button, Col, Row } from "react-bootstrap";
// import Form from "react-bootstrap/Form";
import FormRow from "../../components/admin/FormRow";
import FormRowSelect from "../../components/admin/FormRowSelect";
import MultipleSelect from "../../components/admin/MultipleSelect";
import { useNavigate, useParams } from "react-router";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Box from "@mui/material/Box";
// import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";

// const apiEndpoint =
//   "https://app.steelconnect.co.in:3000/api/admin/admin-user/create";
import usePost from "../../hooks/Api/usePost";

import axios from "axios";
import { useContext } from "react";
import { AuthContext } from "../../hooks/AuthContext";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  // border: '2px solid #000',
  borderRadius: "20px",
  boxShadow: 24,
  p: 4,
};
let message = "Invalid Email or Moblie";


const EditAdmin = () => {
  const navigate = useNavigate();

  const { id } = useParams();
  const { permision } = useContext(AuthContext);

  let permisionData 
  
  if(permision?.length>0){
   permisionData= permision.join();
   console.log(permisionData);
  }
  // console.log(id);
  
const apiEndpoint = `https://app.steelconnect.co.in:3000/api/admin/admin-user/edit/${id}`;

  const { makeRequest, data, isLoading, error } = usePost(apiEndpoint);
  const userOptions = [
    "Manage Role",
    "Manage User",
    "Manage Staff",
    "Manage Group",
    "Messages Count",
    "Manage Delete Setting",
  ];
 
  const [role, setRole] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [validate, setValidate] = useState({});

  const [isOpen, setIsOpen] = React.useState(false);
  const handleOpen = () => setIsOpen(true);
  const handleClose = () => setIsOpen(false);
  const [errrorMessage, setErrorMessage] = useState("");
  const [sendPermsion, setPermsion] = useState(false);

  const [adminData,setAdminData]=useState()


  


  const getUser=async()=>{
    // console.log(id);
    const     apiEndpoint=`https://app.steelconnect.co.in:3000/api/admin/admin-user/${id}`
   
    let token=localStorage.getItem('token');
    
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    };
 try{   const response=await axios.get(apiEndpoint,config);
    console.log(response.data.users);
    setAdminData(response.data.users)

console.log(adminData);
   
  }
  catch (err) {
    console.log(err);
  }
  }

  
  const schema = yup.object().shape({
    firstName: yup.string().required("The firstName is required!"),
    lastName: yup.string().required("The lastName is required!"),
    email: yup.string().email().required("The Email is required!"),
    mobileNumber: yup
      .number()
      .positive()
      .min(10)
      .required("Mobile Number must have at least 10 number"),
  
  
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      // firstName: 'John',
    },
  });
  const [transfer, setTransfer] = useState(false);

  const onSubmit = async (data) => {
    console.log("data",data);
    const fetch = data;

    // console.log(fetch)

    const formData = {
      first_name: data.firstName,
      last_name: data.lastName,
      username: data.email,
      email: data.email,
      mobile: data.mobileNumber,
      permission:permisionData


    };

    let token = localStorage.getItem("token");

    await makeRequest(formData, token);


  };

  const validation = (props) => {
    if (props.fetch) {
      setTransfer(transfer === true);
    } else {
      setTransfer(transfer === false);
    }
  };


  const handlePermission = async () => {

    const apiEndpoint = `https://app.steelconnect.co.in:3000/api/admin/admin-user/change-permissions/${id}`;

    let token = localStorage.getItem("token");

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      const response = await axios.post(apiEndpoint, config, permisionData);
      console.log(response);
      if (response.data.success) {
      }
    } catch (err) {
      console.log(err);
    }
  };

  

  useEffect(() => {
    if (data?.success) {
      navigate("/admin/staff-members");
    }
  });

 

  useEffect(() => {
    getUser()
    
  },[]);

  useEffect(() => {
    if (data?.success === false) {
      setErrorMessage(true);
      message = data?.message;
      console.log(message);

      handleOpen();
    }
    if (data?.success) {
      // handlePermission()
      navigate("/admin/staff-members");
    }
    
  },[data]);

  useEffect(() => {
    if(adminData){
      
    setValue('firstName', adminData.first_name);
    setValue('lastName', adminData.last_name);
    setValue('email', adminData.email);
    setValue('mobileNumber', adminData.phone)


      
    }
  
  
    },[adminData]);
  




  return (
    <div className="row">
      <div className="col-md-12">
        <main>
          <div class="page-heading mt-5">
            <h1>Edit Staff</h1>
          </div>
          <div className="add-job-section">
            <Modal
              open={isOpen}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  {errrorMessage}{" "}
                </Typography>

                <Box style={{ marginLeft: "90px", marginTop: "20px" }}>
                  <Button
                    variant="contained"
                    color="success"
                    onClick={handleClose}
                  >
                    Ok
                  </Button>
                </Box>
              </Box>
            </Modal>
            <form
              className="create-user-form"
              onSubmit={handleSubmit(onSubmit)}
            >
              <span className="d-block">
                <label className="create-user-form-labelBox">First Name</label>
                <input
                  className="create-user-form-inputBox"
                  style={{ borderColor: transfer ? "red" : "lightblue" }}
                  type="text"
                  {...register('firstName', { defaultValue: 'John' })} // Add defaultValue prop
                  />
                <p className="errorMsg">{errors.firstName?.message}</p>
              </span>

              <span className="d-block">
                <label className="create-user-form-labelBox">Last Name</label>
                <input
                  className="create-user-form-inputBox"
                  style={{ borderColor: transfer ? "red" : "lightblue" }}
                  type="text"
                  {...register("lastName")}
                />
                <p className="errorMsg">{errors.lastName?.message}</p>
              </span>

              <div className="d-block">
                <label className="create-user-form-labelBox">Email</label>
                <input
                  className="create-user-form-inputBox"
                  style={{ borderColor: transfer ? "red" : "lightblue" }}
                  type="text"
                  {...register("email")}
                />
                <p className="errorMsg">{errors.email?.message}</p>
              </div>

              <span className="d-block">
                <label className="create-user-form-labelBox">
                  Mobile Number
                </label>
                <input
                  className="create-user-form-inputBox"
                  style={{ borderColor: transfer ? "red" : "lightblue" }}
                  // value={mobile}
                  // handleChange={handleMobile}
                  type="number"
                  {...register("mobileNumber", { minLength: 10 })}
                />
                <p className="errorMsg">{errors.mobileNumber?.message}</p>
              </span>

         
              <span className="d-block">
                <MultipleSelect send={sendPermsion} />
              </span>
              <button className="btnForm" onClick={validation}>
                Submit
              </button>
            </form>
          </div>
        </main>
      </div>
    </div>
  );
};


export default EditAdmin