import React, { useEffect, useState } from "react";
import { IoMdClose } from "react-icons/io";
import "../../assets/sass/newChat.scss";
import { useNavigate } from "react-router";
import SingleChat from "./SingleChat";
import { gql, useQuery, useMutation, useLazyQuery } from "@apollo/client";
import SerachChat from "./SerachChat";

const SEARCH_MUTATION = gql`
  query FindUser($input: FindUserRequest) {
    findUser(input: $input) {
      success
      message
      users {
        id
        first_name
        last_name
        mobile
        email
      }
    }
  }
`;

const NewChat = ({ open, close, falseOpen }) => {
  const [getData, { error, data }] = useLazyQuery(SEARCH_MUTATION);

  const navigate = useNavigate();
  const [isOtpActive, setIsOtpActive] = useState(false);
  const [search, setSearch] = useState();
  const [searchList, setSearchList] = useState();

  const handleSearch = (e) => {
    let text=e.target.value;
    setSearch(text);
    console.log(text);
    getData({ variables: { input: { query: text } } });
    console.log("data==>", data?.findUser?.users);
   

  };

  useEffect(() => {
    console.log("data==>", data?.findUser?.users);
    if (data?.findUser?.success === true) {
      setSearchList(data?.findUser?.users);
      console.log(searchList);
    }
  }, [data]);

  const handleClose = () => {
    close(false);
    falseOpen(false);
    setSearchList(null);
    setSearch("");
  };

  return (
    <div className={open ? "new-chat-modal open" : "new-chat-modal"}>
      <div className="new-chat-header">
        <h2>New Chat</h2>
        <div className="new-chat-close" onClick={handleClose}>
          <IoMdClose />
        </div>
      </div>
      <div className="user-search-section">
        <div className="search-bar" style={{ height: 37, width: 250 }}>
          <input
            value={search}
            onChange={handleSearch}
            type="text"
            placeholder="Search..."
          />
        </div>
      </div>

      {searchList?.map((data) => {
        return (
          <SerachChat
            handleCloseSearch={handleClose}
            user={data}
            first_name={data.first_name}
            last_name={data.last_name}
          />
        );
      })}
    </div>
  );
};

export default NewChat;
