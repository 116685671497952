import React, { useContext, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Checkbox from "@mui/material/Checkbox";
import Modal from "@mui/material/Modal";
import "./Forward.css";
import Sdata from "./Sdata";
import UserData from "./ForwardData";
import { gql, useQuery, useMutation } from "@apollo/client";
import ForwardData from "./ForwardData";
import { AuthContext } from "../../../hooks/AuthContext";

const MYCHAT_LIST = gql`
  query MyChatList {
    myChatList {
      success
      message
      result {
        id
        code
        is_group
        name
        message
        attachment
        message_date
        type
        image

      }
    }
  }
`;

const SEND_MESSAGE_MUTATION = gql`
  mutation SendChat($input: SendChatRequest) {
    sendChat(input: $input) {
      success
      message
      chat {
        id
        room_id
        room_code
        user_id
      }
      chat_room {
        id
        name
        code
      }
      from_me
    }
  }
`;
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function BasicModal({ closeForward, isForward, close, listMessages }) {
  //   const [open, setOpen] = React.useState(false);
  //   const handleOpen = () => setOpen(true);
  const handleClose = () => close(false);
  const { changeCount } = useContext(AuthContext);

  const { userListloading, error, data, refetch } = useQuery(MYCHAT_LIST);
  // console.log("data",data);
  const [selectedMessages, setSelectedMessages] = useState([]);
  const [SendMessage] = useMutation(SEND_MESSAGE_MUTATION);

  const [chatList, setChatList] = useState([]);
  const [searchfield, setSearchField] = useState("");

  //   React.useEffect(()=>{
  // setOpen(isForward)
  //   },[isForward])
  const [checked, setChecked] = React.useState(true);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };


  useEffect(() => {
    // let  originalArray.reverse();
    let chatArray = []
    chatArray = data?.myChatList?.result
    //  console.log(typeof(chatArray));
    // let dataArray=chatArray.reverse()
    setChatList(chatArray);
  },
    [data]);

  useEffect(() => {

    // setChatList([...chatList].reverse());

  }, [chatList])
  // console.log(chatList);

  const filteredContacts = chatList?.filter((contact) => {
    return contact?.name?.toLowerCase().includes(searchfield?.toLowerCase());
  });

  // console.log(listMessages);
  // console.log(typeof(listMessages));
  // console.log((selectedMessages));

  const handleCheckboxChange = (messageId) => {
    setSelectedMessages((prevSelectedMessages) => {
      if (prevSelectedMessages.includes(messageId)) {
        return prevSelectedMessages.filter((id) => id.id !== messageId.id);
      } else {
        return [...prevSelectedMessages, messageId];
      }
    });
  };

  const handleMessage = async (obj) => {
    // console.log(obj);

    try {
      const { data } = await SendMessage({
        variables: {
          input: {
            room_code: obj.roomId,
            message: obj.type === "text" ? obj.message : "voice",
            type: obj.type,
            attachment: obj.type === "attachment" ? obj.attachment : "a",
            is_forwarded: 1,
            reply_to: null,
          },
        },
      });
      // console.log(data.sendChat);
      if (data.sendChat.success === true) {

      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleForward = () => {
    let code = []
    selectedMessages.map((chat) => {
      // console.log(chat);
      code.push(chat.code)
      return ("")
    })
    // console.log(code.length)

    for (let i = 0; i < code.length; i++) {
      listMessages.map((chat) => {
        // console.log(code[i]);
        // console.log("chats",chat);
        let obj = {
          roomId: code[i],
          attachment: chat.attachment,
          message: chat.message,
          type: chat.type

        }
        handleMessage(obj)
        return ("")
      })
      if (i === code.length - 1) {
        handleClose()
        closeForward('p')
      }
    }

    changeCount()



  }


  return (
    <div>
      {/* <Button onClick={handleOpen}>Open modal</Button> */}
      <Modal
        open={isForward}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="forward-content">
          <div class="modal-header-1">
            <h2>Forward message to</h2>
            <div class="modal-close-1"
              onClick={handleClose}
            >
              <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 512 512"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M405 136.798L375.202 107 256 226.202 136.798 107 107 136.798 226.202 256 107 375.202 136.798 405 256 285.798 375.202 405 405 375.202 285.798 256z"></path>
              </svg>
            </div>
          </div>

          <div class="inputfeild">
            <input placeholder="Search..."
              onChange={(e) => setSearchField(e.target.value)}
            />
          </div>
          <h3 className="recent">RECENT CHATS</h3>

          {searchfield ? <ul>

            {filteredContacts.map((contact) => {
              // console.log(contact);
              return (

                <li
                  key={contact.id}
                >
                  <div className="check-btn">
                    <Checkbox
                      className="checkbox-btn"
                      checked={selectedMessages.includes(contact?.id)}
                      onChange={() => handleCheckboxChange(contact)}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  </div>
                  <ForwardData

                    sname={contact.name}
                    image={contact.image}
                    aname={contact.last_name}
                  />
                </li>
              )
            })}

          </ul>
            :
            <ul>

              {chatList?.map((contact) => (
                <li
                  key={contact.id}
                >
                  <div className="check-btn">
                    <Checkbox
                      className="checkbox-btn"
                      checked={selectedMessages.includes(contact)}
                      onChange={() => handleCheckboxChange(contact)}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  </div>
                  <ForwardData
                    sname={contact.name}
                    image={contact.image}
                    aname={contact.aname}
                  />
                </li>
              ))}

            </ul>
          }

          <span className="pp">
            <div className="Popup" style={{ transform: "translateY(0%)" }}>
              <span className="Forward-selected-name">
                <span dir="auto" aria-label="" className="Person-name">
                  You
                </span>
              </span>
              <div
                onClick={handleForward}
                data-animate-btn="true" className="sendmsg" style={{ opacity: "1", transform: "scale(1)" }}>
                <div role="button" tabindex="0" className="circlearound">
                  <span data-testid="send" aria-label="Send" data-icon="send" className="">
                    <svg viewBox="0 0 24 24" height="24" width="24" preserveAspectRatio="xMidYMid meet" className="" version="1.1" x="0px" y="0px" enable-background="new 0 0 24 24" >
                      <path fill="currentColor" d="M1.101,21.757L23.8,12.028L1.101,2.3l0.011,7.912l13.623,1.816L1.112,13.845 L1.101,21.757z"></path>
                    </svg>
                  </span>
                </div>
              </div>
            </div>
          </span>

        </div>
      </Modal>
    </div>
  );
}