import { ApolloClient, gql, InMemoryCache, useQuery } from '@apollo/client';
import { useEffect } from 'react';
import { createContext, useState } from 'react';
import useGet from './Api/useGet';
const apiEndpoint='https://app.steelconnect.co.in:3000/api/admin/messages/groups?page=1'
export const AuthContext = createContext();

const PROFILE_QUERY=gql`
query Profile {
  profile {
    success
    message
    user {
      first_name
      last_name
      image
      email
      mobile
      id

    }
  }
}
`

export const AuthProvider = ({ children }) => {
  const {   data:UserData ,refetch } = useQuery(PROFILE_QUERY);
  const { makeRequest, data, isLoading, error } = useGet(apiEndpoint);
  const [user, setUser] = useState(null);
  const [auth,setAuth]=useState(null);
const [userData,setUserData]=useState()
  const [count, setCount] = useState(0);

  
  const [groupData,setGroupData]=useState()

  const [chatInfo,setChatInfo]=useState()

  const [permision,setPermsion]=useState()

  const [reply,setReply]=useState()

  let client = new ApolloClient({
    uri: "https://app.steelconnect.co.in:3000/graphql",
    headers: {
      Authorization: `Bearer ${auth}`,
      // Replace YOUR_TOKEN_HERE with your actual token
    },
    cache: new InMemoryCache(),
  });

  const hanldeReply=(data)=>{
    setReply(data)
  }

  useEffect(()=>{
setUserData(UserData?.profile?.user)
console.log(userData);

  },[UserData])

const handleUserData=(data)=>{
  setUserData(data)
}

  const handlePermission=(data)=>{
    setPermsion(data)
    console.log(permision);

  }
  const handleChatData=(data)=>{
    setChatInfo(data)
    console.log(chatInfo);
  }


  useEffect(()=>{
    let token=localStorage.getItem('token');
    console.log(token);
    const getData=async()=>{
      await makeRequest(token)
      console.log(data);
    }
getData()
setGroupData(data?.data)
console.log(groupData);
},[])

useEffect(()=>{
  setGroupData(data?.data)
  console.log(groupData);
},[data])

  

 const  handleGroupData =(data)=>{
  setGroupData(data) 
}


  const changeCount = () => {
    setCount(count + 1);
  };
  
  const hanldeAuth=(authData)=>{
    console.log("authData",authData);
    // setAuth(authData)
    // console.log(auth);
  }

  
useEffect(()=>{
  let token=  localStorage.getItem('token');
  console.log(token);
   setAuth(token)

},[])

useEffect(()=>{
   client = new ApolloClient({
    uri: "https://app.steelconnect.co.in:3000/graphql",
    headers: {
      Authorization: `Bearer ${auth}`,
      // Replace YOUR_TOKEN_HERE with your actual token
    },
    cache: new InMemoryCache(),
  });
},[auth])
console.log(auth);



  const login = (userData) => {
    setUser(userData);
  };

  const logout = () => {
    setUser(null);
  };

  return (
    <AuthContext.Provider value={{
      client,
      hanldeReply,
      reply,
      handleUserData,
      userData,
      handlePermission,
      permision,
      handleChatData,chatInfo,
      groupData,handleGroupData,
      count,changeCount,auth,user, login, logout,hanldeAuth }}>
      {children}
    </AuthContext.Provider>
  );
};
