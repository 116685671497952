import React, { useEffect } from "react";

let type = "";
const UserReply = ({ chats }) => {
  console.log(chats);
  const getFileExtension = (filename) => {
    return filename.slice(filename.lastIndexOf(".") + 1).toLowerCase();
  };

  const detectDocumentType = (link) => {
    const extension = getFileExtension(link);

    if (
      extension === "jpg" ||
      extension === "jpeg" ||
      extension === "png" ||
      extension === "gif"
    ) {
      return "photo";
    } else if (
      extension === "mp4" ||
      extension === "avi" ||
      extension === "mov" ||
      extension === "mkv"
    ) {
      return "video";
    } else if (extension === "pdf") {
      return "pdf";
    } else {
      return "unknown";
    }
  };

  useEffect(() => {
    let link =
      chats?.chat?.type === "attachment" ? chats?.chat?.attachment : "";
    type = detectDocumentType(link);
  }, [chats]);

  return (
    <div>
    
       <div className="chat-msg-content">
       <div class="chat-reply-1">
         <span class="bg-color-2 _sidebar"></span>
         <div class="replywraper">
           <div class="chat-heading-name color-2" role="button">
             <span
               data-testid="author"
               dir="auto"
               aria-label=""
               class="chat-name-val"
             >
               {chats?.user?.first_name} &nbsp;
               {chats?.user?.last_name}
             </span>
           </div>
           <div class="chat-reply-msg" dir="ltr" role="button">
             <span dir="auto" aria-label="" class="quoted-mention _11JPr">
               {chats?.chat?.reply_type === "text" ? (
                 <div>{chats?.chat?.reply_message}</div>
               ) : (
                 <div>
                   <img
                     className="replyphoto"
                     src={chats?.chat?.reply_attachment}
                     alt=""
                   />
                 </div>
               )}
             </span>
           </div>
         </div>
         <div className="chat-msg-content">
           <div className="chat-msg-text">{chats?.chat?.message}</div>
         </div>
       </div>
     </div> 
     
    
     
    </div>
  );
};

export default UserReply;
