import axios from "axios";
import React, { useState } from "react";
import { AiFillEdit, AiFillDelete } from "react-icons/ai";
import { useNavigate } from "react-router";
import useDelete from "../../hooks/Api/useDelete";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
// import Button from '@mui/material/Button';
import { Button, Col, Row } from "react-bootstrap";
import FormRow from "../../components/admin/FormRow";
import Checkbox from "@mui/material/Checkbox";

import useGet from "../../hooks/Api/useGet";
import { useEffect } from "react";
import usePost from "../../hooks/Api/usePost";
const apiEndpoint = "https://app.steelconnect.co.in:3000/api/admin/user";
const addChatParticipant =
  "https://app.steelconnect.co.in:3000/api/admin/messages/add-participant";
const removeChatParticipant =
  "https://app.steelconnect.co.in:3000/api/admin/messages/remove-participant";

let code = null;
let filteredUsers = [];
const UserTable = ({ heading, groups, handleUser }) => {
  const navigate = useNavigate();
  const { makeRequest, data, isLoading, error } = useGet(apiEndpoint);
  const { makeRequest: addMembers, data: groupData } =
    usePost(addChatParticipant);
  const { makeRequest: removeMembers, data: removegroupData } =
    usePost(removeChatParticipant);
  const [selectedMessages, setSelectedMessages] = useState([]);

  const [isActive, setIsActive] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorE2, setAnchorE2] = React.useState(null);
  const [filterUser, setFilterUser] = useState("");
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [users, setUsers] = useState(null);
  const [participantUser, setParticipantsUser] = useState([]);

  const handleCheckboxChange = (messageId) => {
    setSelectedMessages((prevSelectedMessages) => {
      if (prevSelectedMessages.includes(messageId)) {
        return prevSelectedMessages.filter((id) => id !== messageId);
      } else {
        return [...prevSelectedMessages, messageId];
      }
    });
  };

  console.log(selectedMessages);
  useEffect(() => {
    let token = localStorage.getItem("token");
    // console.log(token);
    const getData = async () => {
      await makeRequest(token);
      // users=data?.users
    };
    getData();
  }, []);

  useEffect(() => {
    setUsers(data?.users);
  }, [data]);

  // console.log(groups);

  // console.log(filterUser);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleClickE2 = (event) => {
    setAnchorE2(event.currentTarget);
  };

  const handleCloseE2 = () => {
    setAnchorE2(null);
  };

  const openE2 = Boolean(anchorE2);
  
  const idE2 = openE2 ? "simple-popover" : undefined;

  const participateGroups = async (id) => {
    const apiEndpoint = `https://app.steelconnect.co.in:3000/api/admin/messages/participants/${id}`;

    let token = localStorage.getItem("token");

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await axios.get(apiEndpoint, config);
      console.log(response);
      if (response?.data?.success === true) {
        console.log(response?.data);
        setParticipantsUser(response?.data?.data);
        console.log(participantUser);
        handleUser();
      }
    } catch (err) {
      console.log(err);
    }
  };
  const deleteMessage = async (id) => {
    const apiEndpoint = `https://app.steelconnect.co.in:3000/api/admin/messages/delete/${id}`;

    let token = localStorage.getItem("token");

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await axios.get(apiEndpoint, config);
      console.log(response);
      if (response.data.success) {
        handleUser();
      }
    } catch (err) {
      console.log(err);
    }
  };

  const deleteGroup = async (id) => {
    const apiEndpoint = `https://app.steelconnect.co.in:3000/api/admin/messages/room-delete/${id}`;

    let token = localStorage.getItem("token");

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await axios.get(apiEndpoint, config);
      console.log(response);
      if (response.data.success) {
        handleUser();
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case "filter-user":
        setFilterUser(value);
        break;

      default:
        break;
    }
  };

  const removeMembersGroup = async () => {
    let userIds = selectedMessages.join();
    console.log(userIds);
    console.log(code);
    const formData = {
      code: code,
      user_id: userIds,
    };

    let token = localStorage.getItem("token");

    await removeMembers(formData, token);

    handleCloseE2();
    handleUser()
    setSelectedMessages([]);
  };
  const addMembersGroup = async () => {
    let userIds = selectedMessages.join();
    console.log(userIds);
    console.log(code);
    const formData = {
      code: code,
      user_id: userIds,
    };

    let token = localStorage.getItem("token");

    await addMembers(formData, token);

    handleUser()
    setSelectedMessages([]);
    handleClose(); 
   };

  useEffect(() => {
    console.log(groupData);
    if (groupData?.success === true) {
      setSelectedMessages([]);
      handleClose();
    }
  }, [groupData]);

  const filterUsers = () => {
    let emails = [];
    participantUser?.map((user) => {
      emails.push(user.email);
      return "";
    });
    console.log(emails);
    filteredUsers = users?.filter((user) => !emails.includes(user.email));
    console.log(filteredUsers);
  };

  useEffect(() => {
    console.log(participantUser);
    console.log(users);
    filterUsers();
    // console.log();
  }, [participantUser]);

  return (
    <div
      className="all-users"
     
    >
      <div className="user-table">
        <Popover
          id={idE2}
          open={openE2}
          anchorEl={anchorE2}
          onClose={handleCloseE2}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <div
            className="fform"
            style={{
              width: "450px",
              height: "380px",
              overflow: "hidden",
              margin: "20px",
            }}
          >
            <div
              md={4}
              style={{ position: "relative" }}
              onClick={() => setIsFilterOpen(!isFilterOpen)}
            >
              <FormRow
                type="text"
                name="filter-user"
                value={filterUser}
                handleChange={handleChange}
                labelText="Filter User List"
              />
              <div class={"user-wrap display-block"}>
                <div class="list-wrap">
                  <ul
                    className="user-lis"
                    style={{ border: "1px solid black" }}
                  >
                    {participantUser?.map((user) => {
                      return (
                        <>
                          <li>
                            <Checkbox
                              checked={!selectedMessages.includes(user?.user_id)}
                              onChange={() => handleCheckboxChange(user?.user_id)}
                              inputProps={{ "aria-label": "controlled" }}
                            />

                            <label for="user1">
                              {user.first_name}
                              &nbsp;
                              {user.last_name}
                            </label>
                          </li>
                        </>
                      );
                    })}
                  </ul>
                </div>

                <button
                  onClick={removeMembersGroup}
                  className="savebtn"
                  style={{
                    backgroundColor: "#0086ff",
                    color: "white",
                    width: "100px",
                    borderRadius: "20px",
                    padding: "10px",
                  }}
                >
                  save
                </button>
              </div>
            </div>
          </div>
        </Popover>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <div
            className="fform"
            style={{
              width: "450px",
              height: "380px",
              overflow: "hidden",
              margin: "20px",
            }}
          >
            <div
              md={4}
              style={{ position: "relative" }}
              onClick={() => setIsFilterOpen(!isFilterOpen)}
            >
              <FormRow
                type="text"
                name="filter-user"
                value={filterUser}
                handleChange={handleChange}
                labelText="Filter User List"
              />
              <div class={"user-wrap display-block"}>
                <div class="list-wrap">
                  <ul
                    className="user-lis"
                    style={{ border: "1px solid black" }}
                  >
                    {filteredUsers?.map((user) => {
                      return (
                        <>
                          <li>
                            <Checkbox
                              checked={selectedMessages.includes(user?.id)}
                              onChange={() => handleCheckboxChange(user?.id)}
                              inputProps={{ "aria-label": "controlled" }}
                            />

                            <label for="user1">
                              {user.first_name}
                              &nbsp;
                              {user.last_name}
                            </label>
                          </li>
                        </>
                      );
                    })}
                  </ul>
                </div>

                <button
                  onClick={addMembersGroup}
                  className="savebtn"
                  style={{
                    backgroundColor: "#0086ff",
                    color: "white",
                    width: "100px",
                    borderRadius: "20px",
                    padding: "10px",
                  }}
                >
                  save
                </button>
              </div>
            </div>
          </div>
        </Popover>

        <div className="container-fluid">
          <div className="row row--top-40">
            <div
              className="col-md-12"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <h2 className="row__title">
                <span className="p-1">{groups?.length}</span>
                {heading}
              </h2>
              <button
                className="register-btn btn btn-primary"
                onClick={() => navigate("../create-group")}
              >
                Add Group
              </button>
            </div>
          </div>
          <div  style={{
        height: "500px",
        overflow: "scroll",
      }} className="row row--top-20">
            <div className="col-md-12">
              <div className="table-container">
                <table className="table">
                  <thead className="table__thead">
                    <tr>
                      <th className="table__th">
                        <input
                          id="selectAll"
                          type="checkbox"
                          className="table__select-row"
                        />
                      </th>
                      <th className="table__th">Group Name</th>
                      <th className="table__th">Add Group Members</th>
                      <th className="table__th">Remove Group Members</th>
                      <th className="table__th">Message Count</th>
                      <th className="table__th">Group Pic</th>
                      {/* <th className="table__th">Status</th> */}
                      <th className="table__th">Message Delete</th>
                      <th className="table__th">Group Delete</th>
                    </tr>
                  </thead>
                  <tbody className="table__tbody">
                    {groups?.map((user, id) => {
                      return (
                        <tr className="table-row table-row--chris">
                          <td className="table-row__td">
                            <input
                              id=""
                              type="checkbox"
                              className="table__select-row"
                            />
                          </td>
                          <td className="table-row__td">
                            {/* <div className="table-row__img"></div> */}
                            <div className="table-row__info">
                              <p className="table-row__name"> {user.name}</p>
                            </div>
                          </td>
                          <td
                            data-column="Destination"
                            className="table-row__td"
                          >
                            <div
                              onClick={(e) => {
                                handleClick(e);
                                code = user.code;
                                participateGroups(user.id);
                              }}
                              aria-describedby={id}
                            >
                              <Button>Add Members</Button>
                            </div>
                          </td>
                          <td
                            data-column="Destination"
                            className="table-row__td"
                          >
                            <div
                              onClick={(e) => {
                                handleClickE2(e);
                                code = user.code;
                                participateGroups(user.id);
                              }}
                              aria-describedby={id}
                            >
                              <Button>Remove Members</Button>
                            </div>
                          </td>
                          <td
                            data-column="Destination"
                            className="table-row__td"
                          >
                            {user.message_count}
                          </td>
                          <td
                            data-column="Destination"
                            className="table-row__td"
                          >
                            <div className="profile-area">
                              <div className="profile-photo">
                                <img
                                  style={{ height: "38px", width: "43px" }}
                                  src="https://img.freepik.com/free-icon/user_318-159711.jpg"
                                  alt="group "
                                />
                              </div>
                            </div>
                          </td>

                          <td className="table-row__td">
                            <div className="table-row__bin">
                              <AiFillDelete
                                onClick={() => deleteMessage(user.id)}
                              />
                            </div>
                          </td>

                          <td className="table-row__td">
                            <div className="table-row__bin">
                              <AiFillDelete
                                onClick={() => deleteGroup(user.id)}
                              />
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserTable;
