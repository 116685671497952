import axios from "axios";
import React from "react";
import { useState } from "react";
import { AiFillEdit, AiFillDelete } from "react-icons/ai";
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { useEffect } from "react";

const UserTable = ({ users, handleUser ,group }) => {

  const [chatParticipants,setChatParticipants]=useState()

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  



  const getChatParticipants=async(id)=>{
    const     apiEndpoint=`https://app.steelconnect.co.in:3000/api/admin/messages/participants/${id}`
       
        let token=localStorage.getItem('token');
        
        const config = {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        };
     try{   const response=await axios.get(apiEndpoint,config);
        console.log(response);
        if(response?.data?.success){
          console.log("message");
          setChatParticipants(response?.data.data)
          console.log(chatParticipants);
       
         
        }
      }
      catch (err) {
        console.log(err);
      }
      }

  const deleteUser=async(id)=>{
    const     apiEndpoint=`https://app.steelconnect.co.in:3000/api/admin/messages/delete/${id}`
       
        let token=localStorage.getItem('token');
        
        const config = {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        };
     try{   const response=await axios.get(apiEndpoint,config);
        console.log(response);
        if(response.data.success){
          handleUser()
        }
      }
      catch (err) {
        console.log(err);
      }
      
    }

useEffect(()=>{

},[])

  return (
<div className='all-users' 

>
<Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      
      >
       <div style={{ height: 50, width: 136, padding: 10 }}>
        {chatParticipants?.map((user)=>{
          return (
            <span>
              {user?.first_name} &nbsp; {user?.last_name}  <br />
            </span>
          )
        })}
       </div>
      </Popover>
    <div className="user-table">
      <div class="container-fluid">
        <div class="row row--top-40">
          <div class="col-md-12">
            <h2 class="row__title">
            {group===true ? (
                                <div>Group Chat</div>
                              ) : (
                                <div>Normal Chat</div>
                              )}
                             
            </h2>
          </div>
        </div>
        <div style={{
  height: "500px",
  overflow: "scroll"
}} class="row row--top-20">
          <div class="col-md-12">
            <div class="table-container">
              <table class="table">
                <thead class="table__thead">
                  <tr>
                    <th class="table__th">
                      <input
                        id="selectAll"
                        type="checkbox"
                        class="table__select-row"
                      />
                    </th>
                    <th class="table__th">Chat Type</th>
                    <th class="table__th">Members Count</th>
                    <th class="table__th">Members Exchange Count</th>
                    <th class="table__th">Action</th>
                  </tr>
                </thead>
                {users?.map((user, id) => {
                  return (
                    <tbody class="table__tbody">
                   

                      <tr class="table-row table-row--chris">
                        <td class="table-row__td">
                          <input
                            id=""
                            type="checkbox"
                            class="table__select-row"
                          />
                        </td>
                        <td class="table-row__td">
                          {/* <div class="table-row__img"></div> */}
                          <div 
                         
                          onClick={(e)=>{
                            
                            handleClick(e)
                            getChatParticipants(user.id)
                          }
                          }
                          aria-describedby={id} 
                          class="table-row__info">
                            <p class="table-row__name">
                             {user.name}
                            </p>
                          </div>
                        </td>
                        <td data-column="Destination" class="table-row__td">
                          <b>{user.max_participants}</b>
                        </td>
                        <td data-column="Destination" class="table-row__td">
                          <b>
{user.message_count}

                          </b>
                        </td>
                        <td class="table-row__td">
                          
                          <div class="table-row__bin">
                            <AiFillDelete 
                                            onClick={()=> deleteUser(user.id)}
                                            />
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  );
                })}
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  );
};

export default UserTable;
