import axios from "axios";
import React, { useState } from "react";
import { AiFillEdit, AiFillDelete } from "react-icons/ai";
import { useNavigate } from "react-router";
import useDelete from "../../hooks/Api/useDelete";
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
// import Button from '@mui/material/Button';
import { Button, Col, Row } from "react-bootstrap";
import FormRow from "../../components/admin/FormRow";
import Checkbox from '@mui/material/Checkbox';

import useGet from '../../hooks/Api/useGet'
import { useEffect } from "react";
import usePost from "../../hooks/Api/usePost";
const apiEndpoint='https://app.steelconnect.co.in:3000/api/admin/user'
const addChatParticipant='https://app.steelconnect.co.in:3000/api/admin/messages/add-participant'

const UserTable = ({ heading, logs,  }) => {
  const navigate = useNavigate();
 
  const [isActive, setIsActive] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [filterUser, setFilterUser] = useState("");
  const [isFilterOpen, setIsFilterOpen] = useState(false);
const [users,setUsers]=useState(null)





  // console.log(filterUser);
  const handleClick = (event) => {

    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  console.log(open);
  const id = open ? 'simple-popover' : undefined;

  const deleteMessage = async (id) => {
    const apiEndpoint = `https://app.steelconnect.co.in:3000/api/admin/messages/delete/${id}`;

    let token = localStorage.getItem("token");

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await axios.get(apiEndpoint, config);
      console.log(response);
      if (response.data.success) {
        handleUser();
      }
    } catch (err) {
      console.log(err);
    }
  };

 

 console.log(logs);

 

  return (
<div className='all-users' 

>
    <div className="user-table">
             

      <div className="container-fluid">
        <div className="row row--top-40">
          <div
            className="col-md-12"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <h2 className="row__title">
              <span className="p-1">{logs?.length}</span>
              {heading}
            </h2>
         
          </div>
        </div>
        <div style={{
  height: "500px",
  overflow: "scroll"
}} className="row row--top-20">
          <div className="col-md-12">
            <div className="table-container">
              <table className="table">
                <thead className="table__thead">
                  <tr>
                    <th className="table__th">
                      <input
                        id="selectAll"
                        type="checkbox"
                        className="table__select-row"
                      />
                    </th>
                    <th className="table__th"> Name</th>
                    <th className="table__th"> User Types</th>
                    <th className="table__th"> Selected Days</th>
                    <th className="table__th">Created  Dated</th>
                    {/* <th className="table__th">Status</th> */}
                 
                  </tr>
                </thead>
                <tbody className="table__tbody">
                  {logs?.map((user, id) => {
                    
                    const dateTime = new Date(user?.created_at);

                    const year = dateTime.getFullYear();
const month = dateTime.getMonth() + 1; // Months are 0-indexed, so we add 1 to get the correct month (January is 0).
const date = dateTime.getDate();
const hours = dateTime.getHours();
const minutes = dateTime.getMinutes();
const seconds = dateTime.getSeconds();

// Formatting the date and time
const formattedDateTime = `${year}-${month.toString().padStart(2, '0')}-${date.toString().padStart(2, '0')} ${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;

                    return (
                      <tr className="table-row table-row--chris">
                    
                        <td className="table-row__td">
                          <input
                            id=""
                            type="checkbox"
                            className="table__select-row"
                          />
                        </td>
                        <td className="table-row__td">
                          {/* <div className="table-row__img"></div> */}
                          <div className="table-row__info">
                            <p className="table-row__name"> {user.name}</p>
                          </div>
                        </td>
                        <td data-column="Destination" className="table-row__td">
                        <div 

                     
                      
                    >
                    
                   {user.type}
                    </div>
                        </td>
                        <td 
                      
                        data-column="Destination" className="table-row__td">
                 
                       {user?.delete_time}

                        </td>
                        <td data-column="Destination" className="table-row__td">
                          <div className="profile-area">
                            <div className="profile-photo">
                            {formattedDateTime}
                            </div>
                          </div>
                        </td>

                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  );
};

export default UserTable;
