import React, { useState } from 'react'
import AllChats from '../components/chatFeed/AllChats'
import ChatDetails from '../components/chatFeed/ChatDetails'
import ChatMain from '../components/chatFeed/ChatMain'
import '../assets/sass/chatFeed.scss';
import { useContext } from 'react';
import { AuthContext } from '../hooks/AuthContext';
import { useEffect } from 'react';

const ChatFeed = () => {
  
  const [showDetails,setShowDetials]=useState(false)

    const {auth} =useContext(AuthContext)


  useEffect(()=>{

    if(auth)
    {
      // window.location.reload();

    }
  },[auth])

  return (
  <>
  <div className='app'>
    <section id='chat-feed' className='wrapper'>
        <AllChats />
        <ChatMain setShowDetials={setShowDetials} />
        {showDetails?
        <ChatDetails setShowDetials={setShowDetials} />
      :""
      }
    </section>
  </div>
    </>
  )
}

export default ChatFeed
