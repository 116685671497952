import React, { useEffect } from "react";
import "../../assets/sass/registerForm.scss";
import { gql, useMutation } from "@apollo/client";
import { useState } from "react";
import Form from "./Form";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";

const LOGIN_MUTATION = gql`
  mutation AuthRequest($input: AuthRequest) {
    login(input: $input) {
      token
    }
  }
`;

const REGISTER_MUTATION = gql`
  mutation ($input: AuthRegisterRequest) {
    register(input: $input) {
      message
      success
    }
  }
`;

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  // border: '2px solid #000',
  borderRadius: "20px",
  boxShadow: 24,
  p: 4,
};

const RegisterForm = () => {
  const [isOpen, setIsOpen] = React.useState(false);
  const handleOpen = () => setIsOpen(true);
  const handleClose = () => setIsOpen(false);
  const [errrorMessage, setErrorMessage] = useState("");

  const [validate, setValidate] = useState({});

  const [email, setEmail] = useState("bihide9218@byorby.com");
  const [password, setPassword] = useState("123456");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [mobile, setMobile] = useState("");
  const [UserLogin] = useMutation(LOGIN_MUTATION);
  const [UserRegister] = useMutation(REGISTER_MUTATION);


  const handleMobile=(e)=>{

    const value = e.target.value;
    const regex = /^[0-9\b]+$/;

    if (value === '' || regex.test(value)) {
      setMobile(value.slice(0, 10));
    }
      // setMobile(e.target.value)
    
  }

 

  const handleLogin = async (e) => {
    e.preventDefault();
    console.log("name", email, "passowerd", password);
    try {
      const { data } = await UserLogin({
        variables: { input: { email: email, password: password } },
      });
      console.log(data.login.token);
    } catch (error) {
      console.error(error);
    }
  };

  const onlogin = (e, values) => {
    e.preventDefault();
  };

  const validateRegister = () => {
    let isValid = true;

    let validator = Form.validator({
      firstName: {
        value: firstName,
        isRequired: true,
      },
      lastName: {
        value: lastName,
        isRequired: true,
      },
      // email: {
      //   value: email,
      //   isRequired: true,
      //   isEmail: true,
      // },
      mobile: {
        value: mobile,
        isRequired: true,
        isMobile: true,
        minLength: 10,
        // maxLength:10,
      },
      password: {
        value: password,
        isRequired: true,
        minLength: 6,
      },
    });

    if (validator !== null) {
      setValidate({
        validate: validator.errors,
      });

      isValid = false;
    }
    return isValid;
  };

  //   useEffect(()=>{
  //     const validate = validateRegister();

  //   },[firstName])

  const register = async (e) => {
    e.preventDefault();

    const validate = validateRegister();

    if (validate) {
       const fullName = `${firstName}${lastName}`;
      try {
        const { data } = await UserRegister({
          variables: {
            input: {
              first_name: firstName,
              last_name: lastName,
              // email,
              mobile,
              password,
              username: fullName,
            },
          },
        });
        console.log(data.register);

        setErrorMessage(data.register.message);
        handleOpen();

        if (data.register.success) {
          setValidate({});
          setFirstName("");
          setLastName("");
          setEmail("");
          setPassword("");
          setMobile("");
        }
      } catch (error) {
        console.error(error);
      }

      //   alert("Successfully Register User");
    }
  };

  return (
    <div>
      <form className="contact-form" onSubmit={register} name="Form">
        <Modal
          open={isOpen}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              {errrorMessage}{" "}
            </Typography>

            <Box style={{ marginLeft: "90px", marginTop: "20px" }}>
              <Button variant="contained" color="success" onClick={handleClose}>
                Ok
              </Button>
            </Box>
          </Box>
        </Modal>
        <h6>Register</h6>
        <div className="messages"></div>

        <div>
          <div>
            <div className="col-md-12">
              <div className="mb-4">
                <label for="first_name" className="form-label">
                  FirstName *
                </label>
                <input
                  type="text"
                  onChange={(e) => setFirstName(e.target.value)}
                  id="name"
                  autoComplete="off"
                  name="firstName"
                  placeholder="FirstName"
                  value={firstName}
                  className={`form-control ${
                    validate.validate && validate.validate.firstName
                      ? "is-invalid "
                      : ""
                  }`}
                />
                <div
                  className={`invalid-feedback text-start ${
                    validate.validate && validate.validate.firstName
                      ? "d-block"
                      : "d-none"
                  }`}
                >
                  {validate.validate && validate.validate.firstName
                    ? validate.validate.firstName[0]
                    : ""}
                </div>
              </div>

              <div className="mb-4">
                <label for="lastName" className="form-label">
                  Last Name *
                </label>
                <input
                  type="text"
                  onChange={(e) => setLastName(e.target.value)}
                  id="lastName"
                  autoComplete="off"
                  name="lastName"
                  placeholder="Last Name"
                  value={lastName}
                  className={`form-control ${
                    validate.validate && validate.validate.lastName
                      ? "is-invalid "
                      : ""
                  }`}
                />
                <div
                  className={`invalid-feedback text-start ${
                    validate.validate && validate.validate.lastName
                      ? "d-block"
                      : "d-none"
                  }`}
                >
                  {validate.validate && validate.validate.lastName
                    ? validate.validate.lastName[0]
                    : ""}
                </div>
              </div>

              {/* <div className="mb-4">
                <label for="email" className="form-label">
                  Email *
                </label>
                <input
                  type="email"
                  onChange={(e) => setEmail(e.target.value)}
                  id="email"
                  autoComplete="off"
                  name="email"
                  placeholder="Email"
                  value={email}
                  className={`form-control ${
                    validate.validate && validate.validate.email
                      ? "is-invalid "
                      : ""
                  }`}
                />
                <div
                  className={`invalid-feedback text-start ${
                    validate.validate && validate.validate.email
                      ? "d-block"
                      : "d-none"
                  }`}
                >
                  {validate.validate && validate.validate.email
                    ? validate.validate.email[0]
                    : ""}
                </div>
              </div> */}

              <div className="mb-4">
                <label for="mobile" className="form-label">
                  Phone *
                </label>
                <input
                  type="number"
                  maxLength="10"
                  onChange={handleMobile}
                  // onKeyDown={handleBackspace}
                  id="mobile"
                  autoComplete="off"
                  name="mobile"
                  placeholder="Mobile Number"
                  value={mobile}
                  className={`form-control ${
                    validate.validate && validate.validate.mobile
                      ? "is-invalid "
                      : ""
                  }`}
                />
                <div
                  className={`invalid-feedback text-start ${
                    validate.validate && validate.validate.mobile
                      ? "d-block"
                      : "d-none"
                  }`}
                >
                  {validate.validate && validate.validate.mobile
                    ? validate.validate.mobile[0]
                    : ""}
                </div>
              </div>

              <div className="mb-4">
                <label for="password" className="form-label">
                  Paswword *
                </label>
                <input
                  type="password"
                  onChange={(e) => setPassword(e.target.value)}
                  id="password"
                  autoComplete="off"
                  name="password"
                  placeholder="password"
                  value={password}
                  className={`form-control ${
                    validate.validate && validate.validate.password
                      ? "is-invalid "
                      : ""
                  }`}
                />
                <div
                  className={`invalid-feedback text-start ${
                    validate.validate && validate.validate.password
                      ? "d-block"
                      : "d-none"
                  }`}
                >
                  {validate.validate && validate.validate.password
                    ? validate.validate.password[0]
                    : ""}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div  className="row gx-4">
            <div  className="col-md-12">
                <div  className="mb-4">
                    <label for="first_name"  className="form-label"> First Name *</label>
                    <input type="text"  className="form-control" id="first_name" />
                </div>
            </div>
            <div  className="col-md-12">
                <div  className="mb-4">
                    <label for="last_name"  className="form-label"> Last Name *</label>
                    <input type="text"  className="form-control" id="last_name" />
                </div>
            </div>
    
            <div  className="col-md-12">
                <div  className="mb-4">
                    <label for="email"  className="form-label">Email *</label>
                    <input type="email"  className="form-control" id="email"  />
                </div>
            </div>
            
            <div  className="col-md-12">
                <div  className="mb-4">
                    <label for="tel"  className="form-label">Phone *</label>
                    <input type="tel"  className="form-control" id="tel" />
                </div>
            </div>
            <div  className="col-md-12">
                <div  className="mb-4">
                    <label for="password"  className="form-label">Password *</label>
                    <input type="password"  className="form-control" id="password" />
                </div>
            </div>
            <div  className="col-12 text-center btn-container">
                <button onClick={handleLogin} type="button"  className="btn-nav" style={{width: '100%'}} data-bs-toggle="modal" data-bs-target="#otpModal">Sign Up</button>
            </div>
        </div> */}
        <div className="text-center">
          <button
            type="submit"
            className="btn btn-primary w-100 theme-btn mx-auto"
          >
            Sign Up
          </button>
        </div>
      </form>
    </div>
  );
};

export default RegisterForm;
