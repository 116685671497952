import React, { useEffect } from "react";
import ScoreCard from "../../components/admin/ScoreCard";
import {
  AiOutlineBarChart,
  AiOutlineLineChart,
  AiOutlineAreaChart,
} from "react-icons/ai";
import Graph from "../../components/admin/Graph";
import FilterTable from "../../components/admin/FilterTable";

const apiEndpoint = "https://app.steelconnect.co.in:3000/api/admin/dashboard";
import useGet from "../../hooks/Api/useGet";
import { useState } from "react";
import { useNavigate } from "react-router";

const Dashboard = () => {
  const [userData, setUserData] = useState();
  const { makeRequest, data, isLoading, error } = useGet(apiEndpoint);
  const navigate = useNavigate();
  useEffect(() => {
    let token = localStorage.getItem("token");
    console.log("token", token);
    const getData = async () => {
      await makeRequest(token);
      setUserData(data?.data);
    };
    getData();
  }, []);

  const navigateUsers=()=>{
    console.log("users");
    navigate('/admin/all-users')
  }

  console.log(userData);
  return (
    <div className="row">
      <div className="col-md-12">
        <main>
          <div class="page-heading mt-5">
            <h1>Dashboard</h1>
          </div>
          <div class="insights">

<div
                                onClick={navigateUsers}

>

            <ScoreCard


              text={"Users"}
              price={data?.data?.users}
              // percent={81}
              icon={<AiOutlineAreaChart />}
            />
            </div>

<div
onClick={()=>navigate('/admin/all-groups')}
>


            <ScoreCard
              text={"groups"}
              price={data?.data?.groups}
              // percent={60}
              icon={<AiOutlineBarChart />}
            />
            </div>
            <div 
            onClick={()=>navigate('/admin/messages')}

            >

           
            <ScoreCard
              text={"messages"}
              price={data?.data?.messages}
              // percent={41}
              icon={<AiOutlineLineChart />}
            />
             </div>
          </div>
        </main>
      </div>
      {/* <div className='col-md-6'><div class="progress-chart"><Graph /></div></div>
                <div className='col-md-6 table-box'><FilterTable /></div> */}
    </div>
  );
};

export default Dashboard;
