import React, { useContext } from "react";
import { IoMdClose } from "react-icons/io";
import "../../assets/sass/modal.scss";
import { useNavigate } from "react-router";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { useState } from "react";
import { gql, useMutation } from "@apollo/client";
import { AuthContext } from "../../hooks/AuthContext";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { ErrorMessage } from "formik";
import Form from "./Form";
import { useEffect } from "react";

const LOGIN_MUTATION = gql`
  mutation AuthRequest($input: AuthRequest) {
    login(input: $input) {
      success
      message
      token
      user {
        id
        first_name
        last_name
        email
        mobile
      }
    }
  }
`;

const SEND_OTP = gql`
mutation MobileLogin($input: AuthMobileRequest) {
  mobileLogin(input: $input) {
    success
    message
  }
}
`;

const VERIFY_OTP = gql`
  mutation MobileLogin($input: AuthMobileVerifyRequest) {
    mobileVerifyLogin(input: $input) {
      success
      message
      user {
        first_name
        last_name
        email
        mobile
        image
      }
      token
    }
  }
`;

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  // border: '2px solid #000',
  borderRadius: "20px",
  boxShadow: 24,
  p: 4,
};

let auth = localStorage.getItem("token");

const Modals = ({ open, close, setUser, user }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const handleOpen = () => setIsOpen(true);
  const handleClose = () => {
    setIsOpen(false);
  };

  const { hanldeAuth } = useContext(AuthContext);
  const navigate = useNavigate();

  const [getOtp, setGetOtp] = useState(false);
  const [otp, seOtp] = useState("");
  const [mobile, setMobile] = useState("");
  const [mobile2, setMobile2] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("bihide9218@byorby.com");

  const [login, { loading, error }] = useMutation(LOGIN_MUTATION);
  const [sendOtp] = useMutation(SEND_OTP);
  const [verifyOtp] = useMutation(VERIFY_OTP);
  const [errrorMessage, setErrorMessage] = useState("");
  const [validate, setValidate] = useState({});

  const handleMobile = (e) => {
    const value = e.target.value;
    const regex = /^[0-9\b]+$/;

    if (value === "" || regex.test(value)) {
      setMobile(value.slice(0, 10));
    }
    // setMobile(e.target.value)
  };
  const handleMobile2 = (e) => {
    const value = e.target.value;
    const regex = /^[0-9\b]+$/;

    if (value === "" || regex.test(value)) {
      setMobile2(value.slice(0, 10));
    }
    // setMobile(e.target.value)
  };

  const validateRegister = () => {
    let isValid = true;

    let validator = Form.validator({
      mobile: {
        value: mobile,
        isRequired: true,
        isMobile: true,
        minLength: 10,
        // maxLength:10,
      },

      password: {
        value: password,
        isRequired: true,
        minLength: 6,
      },
    });

    if (validator !== null) {
      setValidate({
        validate: validator.errors,
      });

      isValid = false;
    }
    return isValid;
  };

  const handleLogin = async (e) => {
    let count = 1;
    e.preventDefault();
    const validate = validateRegister();
    localStorage.clear();
    hanldeAuth(null);
    if (validate) {
      try {
        const { data } = await login({
          variables: { input: { email: mobile, password: password } },
        });
        console.log(data.login);

        if (data?.login?.success !== true) {
          // const textMessage = JSON.stringify(data.login.message);
          // console.log(textMessage);

          console.log("datalogin");
          setErrorMessage(data?.login?.message);
          handleOpen();
        }

        if (data?.login?.success === true) {
          const user = JSON.stringify(data?.login?.user);
          localStorage.clear();
          hanldeAuth(null);
          localStorage.setItem("token", data?.login?.token);
          localStorage.setItem("user", user);
          hanldeAuth(data.login.token);
          setUser(user + 1);
          navigate("/chats");
          navigate("/chats");
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  const handleOtp = async (e) => {
    e.preventDefault();

    try {
      console.log(mobile.toString());
      let value = mobile.toString();
      console.log(typeof value);

      const { data } = await sendOtp({
        variables: { input: { 
          mobile: mobile2,
          // type: "mobile"
         } },
      });

      console.log(data.mobileLogin.success);
      if (data.mobileLogin.success) {
        setGetOtp(true);
      }

      if (data.mobileLogin.success === false) {
        setErrorMessage(data.mobileLogin.message);

        handleOpen();
      }

      // hanldeAuth(data.login.token)
      // navigate('/chats')
    } catch (error) {
      console.error(error);
    }
  };
  const handleVerifyOtp = async (e) => {
    e.preventDefault();

    let moblieNumber = mobile.toString();
    let code = otp.toString();

    try {
      const { data } = await verifyOtp({
        variables: { input: { mobile: mobile2, otp: code } },
      });
      console.log(data.verifyOTP);

      if (data.mobileVerifyLogin.success !== true) {
        setErrorMessage(data.mobileVerifyLogin.message);
        handleOpen();
      }

      if (data.mobileVerifyLogin.success) {
        localStorage.clear();

        localStorage.setItem("token", data.mobileVerifyLogin.token);
        // navigate("/chats");

        hanldeAuth(null);
        // localStorage.setItem("user", user);
        hanldeAuth(data.mobileVerifyLogin.token);
        setUser(user + 1);
        navigate("/chats");
        // navigate("/chats");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleModal = () => {
    setValidate("");
    setMobile("");
    setPassword("");
    setEmail("");
    setGetOtp(false);
    close(false);
  };

  const [showPassword, setShowPassword] = useState(false);

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className={open ? "login-modal open" : "login-modal"}>
      <Modal
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {errrorMessage}{" "}
          </Typography>

          <Box style={{ marginLeft: "90px", marginTop: "20px" }}>
            <Button variant="contained" color="success" onClick={handleClose}>
              Ok
            </Button>
          </Box>
        </Box>
      </Modal>
      <div className="modal-header">
        <h2>Login</h2>
        <div className="modal-close" onClick={handleModal}>
          <IoMdClose />
        </div>
      </div>
      <div className="modal-form">
        <form>
          <div className="row">
            <div className="col-md-12">
              {/* <div className="mb-4">
                <label for="phone" className="form-label">
                  Phone *
                </label>
                <input
                  type="phone"
                  onChange={(e) => setMobile(e.target.value)}
                  id="email"
                  className={`form-control ${
                    validate.validate && validate.validate.mobile
                      ? "is-invalid "
                      : ""
                  }`}
                />
                <div
                  className={`invalid-feedback text-start ${
                    validate.validate && validate.validate.mobile
                      ? "d-block"
                      : "d-none"
                  }`}
                >
                  {validate.validate && validate.validate.mobile
                    ? validate.validate.mobile[0]
                    : ""}
                </div>
              </div> */}
                <div className="mb-4">
                <label for="mobile" className="form-label">
                  Phone *
                </label>
                <input
                  type="number"
                  maxLength="10"
                  onChange={handleMobile}
                  // onKeyDown={handleBackspace}
                  id="mobile"
                  autoComplete="off"
                  name="mobile"
                  placeholder="Mobile Number"
                  value={mobile}
                  className={`form-control ${
                    validate.validate && validate.validate.mobile
                      ? "is-invalid "
                      : ""
                  }`}
                />
                <div
                  className={`invalid-feedback text-start ${
                    validate.validate && validate.validate.mobile
                      ? "d-block"
                      : "d-none"
                  }`}
                >
                  {validate.validate && validate.validate.mobile
                    ? validate.validate.mobile[0]
                    : ""}
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="mb-4">
                <label for="tel" className="form-label">
                  Password *
                </label>
                <input
                  onChange={(e) => setPassword(e.target.value)}
                  type={showPassword ? "text" : "password"}
                  id="tel"
                  className={`form-control ${
                    validate.validate && validate.validate.password
                      ? "is-invalid "
                      : ""
                  }`}
                />
                <div
                  className={`invalid-feedback text-start ${
                    validate.validate && validate.validate.password
                      ? "d-block"
                      : "d-none"
                  }`}
                >
                  {validate.validate && validate.validate.password
                    ? validate.validate.password[0]
                    : ""}
                </div>

                <button type="button" onClick={togglePasswordVisibility}>
                  {showPassword ? <FaEyeSlash /> : <FaEye />}
                </button>
              </div>
            </div>
            <div className="col-md-12">
              <div className="mb-4">
                <button
                  className="login-btn"
                  onClick={handleLogin}
                  //  onClick={()=>navigate('/chats')}
                >
                  Login
                </button>
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-divider">
                <h3>OR</h3>
              </div>
            </div>
            <div className="col-md-12">
              <div className="mb-4">
                <label for="tel" className="form-label">
                  Mobile Number *
                </label>
                <input
                  value={mobile2}
                  maxLength="10"
                  onChange={handleMobile2}
                  type="number"
                  className="form-control"
                  id="tel"
                />
              </div>
            </div>
            <div className="col-md-12">
              <div className="mb-4">
                <button className="login-btn" onClick={handleOtp}>
                  {getOtp === false ? "Get OTP" : "Resend OTP"}
                </button>
              </div>
            </div>
            {getOtp && (
              <div>
                <div className="col-md-12">
                  <div className="mb-4">
                    <label for="tel" className="form-label">
                      {" "}
                      Enter Otp*
                    </label>
                    <input
                      onChange={(e) => seOtp(e.target.value)}
                      type="tel"
                      className="form-control"
                      id="tel"
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="mb-4">
                    <button className="login-btn" onClick={handleVerifyOtp}>
                      Verify OTP
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default Modals;
