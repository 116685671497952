import { gql, useLazyQuery, useQuery } from "@apollo/client";
import React, { useContext, useEffect, useState } from "react";
import { SlOptionsVertical } from "react-icons/sl";

import { AiFillMessage } from "react-icons/ai";
import NewChat from "./NewChat";
import NewGroup from "./NewGroup";
import { useNavigate } from "react-router";
import Setting from "./Settings/Seeting";
import Profile from "./Settings/Profile";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { AuthContext } from "../../hooks/AuthContext";
const SEARCH_MUTATION = gql`
  query FindUser($input: FindUserRequest) {
    findUser(input: $input) {
      success
      message
      users {
        id

        first_name
        last_name
        mobile
        email
      }
    }
  }
`;

const ChatNavbar = ({ onSearch, open, falseOpen }) => {
  // console.log(open);
  const {userData}=useContext(AuthContext)
  console.log("userData",userData);
  const [imageError, setimageError] = useState(false);

  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const popoverOpeon = Boolean(anchorEl);
  const id = popoverOpeon ? "simple-popover" : undefined;

  // console.log(id);

  const [isModalOpen, setIsModalOpen] = useState();
  const [isNewGroup, setIsNewGroup] = useState(false);
  const [isSetting, setSetting] = useState();
  const [isProfile, setIsProfile] = useState(false);
  const [getData, { loading, error, data }] = useLazyQuery(SEARCH_MUTATION);

  const [isOptionsOpen, setIsOptionsOpen] = useState(false);
  const [search, setSearch] = useState();

  useEffect(() => {
    if (open === true) {
      // console.log('trueopne');
      setIsModalOpen(open);
    }
  }, [open]);

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };
  onSearch(search);
  // console.log(search);

  useEffect(() => {
    setIsOptionsOpen(false);
  }, []);

  const handleOption = () => {
    setIsOptionsOpen(false);
    setIsNewGroup(false);
  };

  const handleGroup = () => {
    setIsNewGroup(true);
    // console.log(isNewGroup);
  };

  const logout = () => {
    // localStorage.removeItem('token');
    localStorage.clear();

    navigate("/");
  };

  const handleImageError = () => {
    setimageError(true);
    setimageError(true);
    
      };

  return (
    <>
      <div >
        <Setting open={isSetting} close={setSetting} />
        <Profile open={isProfile} close={setIsProfile} />
        <NewChat
          open={isModalOpen}
          close={setIsModalOpen}
          falseOpen={falseOpen}
        />
        <NewGroup open={isNewGroup} close={handleOption} />
        <div className="user-profile-settings">
          <div className="user-settings">
            <img
              // src={userData?.image===null?"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQhLja_b1nZD7bzgvwtJ0JlhlQaKR3ModNySg&usqp=CAU":userData?.image}

      
              src={imageError===true?"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQhLja_b1nZD7bzgvwtJ0JlhlQaKR3ModNySg&usqp=CAU":userData?.image}
        
        onError={handleImageError}

              alt="image"
              className="user-profile account-profile"
              onClick={() => setIsProfile(true)}
            />

            <div
              style={{ marginLeft: 204 }}
              onClick={() => setIsModalOpen(true)}
            >
              <AiFillMessage
                size={24}
                style={{
                  cursor: "pointer",
                }}
              />
            </div>
          </div>
          <div className="more-options">
            <div
              className="options-icon"
              onClick={handleClick}    
                      >
              <SlOptionsVertical />
            </div>
            {/* <div        className={isOptionsOpen ? "options-list open" : "options-list"}  > */}
              <Popover
              id={id}
              open={popoverOpeon}
              anchorEl={anchorEl}
              onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
              >
                            <div    
                            style={{
                              width:"200px"
                            }}
                            className="options-list"  >

                <ul className="menu-options">
                  <li onClick={handleGroup}>
                    <span>New Group</span>
                  </li>
                  {/* <li><span>All Groups</span></li> */}
                  <li
                    onClick={() => {
                      setSetting(true);
                      handleClose();
                    }}
                  >
                    <span>Settings</span>
                  </li>
                  <li onClick={logout}>
                    <span>Log Out</span>
                  </li>
                </ul>
            </div>
              </Popover>
          </div>
        </div>
        <div className="user-search-section">
          <div className="search-bar">
            <input
              value={search}
              onChange={handleSearch}
              type="text"
              placeholder="Search..."
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ChatNavbar;
