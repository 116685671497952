import React, { useState } from 'react'
import {AiFillEdit,AiFillDelete} from 'react-icons/ai'
import { useNavigate } from 'react-router'
import axios from 'axios'
import Switch from '@mui/material/Switch';

const UserTable = ({heading,handleStaff,staff}) => {
  const navigate = useNavigate();
  console.log("staff==>",staff);
  const [isActive,setIsActive] = useState(false);
  const deleteUser=async(id)=>{
    const     apiEndpoint=`https://app.steelconnect.co.in:3000/api/admin/admin-user/delete/${id}`
       
        let token=localStorage.getItem('token');
        console.log("userid",id);
        console.log("tokne",token);
        const config = {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        };
     try{   const response=await axios.get(apiEndpoint,config);
        console.log(response);
        if(response.data.message==="User Deleted Successfully"){
          handleStaff()
        }
      }
      catch (err) {
        console.log(err);
      }
      }
    
      const editAdmin=(id)=>{
        navigate(`/admin/edit-admin/${id}`)
      }


      const changeStatus=async(id)=>{
        console.log(id);
        const     apiEndpoint=`https://app.steelconnect.co.in:3000/api/admin/admin-user/change-status/${id}`
       
        let token=localStorage.getItem('token');
        
        const config = {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        };
     try{   const response=await axios.get(apiEndpoint,config);
        console.log(response);
        if(response.data.message==="User Status Changed Successfully"){
          handleStaff()
        }
      }
      catch (err) {
        console.log(err);
      }
      }

    
  return (
    <div className='all-users' 

>
    <div className='user-table'>
      <div className="container-fluid">
  <div className="row row--top-40">
    <div className="col-md-12" style={{display:'flex',justifyContent:'space-between'}}>
      <h2 className="row__title">
      <span className='p-1'>
        {staff?.length}
        </span>
        {heading} </h2>
      <button className="register-btn btn btn-primary" onClick={()=>navigate('../create-staff')}>Add Staff</button>
    </div>
  </div>
  <div 
  style={{
    height: "500px",
    overflow: "scroll"
  }}
  className="row row--top-20">
    <div className="col-md-12">
      <div className="table-container">
        <table className="table">
          <thead className="table__thead">
            <tr>
              <th className="table__th"><input id="selectAll" type="checkbox" className="table__select-row" /></th>
              <th className="table__th">Name</th>
              <th className="table__th">Mobile Number</th>
              <th className="table__th">Status</th>
              {/* <th className="table__th">User Type</th> */}
              {/* <th className="table__th">Role</th> */}
              <th className="table__th">Action</th>
            </tr>
          </thead>
          <tbody className="table__tbody">
          {staff?.map((user,id)=>{
            let status=user.status

            return (
              <tr className="table-row table-row--chris">
              <td className="table-row__td">
                <input id="" type="checkbox" className="table__select-row" />
              </td>
              <td className="table-row__td">
                {/* <div className="table-row__img"></div> */}
                <div className="table-row__info">
                  <p className="table-row__name"> 
                  {user?.first_name} 

                  </p>
                </div>
              </td>
              <td data-column="Destination" className="table-row__td">
              {user?.phone}
              </td>
              <td  data-column="Status" className="table-row__td">
                {/* <p className={`table-row__status status ${isActive ? 'status--green' : 'status--red'}`} onClick={()=>setIsActive(!isActive)}>{isActive?'Active':'Not Active'}</p> */}

                <Switch 
    color="success"
      checked={status}
      
      onClick={()=>changeStatus(user.id) }
      inputProps={{ 'aria-label': 'controlled' }}
    />


              </td>
              {/* <td data-column="Progress" className="table-row__td">
                <p className="table-row__progress">User</p>
              </td>
              <td data-column="Progress" className="table-row__td">
                <p className="table-row__progress">User</p>
              </td> */}
              <td className="table-row__td">
                <div className="table-row__edit"
                  onClick={()=> editAdmin(user.id)}
                ><AiFillEdit /></div>
                <div className="table-row__bin"
                onClick={()=>deleteUser(user.id)}
                ><AiFillDelete /></div>                
              </td>
            </tr>
            )
          })}

          </tbody>
        </table>
      </div>
    </div>
  </div>


</div>

    </div>
    </div>
  )
}

export default UserTable
