import React, { useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import FormRow from "../../components/admin/FormRow";
import FormRowSelect from "../../components/admin/FormRowSelect";
import "./Admin.css";
import Modal from "@mui/material/Modal";
import useGet from "../../hooks/Api/useGet";
import { useEffect } from "react";
import { Box, Typography } from "@mui/material";
import LogsTable from '../../components/admin/LogsTable'
import usePost from "../../hooks/Api/usePost";
const apiEndpoint = "https://app.steelconnect.co.in:3000/api/admin/auto-delete";
const DeleteAllMessage="https://app.steelconnect.co.in:3000/api/admin/messages/delete-all";

const getDate = "https://app.steelconnect.co.in:3000/api/admin/auto-delete";
const getLogsData = "https://app.steelconnect.co.in:3000/api/admin/auto-delete/logs";


const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: "20px",
  boxShadow: 24,
  p: 4,
};
let logs
let reversedLogs=[]
const Settings = () => {
  const [isOpen, setIsOpen] = React.useState(false);
  const handleOpen = () => setIsOpen(true);
  const handleModalClose = () => {
    setIsOpen(false);
  };

  const userOptions = ["Select Days", "3 days", "7 days", "15 days", "30 days"];
  const [deleteSetting, setDeleteSetting] = useState("5 days");
  const [isEditing, setIsEditing] = useState(false);

  const { makeRequest, data:setTimeData,  error } = usePost(apiEndpoint);

  const { makeRequest: getTime, data } = useGet(apiEndpoint);
  const { makeRequest: deleteAllMessage, data:messageDelete } = useGet(DeleteAllMessage);
  const { makeRequest: getLogs, data:logsData } = useGet(getLogsData);

  const [selectedOption, setSelectedOption] = useState('3');


  

  const handleChange = (event) => {
    setSelectedOption(event.target.value);
  };

  useEffect(() => {
    let token = localStorage.getItem("token");
    // console.log(token);
    const getData = async () => {
      await getLogs(token);
     
    };
    getData();
    console.log(logsData);
    logs=logsData?.data;  
    reversedLogs=logs?.slice().reverse()
    console.log(logs);
  }, []);

  useEffect(()=>{
    console.log(logsData);
    logs=logsData?.data;
    console.log(logs);
  },[logsData])
  logs=logsData?.data;
  reversedLogs=logs?.slice().reverse()

  useEffect(() => {
    let token = localStorage.getItem("token");
    // console.log(token);
    const getData = async () => {
      await getTime(token);
     
    };
    getData();
  }, []);



  useEffect(() => {
    setSelectedOption('11');
    console.log(data?.data?.auto_delete_time);
    let time = data?.data?.auto_delete_time;
    console.log(typeof time?.toString());
    if (time === 5) {

      console.log("time");
    

      console.log(selectedOption);
    }
    // let timeDays = time + " Days";
    // console.log(timeDays);
    // setDeleteSetting(time);
    // console.log(deleteSetting);
  }, [data]);

  // console.log(selectedOption);

  // const handleChange = (e) => {
  //   const { name, value } = e.target;
  //   switch (name) {
  //     case "delete-setting":
  //       setDeleteSetting(value);
  //       break;
  //   }
  // };

  const handleForm = async () => {
   
    let token = localStorage.getItem("token");

    let formData = {
      time: selectedOption,
    };
    console.log(selectedOption);
    await makeRequest(formData, token);
    console.log(setTimeData);
  };

  useEffect(()=>{
if(setTimeData?.success===true){
  handleOpen()
}
  },[setTimeData])


  const handleDeleteAllMessage=async()=>{
    let token = localStorage.getItem("token");
await deleteAllMessage(token)
handleModalClose()
  }



  return (
    <div className="row">
       <Modal
        open={isOpen}
        onClose={handleModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
             Your Data Has been Submitted Successfully
          </Typography>

          <Box style={{ marginLeft: "90px", marginTop: "20px" }}>
      {
        setTimeData?.success!==true?  <div>

        <Button
          variant="contained"
          color="success"
          onClick={handleModalClose}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="success"
          onClick={handleDeleteAllMessage}
        >
          Ok
        </Button>
        </div>
        :
        <div>
           <Button
          variant="contained"
          color="success"
          onClick={handleModalClose}
        >
          Ok
        </Button>
        </div>  

      }
      
          </Box>
        </Box>
      </Modal>

      <div className="col-md-12">
        <main>
          <div class="page-heading mt-5" 
           style={{
            display: "flex",
            alignContent: "space-between",
            justifyContent: "space-around",
            alignItems: "flex-start"
          }}
          >
            <h1>Manage Delete Setting</h1>
            <div 
            style={{
              cursor:"pointer"
            }}
            
            className="setting-button">
              <Button
              onClick={handleOpen}
              >
              Delete all Messages
</Button>          </div>
          </div>
          
          <div className="add-job-section">
            <Form className="dashboard-form">
              <Row>
                <Col md={6}>
                  {/* <FormRowSelect
                    name="delete-setting"
                    value={deleteSetting}
                    handleChange={handleChange}
                    labelText="Automatically Delete Messages after"
                    list={userOptions}
                  /> */}

                  <select value={selectedOption} onChange= 
                                                      
                                                       {handleChange}>
                    <option value="1"> Select Days</option>
                    <option value="3">3 Days</option>
                    <option value="7">7 Days </option>
                    <option value="15">15 Days</option>
                    <option value="30">30 Days</option>
                  </select>
                </Col>
                <Col md={6}>
                  <p></p>
                  <div className="form-btn-container">
                    <Button
                      className="register-btn clear-btn"
                      style={{ marginTop: "20px" }}
                    >
                      Clear
                    </Button>
                    <Button
                      className="register-btn"
                      style={{ marginTop: "20px" }}
                      onClick={handleForm}
                    >
                      Submit
                    </Button>
                  </div>
                </Col>
              </Row>
            </Form>
          </div>

        </main>
      </div>
    { logs&& <LogsTable heading="Logs Data " logs={logs}/>}
    </div>
  );
};

export default Settings;
