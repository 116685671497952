import React, { useState } from "react";
import { IoMdClose } from "react-icons/io";
import './Media.css'
import  CustomTabPanel from "./CustomTabPanel"
// import './landing/Profile'
import '../../../assets/sass/Media.scss'

import { useNavigate, useParams } from "react-router";
import { gql, useMutation } from "@apollo/client";
import { useEffect } from "react";


const MEDAI_MUTAION=gql`
mutation RoomChats($input: RoomChatsRequest) {
  roomChats(input: $input) {
    chats {
      from_me
      user {
        first_name
      }
      chat {
        room_code
        message
        attachment
        type
      }
    }
  }
}
`

const Media = ({ open, close,setViewMedia }) => {
  // console.log(open);
  const { roomId } = useParams();

  const [getChatMedia] = useMutation(MEDAI_MUTAION);
  const [chatMedia,setChatMedia]=useState()
  useEffect(() => {
    const getChatInfo = async () => {
      const { data } = await getChatMedia({
        variables: {
          input: {
            code: roomId,
            media:1
          },
        },
      });
      console.log(data?.roomChats);

      setChatMedia(data?.roomChats?.chats);

      
    };
 roomId&&   getChatInfo();
  }, [roomId]);

  useEffect(()=>{
    chatMedia&&setViewMedia(chatMedia)
  },[chatMedia])

  return (
    <>
      <div className={open ? "group-modal open" : "group-modal"}>
        <div className="modal-header">
          <h2>media</h2>
          <div className="modal-close" onClick={() => close(false)}>
            <IoMdClose />
          </div>
        </div>
        <CustomTabPanel  chatMedia={chatMedia}/>
</div>

        </>
  );
};

export default Media;