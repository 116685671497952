import React from "react";
// import MicNoneIcon from "@mui/icons-material/MicNone";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import { useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
// import DeleteIcon from "@mui/icons-material/Delete";
// import MicNoneSharpIcon from "@mui/icons-material/MicNoneSharp";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
// import PauseCircleOutlineIcon from "@mui/icons-material/PauseCircleOutline";
import { BsFillMicFill } from "react-icons/bs";
import { AiOutlineSend, AiFillDelete, AiFillPauseCircle } from "react-icons/ai";
import { gql, useMutation } from "@apollo/client";
import { useEffect, useRef } from "react";
import usePost from "../../hooks/Api/usePost";
import { useParams } from "react-router";
const apiEndpoint = "https://app.steelconnect.co.in:3000/upload";

// import "./Voice.css";
// import SendIcon from "@mui/icons-material/Send";

const style = {
  position: "absolute",
  top: "90%",
  left: "63%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",

  paddingLeft: "20px",
  paddingTop: "15px",
  width: "1104px",
  borderRadius: "50px",
  height: "90px",
};

const style2 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: "20px",
  boxShadow: 24,
  p: 4,
};


const SEND_MESSAGE_MUTATION = gql`
  mutation SendChat($input: SendChatRequest) {
    sendChat(input: $input) {
      success
      message
      chat {
        id
        room_id
        room_code
        user_id
      }
      chat_room {
        id
        name
        code
      }
      from_me
    }
  }
`;


const VoiceSend = ({ setVoiceData, setIsVoice }) => {
  const [permissionGranted, setPermissionGranted] = useState(false);
  const [SendMessage] = useMutation(SEND_MESSAGE_MUTATION);


  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => setOpen(false);
  useEffect(() => {
    const askPermission = async () => {
      const microphonePermission = await navigator.permissions.query({
        name: "microphone",
      });
      setPermissionGranted(microphonePermission.state === "granted");
      // console.log(permissionGranted);
      console.log(microphonePermission.state);
    };

    askPermission();
  }, []);



  const [time, setTime] = useState(0);
  const [isRunning, setIsRunning] = useState(false);

  const intervalRef = useRef();

  const formatTime = (time) => {
    const minutes = Math.floor(time / 6000);
    const seconds = Math.floor((time % 6000) / 100);

    return `${String(minutes).padStart(1, "0")}:${String(seconds).padStart(
      2,
      "0"
    )}`;
  };

  const [status, setStatus] = useState(null);
  const { roomId } = useParams();
  const { makeRequest, data, isLoading, error } = usePost(apiEndpoint);

  const [recording, setRecording] = useState(false);
  const [audioMessage, setAudioMessage] = useState(null);
  const mediaRecorderRef = useRef(null);
  const chunksRef = useRef([]);
  const [url, setUrl] = useState(null);
  const [isPause, setIsPuase] = useState(false);
  const [voiceFile, setVoiceFile] = useState(null)


  const sendMedia = async () => {
    if (!url) return;
    console.log('url: ', url);
    try {
      const { data } = await SendMessage({
        variables: {
          input: {
            room_code: roomId,
            message: "voice",
            type: "attachment",
            attachment: url,
            is_forwarded: null,
            reply_to: null,
          },
        },
      });
      console.log(data.sendChat.success);
      if (data.sendChat.success === true) {

        setUrl(null);
        setVoiceFile(null)
      }
    } catch (error) {
      console.error(error);
    }
    setUrl(null);
  };

  const handleImageUpload = async () => {
    if (!voiceFile) return;
    console.log('voiceFile: ', voiceFile);
    // console.log(selectedFile);
    try {
      let formData = {
        file: voiceFile,
      };
      // console.log(formData);
      let token = localStorage.getItem("token");

      await makeRequest(formData, token);
      // console.log(data);
      // console.log('data: ', data);
      setUrl(data?.url);
      if (url !== null) {
        // handleProfilePic()
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleStartRecording = () => {
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((stream) => {
        const mediaRecorder = new MediaRecorder(stream);
        mediaRecorderRef.current = mediaRecorder;

        mediaRecorder.addEventListener("dataavailable", (event) => {
          if (event.data.size > 0) {
            chunksRef.current.push(event.data);
          }
        });

        mediaRecorder.addEventListener("stop", () => {
          const audioBlob = new Blob(chunksRef.current, { type: "audio/webm" });
          setVoiceFile(audioBlob)
          // console.log(audioBlob);

          const audioURL = URL.createObjectURL(audioBlob);
          setAudioMessage(audioURL);
          // console.log(audioURL);

          chunksRef.current = [];
        });
        mediaRecorder.addEventListener("pause", () => {
          console.log("pauesed");
          const audioBlob = new Blob(chunksRef.current, { type: "audio/webm" });
          const audioURL = URL.createObjectURL(audioBlob);
          console.log(audioURL);
          setAudioMessage(audioURL);
          setVoiceData(audioURL)

          chunksRef.current = [];
        });

        mediaRecorder.start();
        setRecording(true);
        startTimer();
      })
      .catch((error) => {
        console.error("Error accessing microphone:", error);
      });
  };

  const handlePauseRecording = () => {
    if (
      mediaRecorderRef.current &&
      mediaRecorderRef.current.state === "recording"
    ) {
      mediaRecorderRef.current.pause();
      setIsPuase(true);
      pauseTimer();
    }
  };

  const handleResumeRecording = () => {
    if (
      mediaRecorderRef.current &&
      mediaRecorderRef.current.state === "paused"
    ) {
      mediaRecorderRef.current.resume();
      startTimer();
    }
  };

  const handleStopRecording = () => {
    if (
      mediaRecorderRef.current &&
      mediaRecorderRef.current.state !== "inactive"
    ) {
      mediaRecorderRef.current.stop();
      setRecording(false);
      stopTimer();
    }
  };

  const startTimer = () => {
    setIsRunning(true);
    intervalRef.current = setInterval(() => {
      setTime((prevTime) => prevTime + 1);
    }, 10);
  };

  const pauseTimer = () => {
    setIsRunning(false);
    clearInterval(intervalRef.current);
  };

  const stopTimer = () => {
    setIsRunning(false);
    clearInterval(intervalRef.current);
    setTime(0);
  };

  useEffect(() => {
    return () => {
      clearInterval(intervalRef.current);
    };
  }, []);
  useEffect(() => {
    if (permissionGranted === true) {
      //    controlAudio("recording")
    }
  }, [open])

  const controlAudio = (status) => {
    setStatus(status);
  };

  useEffect(() => {
    if (status === "inactive") {
      setAudioMessage(null);
      handleClose();
      handleStopRecording();
    }

    if (status === "recording") {

      handleStartRecording();
      console.log('recording stat');
    }
    if (status === "paused") {
      handlePauseRecording();
    }

    if (status === "resume") {
      handleResumeRecording();
    }

    if (status === "sent") {
      handleStopRecording();
      setIsVoice(true)

    }
  }, [status]);


  useEffect(()=>{

    console.log('data: ', data);
   data&& setUrl(data?.url)
   console.log('url: ', url);
  },[data])

  useEffect(() => {
    
      if (url !== null) {
        console.log('url: ', url);

        sendMedia()
      }
    
  }, [url])

  useEffect(() => {
    if(status==='sent'){
      console.log('voiceFile: ', voiceFile);

      voiceFile &&  handleImageUpload()
    }

  }, [voiceFile])



  return (
    <div>
      <div onClick={handleOpen}>
        <BsFillMicFill style={{ cursor: "pointer", marginLeft: "6px" }} />  </div>
      {permissionGranted === true ? (
        <div>


          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={1}
              >
                <AiFillDelete
                  onClick={() => controlAudio("inactive")}
                  style={{ cursor: "pointer", marginLeft: "6px" }}
                />

                <h2>{formatTime(time)}</h2>

                {recording !== true && (
                  <BsFillMicFill
                    onClick={() => controlAudio("recording")}
                    style={{ cursor: "pointer", marginLeft: "6px" }} y
                  />
                )}

                {recording === true && (
                  <>
                    {status !== "paused" ? (
                      <AiFillPauseCircle
                        style={{ cursor: "pointer", marginLeft: "6px" }}
                        onClick={() => controlAudio("paused")}
                      />
                    ) : (
                      <BsFillMicFill
                        onClick={() => controlAudio("resume")}
                        style={{ cursor: "pointer", marginLeft: "6px" }}
                      />
                    )}
                  </>
                )}

                <AiOutlineSend
                  onClick={() => controlAudio("sent")}
                  style={{ cursor: "pointer", marginLeft: "6px" }}
                />

                <audio src={audioMessage} controls loop />
              </Stack>
            </Box>
          </Modal>
        </div>
      ) : (
        <div>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box >
              <div class="mic-container">
                <h1 class="mic-heading">Allow microphone</h1>
                <p class="mic-paragraph">
                  To record Voice Message, ChatApp needs access to your
                  microphone. Click in the URL bar and choose "Always allow
                  chatapp.com to access your microphone"
                </p>
                <button
                  onClick={handleClose}
                  className="mic-btn">OK,got it</button>
              </div>
            </Box>
          </Modal>
        </div>
      )}
    </div>
  );
};

export default VoiceSend;
