import React, { useState } from 'react'

function UserData({sname,imgsrc,aname}) {
  const [imageError, setimageError] = useState(false);

  
  const handleImageError = () => {
    setimageError(true);
    setimageError(true);
    
      };
    
  return (
    

  
    <div>
       <div className="border1">
  <div className="user-profile-section">
                <div className='user-image'>
             <img className="user-dp-1" 
             
            //  src={imgsrc}
             
             src={imageError===true ||imgsrc===null?"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQhLja_b1nZD7bzgvwtJ0JlhlQaKR3ModNySg&usqp=CAU":imgsrc}
        
        onError={handleImageError}
              />
             </div>
             <div className="user-data">
             <span className="user-name-1" >{sname}</span>
             <span className="about-user-1">{aname}</span>
             </div>
            </div>
            </div>
    </div>
  )
}

export default UserData
