import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { useState } from "react";
import { useNavigate } from "react-router";
import { gql, useLazyQuery, useMutation } from "@apollo/client";
import Form from "../landing/Form";
import { useEffect } from "react";
import { useContext } from "react";
import { AuthContext } from "../../hooks/AuthContext";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  //   border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  borderRadius: "50px",
};

const NEW_GROUP_MUTATION = gql`
  mutation CreateChatGroup($input: CreateGroupRequest) {
    createGroup(input: $input) {
      success
      message
      code
    }
  }
`;

const NewGroup = ({ open, close }) => {
const {changeCount} =useContext(AuthContext)
    // console.log("gropuopne==>",open);
  const [NewGroup, { loading, data }] = useMutation(NEW_GROUP_MUTATION);
  const [isOpen, setIsOpen] = useState(open);
  const handleOpen = () => setIsOpen(true);
  const handleClose = () => close();
  const [message, setMessage] = useState("");
  const navigate = useNavigate();
  const [validate, setValidate] = useState({});
  const [errorMessage,setErrorMessage]=useState(false)

  const handleChange = (e) => {
    setMessage(e.target.value);
  };
//   useEffect(() => {
//     handleOpen()
//     console.log("opne=>",isOpen);

//   }, [open]);
  const validateRegister = () => {
    let isValid = true;

    let validator = Form.validator({
      message: {
        value: message,
        isRequired: true,
      },
    });

    if (validator !== null) {
      setValidate({
        validate: validator.errors,
      });

      isValid = false;
    }
    return isValid;
  };


  const handleNewGroup=()=>{
        
   
    
    const validate = validateRegister();
    if(validate)
{
    NewGroup({variables:{input:{
        
        name: message
    }}})
    console.log(data);
   
    setErrorMessage(false)
}
setErrorMessage(false)

  }


  useEffect(() => {
    if(data?.createGroup?.success===true){
        changeCount()
        navigate(`/chats`)
        handleClose()
        setMessage('')
        
    }
    if(data?.newChat?.success===false) {
// setErrorMessage(data?.newChat?.message)
setErrorMessage(true)


    }
  }, [data]);

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {
            <>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                <input
                  type="text"
                  id="message"
                  placeholder="Type your Group name here"
                  value={message}
                  name="message"
                  onChange={handleChange}
                  style={{
                    width: 312,
                    height: 30,
                    border: "1px solid #ccc",
                    padding: 18,
                    marginBottom: 10,
                  }}
                  className={`form-control ${
                    validate.validate && validate.validate.message
                      ? "is-invalid "
                      : ""
                  }`}
                />
                <div
                  className={`invalid-feedback text-start ${
                    validate.validate && validate.validate.message
                      ? "d-block"
                      : "d-none"
                  }`}
                >
                  {validate.validate && validate.validate.message
                    ? validate.validate.message[0]
                    : ""}
                </div>
              </Typography>
              <Typography style={{ marginTop: 30, marginLeft: 113 }}>
                <Button  
                onClick={handleNewGroup}
                variant="contained" color="success">
                  Send
                </Button>
              </Typography>
            </>
          }
        </Box>
      </Modal>
    </div>
  );
};

export default NewGroup;
