import React from "react";
import { AiOutlineDown } from "react-icons/ai";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useParams } from "react-router";
import { gql, useMutation } from "@apollo/client";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: "20px",
  boxShadow: 24,
  p: 4,
};

const MAKE_GROUP_ADMIN = gql`
  mutation AddGroupAdmin($input: AddChatParticipantRequest) {
    addGroupAdmin(input: $input) {
      success
      message
    }
  }
`;
const REMOVE_GROUP_ADMIN = gql`
  mutation RemoveGroupAdmin($input: AddChatParticipantRequest) {
    removeGroupAdmin(input: $input) {
      success
      message
    }
  }
`;

const REMOVE_PARTICIPATNS = gql`
  mutation RemoveChatParticipant($input: AddChatParticipantRequest) {
    removeChatParticipant(input: $input) {
      success
      message
    }
  }
`;

let groupType = "";
function UserData({ name, handleCount, data, chatData }) {
  // console.log(chatData);
  // console.log('chatData: ', chatData);
  const { roomId } = useParams();
  let userId = data?.id?.toString();
  // console.log(data);
  const [makeGroupAdmin] = useMutation(MAKE_GROUP_ADMIN);
  const [removeGroupAdmin] = useMutation(REMOVE_GROUP_ADMIN);
  const [removeParticipant] = useMutation(REMOVE_PARTICIPATNS);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isOpen, setIsOpen] = React.useState(false);
  const handleOpen = () => setIsOpen(true);
  const handleModalClose = () => {
    setIsOpen(false);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMakeAdmin = async () => {
    const { data } = await makeGroupAdmin({
      variables: {
        input: {
          code: roomId,
          user_id: userId,
        },
      },
    });

    handleCount();
    handleClose();

    console.log(data);
    handleModalClose();
    groupType = "";
  };

  const handleRemoveParticipants = async () => {
    const { data } = await removeParticipant({
      variables: {
        input: {
          code: roomId,
          user_id: userId,
        },
      },
    });

    handleCount();

    console.log(data);
    handleModalClose();
    handleClose();
    groupType = "";
  };
  const handleRemoveAdmin = async () => {
    
    const { data } = await removeGroupAdmin({
      variables: {
        input: {
          code: roomId,
          user_id: userId,
        },
      },
    });

    handleCount();

    console.log(data);
    handleModalClose();
    handleClose();
    groupType = "";
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div>
      <Modal
        open={isOpen}
        onClose={handleModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {data?.is_group_admin === 1
              ? "Remove as  Admin"
              :groupType==="member"?"Remove from group"
              : "Make as Group Admin"}
          </Typography>

          <Box style={{ marginLeft: "90px", marginTop: "20px" }}>
            {groupType === "member" ? (
              <Button
                variant="contained"
                color="success"
                onClick={handleRemoveParticipants}
              >
                Ok
              </Button>
            ) : (
              <Button
                variant="contained"
                color="success"
                onClick={
                  data?.is_group_admin === 1
                    ? handleRemoveAdmin
                    : handleMakeAdmin
                }
              >
                Ok
              </Button>
            )}
          </Box>
        </Box>
      </Modal>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Typography sx={{ p: 1 }}>
          <li
            style={{
              cursor: "pointer",
            }}
            // onClick={handleOpen}
          >
            {data?.is_group_admin === 1 ? (
              <span>
                <span onClick={handleOpen}>Remove as Admin</span>

                <br />
                <br />

                <span
                  onClick={() => {
                    groupType = "member";
                    handleOpen();
                  }}
                >
                  Remove from group
                </span>
              </span>
            ) : (
              <>
                <span onClick={handleOpen}>Make as Group Admin</span>
                <br />
                <br />
                <span
                  onClick={() => {
                    groupType = "member";
                    handleOpen();
                  }}
                >
                  Remove from group
                </span>
              </>
            )}
          </li>
        </Typography>
      </Popover>
      <div className="border1">
        <div className="user-profile-section">
          <div className="user-image">
            <img
              className="user-dp-1"
              src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
              alt="user"
            />
          </div>
          <div className="user-data">
            <span className="user-name-1">{name}</span>
          </div>

          <div className="group-admin">
            {data?.is_group_admin === 1 ? "Group Admin" : ""}

            {chatData?.is_group_admin ? (
              <AiOutlineDown
                onClick={handleClick}
                style={{
                  cursor: "pointer",
                  // marginRight: "38px",
                  fontSize: "12px",
                  marginTop: "10px",
                }}
              />
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserData;
