import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import './Page.css'
const ErrorPage = () => {
    
    const navigate =useNavigate()

    const [isConnected, setIsConnected] = useState(true);

  useEffect(() => {
    // Check for internet connection
    const checkInternetConnection = () => {
      const isOnline = navigator.onLine;
      setIsConnected(isOnline);
    };

    checkInternetConnection();

    // Event listener to detect changes in internet connection
    window.addEventListener('online', checkInternetConnection);
    window.addEventListener('offline', checkInternetConnection);

    // Clean up the event listeners
    return () => {
      window.removeEventListener('online', checkInternetConnection);
      window.removeEventListener('offline', checkInternetConnection);
    };
  }, []);

  useEffect(()=>{

    if(isConnected===true){
        navigate('/')
    }

  },[isConnected])

  return (
    <div>
        <section class="page_404">
	<div class="container">
		<div class="row">	
		<div class="col-sm-12 ">
		<div class="col-sm-10 col-sm-offset-1  text-center">
		<div class="four_zero_four_bg">
			<h1 class="text-center ">404</h1>
		
		
		</div>
		
		<div class="contant_box_404">
	
		
        <h2>
          
          You're lost could not connect to the  internet. Please check your network.
        </h2>
        		
	</div>
		</div>
		</div>
		</div>
	</div>
</section>
    </div>
  )
}

export default ErrorPage